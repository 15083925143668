import * as _ from '@technically/lodash'

import { summaryTreeArray } from '~c/common/sheets'
import { getRosterDetailsValue, getSizeSummaryValue } from './roster'

const getSingleValue = (value) => {
  if (value && value.name) {
    return value.name
  }

  return value || '—'
}

const getValue = (node) => {
  if (!node) {
    return null
  }

  if (node.nodeKind === 'FileUploadNode' && node.value) {
    return node.value
  }

  if (node.nodeKind === 'SelectNode' && node.optionName) {
    return node.optionName
  }

  if (node.valueLabel) {
    return node.valueLabel
  }

  if (_.isArray(node.value)) {
    return _.map(node.value, getSingleValue).join(', ')
  }

  return getSingleValue(node.value)
}

const getSummaryDetails = (nodes, parentId) =>
  _.compact(
    _.map(_.filter(summaryTreeArray, { parentId }), (row) => {
      const node = row.propId ? nodes[row.propId] : null

      if (node && !node.isAvailable) {
        return null
      }

      const details = getSummaryDetails(nodes, `${row.parentId}.${row.childId}`)

      if (node && !node.value && node.nodeKind === 'SelectNode') {
        const options = node ? node.visibleOptions || node.options : []

        if (options.length === 0) {
          return null
        }
      }

      let value = getValue(node)
      if (!value && row.propId === 'calc.roster') {
        value = getRosterDetailsValue(nodes)
      }

      if (!value && row.propId === 'calc.sizes') {
        value = getSizeSummaryValue(nodes)
      }

      if (!value && !details.length) {
        return null
      }

      return {
        id: `${row.parentId}.${row.childId}`,
        label: (node && node.summaryLabel) ?? row.name,
        value,
        details,
      }
    }),
  )

const getSummary = (nodes) => {
  const summaryNodeSections = _.filter(
    summaryTreeArray,
    (entry) => entry.parentId == null,
  )
  const summaryNodeDetails = _.map(summaryNodeSections, (section) => {
    const details = getSummaryDetails(nodes, section.childId)

    if (!details.length) {
      return null
    }

    return {
      id: section.childId,
      label: section.name,
      details,
    }
  })

  return _.compact(summaryNodeDetails)
}

export default getSummary
