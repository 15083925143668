import React from 'react'

import { cn } from '~p/client/components/utils'

import './Navigation.css'

function Navigation(props) {
  const { children } = props
  return <div className={cn(['navigation'])}>{children}</div>
}

export default Navigation
