import React from 'react'

import { cn, mods, states } from '~p/client/components/utils'

import './NavigationTab.css'

function NavigationTab(props) {
  const { onClick, classMods, classStates, children } = props
  return (
    <button
      className={cn(['navigationTab', mods(classMods), states(classStates)])}
      data-gtm={props['data-gtm']}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default NavigationTab
