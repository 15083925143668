// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PropDefRow } from '../typings';

const data = [
  {
    "id": "filter_sport",
    "name": "Sport",
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "filter_serie",
    "name": "Serie",
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "filter_position",
    "name": "Position",
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "filter_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "filter_pocketDepth",
    "name": "Pocket Depth",
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "product_throwingHand",
    "name": "Throwing Hand",
    "description": "Changes in this option are not visualized.",
    "tileType": "image",
    "tileImageProps": "icons/hand/${id}.png",
    "defaultValueId": "right",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "product_glove",
    "name": "Glove",
    "availability": {
      "customClassicFastpitch": "TRUE",
      "proSelectFastpitch": "TRUE",
      "proSelectBaseball": "TRUE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "tileType": "image",
    "tileImageProps": "icons/glove/${id}.png",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "product_web",
    "name": "Web Style",
    "availability": {
      "customClassicFastpitch": "TRUE",
      "proSelectFastpitch": "TRUE",
      "proSelectBaseball": "TRUE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "tileType": "image",
    "tileImageProps": "icons/web/${id}.jpg",
    "isOptional": false,
    "viewNames": [
      "web",
      "palm"
    ]
  },
  {
    "id": "colors_web",
    "name": "Web",
    "availability": {
      "customClassicFastpitch": "legacyExtended",
      "proSelectFastpitch": "proSelectWeb",
      "proSelectBaseball": "proSelectWeb",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ]
  },
  {
    "id": "colors_palm",
    "name": "Palm",
    "availability": {
      "customClassicFastpitch": "legacyStandard",
      "proSelectFastpitch": "proSelectBase",
      "proSelectBaseball": "proSelectBase",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "palm"
    ]
  },
  {
    "id": "colors_back",
    "name": "Back",
    "availability": {
      "customClassicFastpitch": "legacyStandard",
      "proSelectFastpitch": "proSelectBase",
      "proSelectBaseball": "proSelectBase",
      "mizunoProMesh": "FALSE",
      "mizunoPro": "FALSE"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_meshBack",
    "name": "Mesh Back",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProMeshBack",
      "mizunoPro": "FALSE"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_pinkyThumbPanels",
    "name": "Mitt Connector",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "proSelectBase",
      "mizunoProMesh": "FALSE",
      "mizunoPro": "FALSE"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "palm"
    ]
  },
  {
    "id": "colors_backPanel1",
    "name": "Back Panel 1",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "colors_backPanel2",
    "name": "Back Panel 2",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "colors_backPanel3",
    "name": "Back Panel 3",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ]
  },
  {
    "id": "colors_backPanel4",
    "name": "Back Panel 4",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_backPanel5",
    "name": "Back Panel 5",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "colors_backPanel6",
    "name": "Back Panel 6",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "colors_backEmboss",
    "name": "Back Emboss",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "TRUE",
      "mizunoProMesh": "FALSE",
      "mizunoPro": "TRUE"
    },
    "tileType": "color",
    "isOptional": true,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_accent",
    "name": "Accent",
    "availability": {
      "customClassicFastpitch": "legacyExtended",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "FALSE",
      "mizunoPro": "FALSE"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ]
  },
  {
    "id": "colors_wrist",
    "name": "Wrist",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ]
  },
  {
    "id": "colors_thumbOutside",
    "name": "Thumb Outside",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "colors_thumbLoop",
    "name": "Thumb Loop",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "colors_pinkyOutside",
    "name": "Pinky Outside",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "colors_pinkyLoop",
    "name": "Pinky Loop",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "colors_welting_type",
    "name": "Welting Type",
    "availability": {
      "customClassicFastpitch": "onlyRoll",
      "proSelectFastpitch": "onlyRoll",
      "proSelectBaseball": "onlyRoll",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "dataType": "weltingTypeSets",
    "tileType": "name",
    "defaultValueId": "roll",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_welting_color",
    "name": "Welting Color",
    "availability": {
      "customClassicFastpitch": "TRUE",
      "proSelectFastpitch": "TRUE",
      "proSelectBaseball": "TRUE",
      "mizunoProMesh": "mizunoProRollWelting",
      "mizunoPro": "mizunoProRollWelting"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_binding",
    "name": "Binding",
    "availability": {
      "customClassicFastpitch": "legacyBinding",
      "proSelectFastpitch": "proSelectWeltingBinding",
      "proSelectBaseball": "proSelectWeltingBinding",
      "mizunoProMesh": "mizunoProBinding",
      "mizunoPro": "mizunoProBinding"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ]
  },
  {
    "id": "colors_lace_color",
    "name": "Lace Color",
    "availability": {
      "customClassicFastpitch": "legacyLace",
      "proSelectFastpitch": "proSelectLace",
      "proSelectBaseball": "proSelectLace",
      "mizunoProMesh": "mizunoProLace",
      "mizunoPro": "mizunoProLace"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_lace_length",
    "name": "Lace Length",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Changes in this option are not visualized.",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "colors_stitching_web",
    "name": "Stitching on Web",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "proSelectStitching",
      "proSelectBaseball": "proSelectStitching",
      "mizunoProMesh": "mizunoProExtended",
      "mizunoPro": "mizunoProExtended"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web",
      "palm"
    ]
  },
  {
    "id": "colors_stitching_glove",
    "name": "Stitching on Glove",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "proSelectStitching",
      "proSelectBaseball": "proSelectStitching",
      "mizunoProMesh": "mizunoProExtended",
      "mizunoPro": "mizunoProExtended"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "embroidery_patch",
    "name": "Patch Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "proSelectPatch",
      "proSelectBaseball": "proSelectPatch",
      "mizunoProMesh": "FALSE",
      "mizunoPro": "FALSE"
    },
    "dataType": "colorSets",
    "tileType": "image",
    "tileImageProps": "icons/patch/${id}.png",
    "defaultValueId": "gold",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_color",
    "name": "Embroidery Color",
    "availability": {
      "customClassicFastpitch": "legacyPersonalize",
      "proSelectFastpitch": "proSelectEmbroidery",
      "proSelectBaseball": "proSelectEmbroidery",
      "mizunoProMesh": "mizunoProExtended",
      "mizunoPro": "mizunoProExtended"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "gold",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_name_text",
    "name": "Name Text",
    "availability": {
      "customClassicFastpitch": "TRUE",
      "proSelectFastpitch": "TRUE",
      "proSelectBaseball": "TRUE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Only letters, numbers, hash, period symbol and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_name_font",
    "name": "Font",
    "tileType": "text",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_name_color",
    "name": "Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProExtended",
      "mizunoPro": "mizunoProExtended"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "gold",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_number_text",
    "name": "Number Embroidery",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_number_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_number_ringColor",
    "name": "Number Ring Color",
    "tileType": "image",
    "tileImageProps": "icons/numberBase/${id}.png",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_number_color",
    "name": "Number Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProEmbroidery",
      "mizunoPro": "mizunoProEmbroidery"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_liner_text",
    "name": "Liner Embroidery",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "isOptional": true,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_liner_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_liner_color",
    "name": "Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProEmbroidery",
      "mizunoPro": "mizunoProEmbroidery"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_pinky_type",
    "name": "Pinky Embroidery",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "onlyFlag",
      "proSelectBaseball": "onlyFlag",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "dataType": "pinkyTypeSets",
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_pinky_flag",
    "name": "Flag",
    "tileType": "image",
    "tileImageProps": "icons/flag/${id}.png",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_pinky_ribbon",
    "name": "Ribbon Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProExtended",
      "mizunoPro": "mizunoProExtended"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "gold",
    "isOptional": false,
    "viewNames": [
      "back"
    ]
  },
  {
    "id": "embroidery_logo_previewFile",
    "name": "Team Logo Preview File",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "tileType": "upload",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_logo_factoryFile",
    "name": "Team Logo Factory File",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "tileType": "upload",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "id": "embroidery_bag_text",
    "name": "Glove Bag Embroidery",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Only letters, numbers, hash symbol and spaces are allowed. Maximum 15 characters. Changes in this option are not visualized.",
    "tileType": "text",
    "isOptional": true,
    "viewNames": [
      "web",
      "back",
      "palm"
    ]
  },
  {
    "id": "embroidery_bag_font",
    "name": "Font",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE"
    },
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "web",
      "back",
      "palm"
    ]
  },
  {
    "id": "embroidery_bag_color",
    "name": "Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProExtended",
      "mizunoPro": "mizunoProExtended"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "gold",
    "isOptional": false,
    "viewNames": [
      "web",
      "back",
      "palm"
    ]
  },
  {
    "id": "options_finger_type",
    "name": "Finger Pad or Hood",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "tileType": "name",
    "isOptional": true,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "options_finger_color",
    "name": "Finger Pad or Hood Color",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "mizunoProStandard",
      "mizunoPro": "mizunoProStandard"
    },
    "dataType": "colorSets",
    "tileType": "color",
    "defaultValueId": "cream",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "options_finger_location",
    "name": "Finger Pad or Hood Location",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE"
    },
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ]
  },
  {
    "id": "options_stamp",
    "name": "Stamp",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "palm",
      "web"
    ]
  },
  {
    "id": "options_softness",
    "name": "Softness",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Changes in this option are not visualized.",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "options_palmLinerMaterial",
    "name": "Palm Liner Material",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Changes in this option are not visualized.",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "options_gripThumb",
    "name": "Grip Thumb",
    "availability": {
      "customClassicFastpitch": "FALSE",
      "proSelectFastpitch": "FALSE",
      "proSelectBaseball": "FALSE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Ultra comfortable padded thumb slot providing enhanced grip. Changes in this option are not visualized.",
    "tileType": "name",
    "defaultValueId": "no",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "details_recipeName_text",
    "name": "Recipe Name",
    "availability": {
      "customClassicFastpitch": "TRUE",
      "proSelectFastpitch": "TRUE",
      "proSelectBaseball": "TRUE",
      "mizunoProMesh": "TRUE",
      "mizunoPro": "TRUE"
    },
    "description": "Maximum 30 characters.",
    "tileType": "text",
    "isOptional": true,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "calc_sport",
    "name": "Calc Sport",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "calc_serie",
    "name": "Calc Serie",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "calc_positions",
    "name": "Calc Positions",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "calc_size",
    "name": "Calc Size",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "calc_pocketDepth",
    "name": "Calc Pocket Depth",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "id": "calc_sku",
    "name": "Calc SKU",
    "tileType": "name",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "filter_sport": typed[i++],
  "filter_serie": typed[i++],
  "filter_position": typed[i++],
  "filter_size": typed[i++],
  "filter_pocketDepth": typed[i++],
  "product_throwingHand": typed[i++],
  "product_glove": typed[i++],
  "product_web": typed[i++],
  "colors_web": typed[i++],
  "colors_palm": typed[i++],
  "colors_back": typed[i++],
  "colors_meshBack": typed[i++],
  "colors_pinkyThumbPanels": typed[i++],
  "colors_backPanel1": typed[i++],
  "colors_backPanel2": typed[i++],
  "colors_backPanel3": typed[i++],
  "colors_backPanel4": typed[i++],
  "colors_backPanel5": typed[i++],
  "colors_backPanel6": typed[i++],
  "colors_backEmboss": typed[i++],
  "colors_accent": typed[i++],
  "colors_wrist": typed[i++],
  "colors_thumbOutside": typed[i++],
  "colors_thumbLoop": typed[i++],
  "colors_pinkyOutside": typed[i++],
  "colors_pinkyLoop": typed[i++],
  "colors_welting_type": typed[i++],
  "colors_welting_color": typed[i++],
  "colors_binding": typed[i++],
  "colors_lace_color": typed[i++],
  "colors_lace_length": typed[i++],
  "colors_stitching_web": typed[i++],
  "colors_stitching_glove": typed[i++],
  "embroidery_patch": typed[i++],
  "embroidery_color": typed[i++],
  "embroidery_name_text": typed[i++],
  "embroidery_name_font": typed[i++],
  "embroidery_name_color": typed[i++],
  "embroidery_number_text": typed[i++],
  "embroidery_number_font": typed[i++],
  "embroidery_number_ringColor": typed[i++],
  "embroidery_number_color": typed[i++],
  "embroidery_liner_text": typed[i++],
  "embroidery_liner_font": typed[i++],
  "embroidery_liner_color": typed[i++],
  "embroidery_pinky_type": typed[i++],
  "embroidery_pinky_flag": typed[i++],
  "embroidery_pinky_ribbon": typed[i++],
  "embroidery_logo_previewFile": typed[i++],
  "embroidery_logo_factoryFile": typed[i++],
  "embroidery_bag_text": typed[i++],
  "embroidery_bag_font": typed[i++],
  "embroidery_bag_color": typed[i++],
  "options_finger_type": typed[i++],
  "options_finger_color": typed[i++],
  "options_finger_location": typed[i++],
  "options_stamp": typed[i++],
  "options_softness": typed[i++],
  "options_palmLinerMaterial": typed[i++],
  "options_gripThumb": typed[i++],
  "details_recipeName_text": typed[i++],
  "calc_sport": typed[i++],
  "calc_serie": typed[i++],
  "calc_positions": typed[i++],
  "calc_size": typed[i++],
  "calc_pocketDepth": typed[i++],
  "calc_sku": typed[i++]
} as const;

export { typed as PROP_DEFS };
