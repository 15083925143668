import React from 'react'
import * as _ from '@technically/lodash'

import { cn } from '~p/client/components/utils'

import './FinalizeRow.css'

const Filename = ({ value, id }) => {
  // TODO: This is an ugly way for deciding wheteher it's a image or a file.
  // This all should be trhown out and rewritten at some point!
  const isFile = !!(id && _.endsWith(id, '.factoryFile'))

  const href =
    isFile ?
      `${window.location.origin}/api/files/${value.id}`
    : `${window.location.origin}/api/images/${value.id}/${encodeURIComponent(
        value.filename,
      )}`

  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      {value.filename}
    </a>
  )
}

function BasicRow(props) {
  const { label, value, id } = props
  return (
    <tr>
      <th className={cn(['finalizeRow-label'])}>{label}</th>
      <td className={cn(['finalizeRow-value'])}>
        {typeof value === 'string' && value}
        {typeof value === 'object' && value && value.filename && (
          <Filename value={value} id={id} />
        )}
      </td>
    </tr>
  )
}

function TableRow(props) {
  const { value } = props
  const header = value[0]
  const values = _.tail(value)

  return (
    <tr>
      <td colSpan="2">
        <table className="roster-table">
          <thead>
            <tr>
              {_.map(header, (label, colIndex) => (
                <th key={colIndex}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(values, (row, rowIndex) => (
              <tr
                key={rowIndex}
                className={row[0].startsWith('Total') ? 'roster-total' : null}
              >
                {_.map(row, (label, colIndex) => (
                  <td key={colIndex}>{label}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  )
}

function FinalizeRow(props) {
  const { label, value, id } = props

  // Proper type checking is long and messes up color coding
  const anyValue = value

  if (_.isArray(value)) {
    return <TableRow label={label} value={anyValue} id={id} />
  }

  return <BasicRow label={label} value={anyValue} id={id} />
}

export default FinalizeRow
