import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './TileLabel.css'

const TileLabel = (props) => {
  const { classMods = [], children } = props
  return <div className={cn(['tileLabel', mods(classMods)])}>{children}</div>
}

export default TileLabel
