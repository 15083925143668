import {
  createLegacyRecipeDataFlatSelector,
  createLegacyRecipeDataNestedSelector,
} from '../common/selectors'

// Never forget to pass in state ever again!
const createUtils = (store, controlTree) =>
  ({
    store,
    controlTree,
    getNodes: () => controlTree.getNodes(store.getState()),
    getRecipe: () => controlTree.getRecipe(store.getState()),
    getInvalidNodes: () => controlTree.getInvalidNodes(store.getState()),
    getInvalidNodeValues: () =>
      controlTree.getInvalidNodeValues(store.getState()),
    getPublicRecipe: () => controlTree.getPublicRecipe(store.getState()),
    getExpandedRecipe: () => controlTree.getExpandedRecipe(store.getState()),
    getExpandedRecipeNested: () =>
      controlTree.getExpandedRecipeNested(store.getState()),
    legacyRecipeDataFlat: () =>
      createLegacyRecipeDataFlatSelector(controlTree)(store.getState()),
    legacyRecipeDataNested: () =>
      createLegacyRecipeDataNestedSelector(controlTree)(store.getState()),
    setRecipe: (d) => {
      // NOTE: Test any recipe locally.
      // On production or wherever run:
      // > copy(JSON.stringify(getRecipe()))
      // Locally run:
      // > s = '{...}'
      // > setRecipe(JSON.parse(s))
      // Uploaded files won't work and need to be reuploaded.
      store.dispatch(controlTree.setValues(d))
    },
  }) as const

export { createUtils }
