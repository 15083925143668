import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import { match, concat } from 'redux-fp'
import { array as toposort } from 'toposort'
import stringify from 'json-stable-stringify'

import { mapValuesWithKey } from '../utils'
import { Text, Conditional, Repeater, Select, FileUpload } from './nodes'
import { getAllOptions, $$naturalValue } from './nodes/commons'
import bindNodes from './bindNodes'
import { controlize, ControlGroup, Fragment, Link, repeatedControl } from './ui'

const unspecifiedValue = $$naturalValue

const change = (path, value) => ({
  type: 'CHANGE',
  payload: { path, value },
})

const autoChange = (...args) => fp.set('payload.isAuto', true, change(...args))

const addNode = (repeaterPath) => ({
  type: 'ADD_NODE',
  payload: { repeaterPath },
})

const removeNode = (repeaterPath, controlId) => ({
  type: 'REMOVE_NODE',
  payload: { repeaterPath, controlId },
})

const commitChanges = () => ({ type: 'COMMIT' })
const cancelChanges = () => ({ type: 'CANCEL' })

const setChanges = (changes) => ({
  type: 'SET_CHANGES',
  payload: changes,
})

const setValues = (values) => ({ type: 'SET_VALUES', payload: values })

const resetValues = () => ({ type: 'RESET_VALUES' })

/* eslint-disable no-param-reassign */
const digest = fp.update('controlTree', (ct) => {
  ct = fp.set('values', {
    ...fp.get('values', ct),
    ...fp.get('pendingChanges.user', ct),
    ...fp.get('pendingChanges.auto', ct),
  })(ct)

  const updatedPreferredValues = fp.pipe(
    fp.get('pendingChanges.user'),
    mapValuesWithKey((v, k) =>
      fp.concat(fp.get(['preferredValues', k], ct) || [], [v]),
    ),
  )(ct)

  ct = fp.update(
    ['preferredValues'],
    fp.assign(fp.__, updatedPreferredValues),
  )(ct)

  ct = fp.set('pendingChanges', { auto: {}, user: {} })(ct)

  return fp.update(
    'values',
    fp.omitBy((v) => v === undefined),
  )(ct)
})
/* eslint-enable no-param-reassign */

const hasPendingUserChanges = fp.pipe(
  fp.get('controlTree.pendingChanges.user'),
  fp.negate(fp.isEmpty),
)

const hasPendingAutoChanges = fp.pipe(
  fp.get('controlTree.pendingChanges.auto'),
  fp.negate(fp.isEmpty),
)

const hasPendingChanges = fp.overSome([
  hasPendingAutoChanges,
  hasPendingUserChanges,
])

const handleAdd = match(
  (x) => () => x.type === 'ADD_NODE',
  ({ payload: { repeaterPath } }) =>
    fp.update(['controlTree', 'repeatedNodes', repeaterPath], (ids) =>
      fp.concat(ids, fp.isEmpty(ids) ? 1 : fp.max(ids) + 1),
    ),
)

// Changes key of repeated nodes to be sequential.
const resetRepeaterControlIds = (state) => {
  _.forEach(state.controlTree.repeatedNodes, (repeatedIds, repeatedKey) => {
    const replacements = {}
    const newIds = []
    _.forEach(repeatedIds, (repeatedId, index) => {
      const newId = index + 1

      newIds.push(newId)

      if (repeatedId !== newId) {
        replacements[repeatedId] = newId
      }
    })

    state.controlTree.repeatedNodes[repeatedKey] = newIds

    const replacer = (newId, oldId) => (v, k) => {
      if (_.startsWith(k, `${repeatedKey}.${oldId}.`)) {
        return k.replace(`.${oldId}.`, `.${newId}.`)
      }
      return k
    }

    _.forEach(replacements, (newId, oldId) => {
      state.controlTree.pendingChanges.auto = _.mapKeys(
        state.controlTree.pendingChanges.user,
        replacer(newId, oldId),
      )
      state.controlTree.pendingChanges.user = _.mapKeys(
        state.controlTree.pendingChanges.user,
        replacer(newId, oldId),
      )
      state.controlTree.preferredValues = _.mapKeys(
        state.controlTree.preferredValues,
        replacer(newId, oldId),
      )
      state.controlTree.values = _.mapKeys(
        state.controlTree.values,
        replacer(newId, oldId),
      )
    })
  })

  return state
}

const handleRemove = match(
  (x) => () => x.type === 'REMOVE_NODE',
  ({ payload: { repeaterPath, controlId } }) =>
    (state) => {
      const omit = fp.omitBy((v, k) =>
        fp.startsWith(`${repeaterPath}.${controlId}.`, k),
      )
      return resetRepeaterControlIds(
        fp.update(
          'controlTree',
          fp.pipe(
            fp.update(
              ['repeatedNodes', repeaterPath],
              fp.reject((id) => id === controlId),
            ),
            fp.update('pendingChanges.user', omit),
            fp.update('pendingChanges.auto', omit),
            fp.update('preferredValues', omit),
            fp.update('values', omit),
          ),
        )(state),
      )
    },
)

const getRepeaterPaths = fp.pipe(
  fp.filter('repeaterPath'),
  fp.map('repeaterPath'),
  fp.uniq,
)

const handleCommit = match(
  (action) => (state) => action.type === 'COMMIT' && hasPendingChanges(state),
  () => digest,
)

const handleCancellation = match(
  (x) => () => x.type === 'CANCEL',
  () => fp.set('controlTree.pendingChanges', { auto: {}, user: {} }),
)

const autoCommit = match(
  (action) => (state) =>
    action.type === 'CHANGE' &&
    !action.payload.isAuto &&
    hasPendingUserChanges(state) &&
    fp.negate(hasPendingAutoChanges)(state),
  () => digest,
)

const withDefaultState = (nodes, tree) =>
  fp.update('controlTree', (controlTree) => {
    if (controlTree !== undefined) {
      return controlTree
    }

    const repeatedNodes = fp.pipe(
      getRepeaterPaths,
      fp.map((p) => {
        const defaultRepeats = fp.get(p, tree).props.defaultRepeats || 0
        return [p, fp.range(1, defaultRepeats + 1)]
      }),
      fp.fromPairs,
    )(nodes)

    return {
      values: {},
      preferredValues: {},
      pendingChanges: {
        user: {},
        auto: {},
      },
      repeatedNodes,
    }
  })

function getSortedKeyPaths(keyPathsInOriginalOrder, nodes) {
  let graph = fp.flatMap(
    (n) =>
      fp.pipe(
        fp.filter(fp.includes(fp.__, keyPathsInOriginalOrder)),
        fp.map((g) => [g, n.keyPath]),
      )(n.graphDependencies),
    nodes,
  )
  graph = fp.uniqWith(fp.isEqual, graph)
  return toposort(keyPathsInOriginalOrder, graph)
}

const keyNodes = (nodes) => {
  const key = fp.keyBy('keyPath')
  return fp.assign(key(nodes), key(fp.filter('isAvailable', nodes)))
}

function nodeReducer({ state, resolvedNodes }, node) {
  const setValue = (st, n) =>
    n.isVariantAvailable ?
      fp.set(['controlTree', 'values', n.keyPath], n.value)(state)
    : st

  const { repeaterPath, repeatedItemPath } = node
  let resolvedNodesNext
  let stateNext
  if (repeaterPath && repeatedItemPath) {
    const controlIds =
      fp.get(['controlTree', 'repeatedNodes', node.repeaterPath])(state) || []
    const resolvedRepeats = fp.map((controlId) =>
      node.resolve(
        state,
        keyNodes(resolvedNodes),
        `${repeaterPath}.${controlId}.${repeatedItemPath}`,
      ),
    )(controlIds)
    resolvedNodesNext = fp.concat(resolvedNodes, resolvedRepeats)

    stateNext = fp.reduce(setValue, state, resolvedRepeats)
  } else {
    const resolvedNode = node.resolve(state, keyNodes(resolvedNodes))
    resolvedNodesNext = fp.concat(resolvedNodes, resolvedNode)

    stateNext = setValue(state, resolvedNode)
  }

  return { state: stateNext, resolvedNodes: resolvedNodesNext }
}

function resolveNodes(state, nodes) {
  const { resolvedNodes } = fp.reduce(
    nodeReducer,
    {
      state: digest(state),
      resolvedNodes: [],
    },
    nodes,
  )

  return keyNodes(resolvedNodes)
}

const mapValueToUniqId = (value) =>
  fp.isArray(value) ? fp.map((x) => x.id || x, value) : value

const getCacheKey = (state) =>
  stringify({
    pendingChanges: {
      auto: fp.mapValues(
        mapValueToUniqId,
        state.controlTree.pendingChanges.auto,
      ),
      user: fp.mapValues(
        mapValueToUniqId,
        state.controlTree.pendingChanges.user,
      ),
    },
    values: fp.mapValues(mapValueToUniqId, state.controlTree.values),
    preferredValues: fp.mapValues(
      fp.map(mapValueToUniqId),
      state.controlTree.preferredValues,
    ),
    repeatedNodes: fp.mapValues(
      mapValueToUniqId,
      state.controlTree.repeatedNodes,
    ),
  })

/**
 * Create control tree.
 *
 * Main entry point.
 */
function createControlTree(tree) {
  let nodes = bindNodes(tree, { path: [], dependencies: [], controlTree: {} })

  const keyPathsInOriginalOrder = fp.uniq(fp.map('keyPath', nodes))
  const sortedKeyPaths = getSortedKeyPaths(keyPathsInOriginalOrder, nodes)
  const getKeyPathIndex = (node) => fp.indexOf(node.keyPath, sortedKeyPaths)

  nodes = fp.sortBy(getKeyPathIndex, nodes)

  const repeaterPaths = getRepeaterPaths(nodes)

  // Check that node dependencies are valid.
  _.forEach(nodes, (node) => {
    // TODO: For some reason dependency can be undefined, that's not right.
    _.forEach(node.graphDependencies, (dep) => {
      if (dep === undefined || fp.startsWith('sheet:', dep)) {
        return
      }

      if (repeaterPaths.includes(dep)) {
        return
      }

      const hasDependency = !!fp.find({ keyPath: dep }, nodes)

      if (!hasDependency) {
        throw Error(`Dependency ${dep} not found for node ${node.keyPath}`)
      }
    })
  })

  const getNodes = _.memoize((state) => resolveNodes(state, nodes), getCacheKey)

  const dispose = () => {
    getNodes.cache.clear()
  }

  function getStateFromData(data) {
    const repeatedNodes = fp.fromPairs(
      fp.map((repeaterPath) => {
        const repeatedIndexes = fp.pipe(
          fp.keys,
          fp.filter((k) => fp.startsWith(`${repeaterPath}.`, k)),
          fp.map((k) => {
            const k2 = k.slice(repeaterPath.length + 1)
            return parseInt(k2.slice(0, k2.indexOf('.')), 10)
          }),
          fp.uniq,
          fp.sortBy((x) => x),
        )(data)
        return [repeaterPath, repeatedIndexes]
      }, repeaterPaths),
    )

    return {
      controlTree: {
        pendingChanges: { auto: {}, user: {} },
        preferredValues: {},
        repeatedNodes,
        values: data,
      },
    }
  }

  function getRepeatedNodes(state, path) {
    return state.controlTree.repeatedNodes[path]
  }

  function getRecipe(state, opts = {}) {
    return fp.pipe(
      opts.omitPrivate ? fp.omitBy('isPrivate') : fp.identity,
      fp.mapValues('value'),
      fp.omitBy(fp.isUndefined),
    )(getNodes(state))
  }

  let previousPublicRecipeNodes
  let previousPublicRecipe
  function getPublicRecipe(state) {
    const nodes = getNodes(state)
    if (nodes === previousPublicRecipeNodes) return previousPublicRecipe
    previousPublicRecipeNodes = nodes
    previousPublicRecipe = getRecipe(state, { omitPrivate: true })
    return previousPublicRecipe
  }

  function getExpandedRecipe(state) {
    return fp.pipe(
      fp.omitBy((n) => n.value === undefined),
      fp.mapValues((n) => {
        if (n.value === null) {
          return n.value
        }

        const allOptions = getAllOptions(n)
        if (!allOptions) {
          return n.value
        }

        if (n.multiple) {
          return fp.map((v) => fp.find({ id: v })(allOptions))(n.value)
        }
        return fp.find({ id: n.value })(allOptions)
      }),
    )(getNodes(state))
  }

  function getInvalidNodes(state) {
    try {
      const rawNodes = getNodes(state)

      const publicNodes = fp.filter({ isPrivate: false }, rawNodes)

      const errorNodes = fp.filter((node) => node.error === true, publicNodes)
      if (errorNodes.length > 0) {
        return errorNodes
      }

      const changedNodes = fp.filter(
        (node) =>
          !fp.isEqual(node.value, state.controlTree.values[node.keyPath]) &&
          node.isAvailable,
        publicNodes,
      )
      if (changedNodes.length > 0) {
        return changedNodes
      }

      return undefined
    } catch (error) {
      return [
        {
          keyPath: 'error',
          errorMessage: error.message,
        },
      ]
    }
  }

  function getInvalidNodeValues(state) {
    const invalidNodes = getInvalidNodes(state)
    if (!invalidNodes) {
      return undefined
    }
    const invalidValues = _.transform(
      invalidNodes,
      (result, node) => {
        result[node.keyPath] =
          node.keyPath in state.controlTree.values ?
            state.controlTree.values[node.keyPath]
          : node.errorMessage || 'Not Specified'
      },
      {},
    )
    return invalidValues
  }

  const isValid = (state) =>
    !Object.values(getNodes(state)).some((x) => x.error)

  function isPathValid(keyPath) {
    const isRepeaterNode = fp.some(
      (repeaterPath) => fp.startsWith(`${repeaterPath}.`, keyPath),
      repeaterPaths,
    )
    if (isRepeaterNode) {
      return true
    }
    return !!fp.find({ keyPath }, nodes)
  }

  function isConsistent(state) {
    return !fp.some((n) => n.error && n.stopOnError)(getNodes(state))
  }

  // TODO: better name for nodes2
  const mapPendingChangesToChangeset = (nodes2) =>
    mapValuesWithKey((valueTo, keyPath) => {
      const node = nodes2[keyPath]

      if (node.isPrivate) {
        return null
      }

      const valueFrom = node.value
      const label = node.changeLabel || node.label || keyPath

      const becomesAvailable = valueFrom === undefined && valueTo !== undefined
      const becomesUnavailable = valueTo === undefined

      if (fp.isEqual(valueTo, valueFrom)) return null

      return {
        keyPath,
        becomesAvailable,
        becomesUnavailable,
        valueFrom,
        valueTo,
        label,
      }
    })

  function getChangesets(state) {
    const previousNodes = getNodes(handleCancellation(cancelChanges())(state))
    const currentNodes = getNodes(state)

    const { user, auto } = fp.get('controlTree.pendingChanges', state)

    const impossibleNodes = fp.filter((n) => n.error && n.stopOnError)(
      currentNodes,
    )
    const impossibleChanges = fp.map(({ keyPath }) => ({
      keyPath,
      label: currentNodes[keyPath].changeLabel || currentNodes[keyPath].label,
    }))(impossibleNodes)

    const userChanges = fp.pipe(
      fp.toArray,
      fp.compact,
    )(mapPendingChangesToChangeset(previousNodes)(user))

    const {
      autoChanges = [],
      becomesAvailable = [],
      becomesUnavailable = [],
    } = fp.pipe(
      mapPendingChangesToChangeset(previousNodes),
      fp.toArray,
      fp.compact,
      fp.groupBy((x) => {
        const isValueChange = !x.becomesAvailable && !x.becomesUnavailable

        if (isValueChange) return 'autoChanges'
        if (x.becomesAvailable) return 'becomesAvailable'
        if (x.becomesUnavailable) return 'becomesUnavailable'
        return undefined
      }),
    )(auto)

    return {
      userChanges,
      autoChanges,
      becomesAvailable,
      becomesUnavailable,
      impossibleChanges,
    }
  }

  function getChangeLabels(state) {
    const { autoChanges, becomesAvailable, becomesUnavailable } =
      getChangesets(state)
    const changes = [].concat(autoChanges, becomesAvailable, becomesUnavailable)

    return fp.map((x) => {
      if (x.becomesAvailable) {
        return `${x.label} becomes available`
      }
      if (x.becomesUnavailable) {
        return `${x.label} becomes unavailable`
      }
      return `${x.label} from ${x.valueFrom} to ${x.valueTo}`
    })(changes)
  }

  const handleChange = match(
    (x) => () => x.type === 'CHANGE',
    (action) => {
      const { path, value } = action.payload

      if (!isPathValid(path)) {
        throw Error(`Path ${path} is not valid!`)
      }

      return fp.update('controlTree.pendingChanges', (changes) => {
        if (action.payload.isAuto) {
          const hasUserChange = fp.has(['user', path], changes)
          if (
            !hasUserChange ||
            (hasUserChange &&
              !fp.isEqual(value, fp.get(['user', path], changes)))
          ) {
            return fp.set(['auto', path], value)(changes)
          }
        } else {
          return fp.set(['user', path], value)(changes)
        }

        return changes
      })
    },
  )

  const handleChangeWithDerived = match(
    (x) => () => x.type === 'CHANGE',
    (action) => (state) => {
      const { path } = action.payload

      const node = getNodes(state)[path]

      if (
        action.payload.value !== undefined &&
        node !== undefined &&
        fp.isFunction(node.onChange)
      ) {
        const actionCreator = action.payload.isAuto ? autoChange : change
        const updates = node.onChange(action.payload.value)
        return fp.reduce(
          // TODO: better names
          (state2, action2) => handleChange(action2)(state2),
          state,
          fp.map((k) => actionCreator(k, updates[k]), fp.keys(updates)),
        )
      }

      return handleChange(action)(state)
    },
  )

  const computeChanges = fp.compose(
    match((x) => () => x.type === 'CHANGE'),
    match((x) => () => !x.payload.isAuto),
  )((action) => (state) => {
    const currentState = fp.set('controlTree.pendingChanges', {
      auto: {},
      user: {},
    })(state)
    let nextState = fp.set('controlTree.pendingChanges.auto', {})(state)
    nextState = handleChangeWithDerived(action)(nextState)

    const currentNodes = getNodes(currentState)
    const nextNodes = getNodes(nextState)

    const keyPaths = fp.union(fp.keys(currentNodes), fp.keys(nextNodes))
    const userChanges = fp.get('controlTree.pendingChanges.user', nextState)

    const actions = fp.pipe(
      fp.map((keyPath) => {
        const currentValue = currentNodes[keyPath].value
        const nextValue = nextNodes[keyPath].value

        const userChange = fp.get([keyPath], userChanges)
        const nextValueIsSameUserChange =
          userChange !== undefined && fp.isEqual(userChange, nextValue)
        if (nextValueIsSameUserChange) {
          return change(keyPath, nextValue)
        }

        if (fp.isEqual(currentValue, nextValue)) {
          return null
        }

        return autoChange(keyPath, nextValue)
      }),
      fp.compact,
    )(keyPaths)

    return fp.reduce(
      (state2, action2) => handleChange(action2)(state2),
      currentState,
      actions,
    )
  })

  const guaranteeRepeatersFromValues = (values) => (state) =>
    fp.reduce(
      (st, repeaterPath) =>
        fp.update(['controlTree', 'repeatedNodes', repeaterPath], (ids) =>
          fp.pipe(
            fp.concat(
              fp.pipe(
                fp.keys,
                fp.filter((k) => fp.startsWith(`${repeaterPath}.`, k)),
                fp.map((k) =>
                  fp.toNumber(k.replace(`${repeaterPath}.`, '').split('.')[0]),
                ),
              )(values),
            ),
            fp.uniq,
            fp.sortBy((x) => x),
          )(ids),
        )(st),
      state,
      repeaterPaths,
    )

  const handleValues = match(
    (x) => () => x.type === 'SET_VALUES',
    ({ payload: values }) => {
      _.forEach(values, (_, path) => {
        if (!isPathValid(path)) {
          throw Error(`Path ${path} is not valid!`)
        }
      })

      return fp.pipe(
        guaranteeRepeatersFromValues(values),
        fp.set('controlTree.pendingChanges', { user: {}, auto: {} }),
        (state) =>
          fp.reduce(
            (st, k) => fp.set(['controlTree', 'values', k], values[k])(st),
            state,
            fp.keys(values),
          ),
      )
    },
  )

  const handleReset = match(
    (x) => () => x.type === 'RESET_VALUES',
    // Setting controlTree to undefined will trigger withDefaultState.
    () => fp.set('controlTree', undefined),
  )

  const handleChanges = match(
    (x) => () => x.type === 'SET_CHANGES',
    ({ payload }) =>
      fp.pipe(
        guaranteeRepeatersFromValues(payload),
        fp.set('controlTree.pendingChanges.user', payload),
      ),
  )

  const guaranteeState = withDefaultState(nodes, tree)

  const updater = concat(
    () => guaranteeState,
    computeChanges,
    handleCommit,
    handleCancellation,
    handleAdd,
    handleRemove,
    handleChanges,
    handleValues,
    handleReset,
    // autoCommit
  )

  const sortByKeyPaths = (values) =>
    _.defaults(
      fp.reduce(
        (result, key) => {
          if (key in values) {
            result[key] = values[key]
            return result
          }
          return result
        },
        {},
        keyPathsInOriginalOrder,
      ),
      values,
    )

  return {
    updater,

    dispose,
    getNodes: (st) => getNodes(guaranteeState(st)),
    getRecipe: (st, opts) => getRecipe(guaranteeState(st), opts),
    getPublicRecipe: (st) => getPublicRecipe(guaranteeState(st)),
    getExpandedRecipe: (st) => getExpandedRecipe(guaranteeState(st)),
    getExpandedRecipeNested: (st) => {
      const flatRecipe = getExpandedRecipe(guaranteeState(st))
      return _.transform(flatRecipe, (r, value, key) => {
        _.set(r, key, value)
      })
    },
    // #TODO: Guarantee state on methods below.
    getStateFromData,
    getRepeatedNodes,
    getChangesets,
    getChangeLabels,
    getInvalidNodes,
    getInvalidNodeValues,
    isValid,
    isConsistent,
    isPathValid,
    hasPendingChanges,

    change,
    commitChanges,
    cancelChanges,
    addNode,
    removeNode,
    setChanges,
    setValues,
    resetValues,

    keyPathsInOriginalOrder,
    sortByKeyPaths,
  }
}

// Public API
export {
  Text,
  Conditional,
  Repeater,
  FileUpload,
  Select,
  createControlTree,
  controlize,
  ControlGroup,
  Fragment,
  Link,
  unspecifiedValue,
  repeatedControl,
  change,
  commitChanges,
  cancelChanges,
  setChanges,
  setValues,
  resetValues,
}
