import { connect } from 'react-redux'

import {
  isRecipeFinalizedSelector,
  recipeIdSelector,
} from '~p/client/common/selectors'
import { modifyRecipe, openMenu } from '~p/client/common/actions'

import Finalize from '~mizuno/client/components/organisms/Finalize'
import controlTree from '~c/client/controlTree'

const mapStateToProps = (state, props) => ({
  state: isRecipeFinalizedSelector(state) ? 'on' : 'off',
  recipeId: recipeIdSelector(state),
  recipeLabel: 'Design ID:',

  ...props,
})

const mapDispatchToProps = (dispatch, props) => ({
  onResume: () => {
    dispatch(openMenu(controlTree, props.resetMenu))
    dispatch(modifyRecipe())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Finalize)
