import React from 'react'
import { v4 as uuid4 } from 'uuid'

import { cn } from '~p/client/components/utils'

import Icon from '../atoms/Icon'

import './BlockChanges.css'

const renderChanges = (changes) => (
  <div className={cn(['blockChanges'])}>
    <div className={cn(['blockChanges-icon'])}>
      <Icon name="info" />
    </div>
    <div className={cn(['blockChanges-body'])}>
      {changes.map((it) => (
        <div key={uuid4()} className={cn(['blockChanges-item'])}>
          {it}
        </div>
      ))}
    </div>
  </div>
)

function BlockChanges(props) {
  const { changes = [] } = props

  if (changes !== null && changes.length > 0) {
    return renderChanges(changes)
  }

  return null
}

export default BlockChanges

export function hasChanges(changes = []) {
  return changes !== null && changes.length > 0
}
