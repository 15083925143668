import React from 'react'
import * as Sentry from '@sentry/browser'

export default class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch(error) {
    this.setState({ hasError: true })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return <div style={{ backgroundColor: 'red' }}>Something went wrong.</div>
    }
    return this.props.children
  }
}
