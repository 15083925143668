// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveRow } from '../typings';

const data = [
  {
    "id": "GMP2-100DC",
    "name": "GMP2-100DC",
    "sku": "31288300001200",
    "asset": {
      "gloveAssetId": "100"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "deep",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": true,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": true,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": true,
        "TA26": true,
        "TA4": false,
        "TAD": true,
        "TAMTF": true,
        "TAS": true,
        "TAS2": false,
        "TAST": true,
        "TR": false
      }
    },
    "defaults": {
      "webId": "TA"
    }
  },
  {
    "id": "GMP2-400RC",
    "name": "GMP2-400RC",
    "sku": "31285900001150",
    "asset": {
      "gloveAssetId": "400"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "regular",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "T"
    }
  },
  {
    "id": "GMP2-400SC",
    "name": "GMP2-400SC",
    "sku": "31288500001150",
    "asset": {
      "gloveAssetId": "400"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "shallow",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GMP2-600DC",
    "name": "GMP2-600DC",
    "sku": "31288700001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "deep",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "H"
    }
  },
  {
    "id": "GMP2-600RC",
    "name": "GMP2-600RC",
    "sku": "31286500001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "regular",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "T"
    }
  },
  {
    "id": "GMP2-600SC",
    "name": "GMP2-600SC",
    "sku": "31286400001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "shallow",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GMP2-700DC",
    "name": "GMP2-700DC",
    "sku": "31289100001275",
    "asset": {
      "gloveAssetId": "700"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "pocket": "deep",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": true,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": true,
        "H": false,
        "IC": true,
        "PY": false,
        "SH1": false,
        "SH2": true,
        "SH21": true,
        "SH24": true,
        "SH4": false,
        "SH8": true,
        "SHY2": true,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "DH"
    }
  },
  {
    "id": "GMP2-300FBMC",
    "name": "GMP2-300FBMC",
    "sku": "0",
    "asset": {
      "gloveAssetId": "300"
    },
    "props": {
      "isHidden": true,
      "serieId": "mizunoPro",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false
      },
      "webIds": {
        "static": true,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GMP2-335CC",
    "name": "GMP2-335CC",
    "sku": "31289300003350",
    "asset": {
      "gloveAssetId": "335"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoPro",
      "price": {
        "USD": 550,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true
      },
      "webIds": {
        "static": true,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GMP2-100DCM",
    "name": "GMP2-100DCM",
    "sku": "31288400001200",
    "asset": {
      "gloveAssetId": "100"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "deep",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": true,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": true,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": true,
        "TA26": true,
        "TA4": false,
        "TAD": true,
        "TAMTF": true,
        "TAS": true,
        "TAS2": false,
        "TAST": true,
        "TR": false
      }
    },
    "defaults": {
      "webId": "TA"
    }
  },
  {
    "id": "GMP2-400RCM",
    "name": "GMP2-400RCM",
    "sku": "31285900001150",
    "asset": {
      "gloveAssetId": "400"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "regular",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "T"
    }
  },
  {
    "id": "GMP2-400SCM",
    "name": "GMP2-400SCM",
    "sku": "31288600001150",
    "asset": {
      "gloveAssetId": "400"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "shallow",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GMP2-600DCM",
    "name": "GMP2-600DCM",
    "sku": "31289000001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "deep",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "H"
    }
  },
  {
    "id": "GMP2-600RCM",
    "name": "GMP2-600RCM",
    "sku": "31288800001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "regular",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "T"
    }
  },
  {
    "id": "GMP2-600SCM",
    "name": "GMP2-600SCM",
    "sku": "31288900001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "shallow",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": true,
        "CR9": true,
        "CRSH1": true,
        "CRSH2": true,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": true,
        "D3I": false,
        "D3S": true,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GMP2-700DCM",
    "name": "GMP2-700DCM",
    "sku": "31285800001275",
    "asset": {
      "gloveAssetId": "700"
    },
    "props": {
      "isHidden": false,
      "serieId": "mizunoProMesh",
      "pocket": "deep",
      "price": {
        "USD": 500,
        "CAD": 600
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": true,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": true,
        "H": false,
        "IC": true,
        "PY": false,
        "SH1": false,
        "SH2": true,
        "SH21": true,
        "SH24": true,
        "SH4": false,
        "SH8": true,
        "SHY2": true,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "DH"
    }
  },
  {
    "id": "GPS1-100DC",
    "name": "GPS1-100DC",
    "sku": "31289600001200",
    "asset": {
      "gloveAssetId": "100"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "deep",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": true,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "TA"
    }
  },
  {
    "id": "GPS1-400RC",
    "name": "GPS1-400RC",
    "sku": "31289700001150",
    "asset": {
      "gloveAssetId": "400"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "regular",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "T"
    }
  },
  {
    "id": "GPS1-400SC",
    "name": "GPS1-400SC",
    "sku": "31290500001150",
    "asset": {
      "gloveAssetId": "400"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "shallow",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GPS1-600DC",
    "name": "GPS1-600DC",
    "sku": "31286000001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "deep",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "H"
    }
  },
  {
    "id": "GPS1-600RC",
    "name": "GPS1-600RC",
    "sku": "31289800001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "regular",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "T"
    }
  },
  {
    "id": "GPS1-600SC",
    "name": "GPS1-600SC",
    "sku": "31290000001175",
    "asset": {
      "gloveAssetId": "600"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "shallow",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GPS1-700DC",
    "name": "GPS1-700DC",
    "sku": "31286100001275",
    "asset": {
      "gloveAssetId": "700"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "pocket": "deep",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": true,
        "PY": false,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "H"
    }
  },
  {
    "id": "GPS1-300FBMC",
    "name": "GPS1-300FBMC",
    "sku": "31289400001250",
    "asset": {
      "gloveAssetId": "300"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false
      },
      "webIds": {
        "static": true,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GPS1-335CC",
    "name": "GPS1-335CC",
    "sku": "31289500003350",
    "asset": {
      "gloveAssetId": "335-proSelect"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectBaseball",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true
      },
      "webIds": {
        "static": true,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GPSF-1150C",
    "name": "GPSF-1150C",
    "sku": "31290100001150",
    "asset": {
      "gloveAssetId": "1150C"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectFastpitch",
      "pocket": "regular",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GPSF-1175C",
    "name": "GPSF-1175C",
    "sku": "31288200001175",
    "asset": {
      "gloveAssetId": "1175C"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectFastpitch",
      "pocket": "regular",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GPSF-1200C",
    "name": "GPSF-1200C",
    "sku": "31290300001200",
    "asset": {
      "gloveAssetId": "1200C"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectFastpitch",
      "pocket": "regular",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": true,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GPSF-1250C",
    "name": "GPSF-1250C",
    "sku": "31290400001250",
    "asset": {
      "gloveAssetId": "1250C"
    },
    "props": {
      "isHidden": false,
      "serieId": "proSelectFastpitch",
      "pocket": "regular",
      "price": {
        "USD": 300,
        "CAD": 480
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": true,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "static"
    }
  },
  {
    "id": "GCCFP1151",
    "name": "GCCFP1151",
    "sku": "31168000000000",
    "asset": {
      "gloveAssetId": "1151"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 250,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": true,
        "D10": false,
        "D2": false,
        "D3": true,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": true,
        "IC": true,
        "PY": true,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": true,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": true
      }
    },
    "defaults": {
      "webId": "D3"
    }
  },
  {
    "id": "GCCFP1202",
    "name": "GCCFP1202",
    "sku": "31168200000000",
    "asset": {
      "gloveAssetId": "1202"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 250,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": true,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": true,
        "D9": true,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": true,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": true,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": true,
        "TAST": false,
        "TR": true
      }
    },
    "defaults": {
      "webId": "D9"
    }
  },
  {
    "id": "GCCFP1203",
    "name": "GCCFP1203",
    "sku": "31188800000000",
    "asset": {
      "gloveAssetId": "1203"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 250,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": true,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": true,
        "D9": true,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": true,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": true,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": true,
        "TAST": false,
        "TR": true
      }
    },
    "defaults": {
      "webId": "TA4"
    }
  },
  {
    "id": "GCCFP1252",
    "name": "GCCFP1252",
    "sku": "31168400000000",
    "asset": {
      "gloveAssetId": "1252"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 250,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": true,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": true,
        "D9": true,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": true,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": true,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": true
      }
    },
    "defaults": {
      "webId": "D10"
    }
  },
  {
    "id": "GCCFP1253",
    "name": "GCCFP1253",
    "sku": "31188900000000",
    "asset": {
      "gloveAssetId": "1253"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 250,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": true,
        "outfielder": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": true,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": true,
        "D9": true,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": true,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": true,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": true
      }
    },
    "defaults": {
      "webId": "D8T"
    }
  },
  {
    "id": "GCCFP1302",
    "name": "GCCFP1302",
    "sku": "31189000000000",
    "asset": {
      "gloveAssetId": "1302"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 250,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false
      },
      "webIds": {
        "static": false,
        "CM": false,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": true,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": true,
        "SH1": false,
        "SH2": true,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": true,
        "TA0": false,
        "TA26": false,
        "TA4": true,
        "TAD": false,
        "TAMTF": false,
        "TAS": true,
        "TAS2": false,
        "TAST": false,
        "TR": true
      }
    },
    "defaults": {
      "webId": "SH2"
    }
  },
  {
    "id": "GCCFPCM33",
    "name": "GCCFPCM33",
    "sku": "31168600000000",
    "asset": {
      "gloveAssetId": "CM33"
    },
    "props": {
      "isHidden": false,
      "serieId": "customClassicFastpitch",
      "price": {
        "USD": 275,
        "CAD": 380
      }
    },
    "limitations": {
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "positionIds": {
        "infielder": false,
        "outfielder": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true
      },
      "webIds": {
        "static": false,
        "CM": true,
        "CR2": false,
        "CR8": false,
        "CR9": false,
        "CRSH1": false,
        "CRSH2": false,
        "D1": false,
        "D10": false,
        "D2": false,
        "D3": false,
        "D3C": false,
        "D3I": false,
        "D3S": false,
        "D8T": false,
        "D9": false,
        "DH": false,
        "H": false,
        "IC": false,
        "PY": false,
        "SH1": false,
        "SH2": false,
        "SH21": false,
        "SH24": false,
        "SH4": false,
        "SH8": false,
        "SHY2": false,
        "T": false,
        "TA": false,
        "TA0": false,
        "TA26": false,
        "TA4": false,
        "TAD": false,
        "TAMTF": false,
        "TAS": false,
        "TAS2": false,
        "TAST": false,
        "TR": false
      }
    },
    "defaults": {
      "webId": "CM"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveRow[]>> = data;

export type Glove = typeof typed[number];

export const GLOVE_INDEX_KEY = "id";
export type GloveIndexKey = "id";
export type GloveId = Glove["id"];

let i = 0;
export const GLOVE_DICT = {
  "GMP2-100DC": typed[i++],
  "GMP2-400RC": typed[i++],
  "GMP2-400SC": typed[i++],
  "GMP2-600DC": typed[i++],
  "GMP2-600RC": typed[i++],
  "GMP2-600SC": typed[i++],
  "GMP2-700DC": typed[i++],
  "GMP2-300FBMC": typed[i++],
  "GMP2-335CC": typed[i++],
  "GMP2-100DCM": typed[i++],
  "GMP2-400RCM": typed[i++],
  "GMP2-400SCM": typed[i++],
  "GMP2-600DCM": typed[i++],
  "GMP2-600RCM": typed[i++],
  "GMP2-600SCM": typed[i++],
  "GMP2-700DCM": typed[i++],
  "GPS1-100DC": typed[i++],
  "GPS1-400RC": typed[i++],
  "GPS1-400SC": typed[i++],
  "GPS1-600DC": typed[i++],
  "GPS1-600RC": typed[i++],
  "GPS1-600SC": typed[i++],
  "GPS1-700DC": typed[i++],
  "GPS1-300FBMC": typed[i++],
  "GPS1-335CC": typed[i++],
  "GPSF-1150C": typed[i++],
  "GPSF-1175C": typed[i++],
  "GPSF-1200C": typed[i++],
  "GPSF-1250C": typed[i++],
  "GCCFP1151": typed[i++],
  "GCCFP1202": typed[i++],
  "GCCFP1203": typed[i++],
  "GCCFP1252": typed[i++],
  "GCCFP1253": typed[i++],
  "GCCFP1302": typed[i++],
  "GCCFPCM33": typed[i++]
} as const;

export { typed as GLOVES };
