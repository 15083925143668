import React from 'react'

import { cn, mods, states } from '~p/client/components/utils'

import './Button.css'

function Button(props) {
  const { onClick, classMods, classStates, children } = props

  return (
    <button
      className={cn(['button', mods(classMods), states(classStates)])}
      data-gtm={props['data-gtm']}
      onClick={onClick}
    >
      {/* https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers */}
      <span className="button-container">{children}</span>
    </button>
  )
}

export default Button
