import React, { useState } from 'react'

import { sendToFactory } from '../../../../../platform/client/factory'
import {
  cn,
  mods,
  states,
} from '../../../../../platform/client/components/utils'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import TextInput from '../molecules/TextInput'
import CopyUrl from '../../containers/CopyUrl'
import ResetRecipe from '../../containers/ResetRecipe'

import './Finalize.css'

function Finalize(props) {
  const {
    onSave,
    onOrder,
    onResume,
    onAfterResume,
    saveButtonLabel,
    orderButtonLabel,
    orderButtonIcon,
    resumeButtonLabel,
    orderMethodGTM,
    state = 'off',
    recipeLabel,
    recipeId,
    children,
    isAdmin,
    isOrderDisabled,
  } = props

  const [orderId, setOrderId] = useState('')

  return (
    <div className={cn(['finalize', states([state])])}>
      <div className={cn(['finalize-header'])}>
        <div className={cn(['finalize-headerVariation', mods(['off'])])}>
          <Button classMods={['black']} onClick={onSave} data-gtm="recipe-save">
            {saveButtonLabel}
          </Button>
          <ResetRecipe />
        </div>
        <div className={cn(['finalize-headerVariation', mods(['on'])])}>
          <span className="finalize-recipe">
            <span className="finalize-recipe-label">{recipeLabel}</span>
            <strong>{recipeId}</strong>
          </span>
          <div className="finalize-headerButtons">
            {isAdmin ?
              <>
                <TextInput
                  subline="Sales ID"
                  value={orderId}
                  onChange={(v) => {
                    setOrderId(v)
                  }}
                />
                <Button
                  classMods={['black', 'hasIcon']}
                  onClick={async () => {
                    // eslint-disable-next-line no-alert
                    const isConfirmed = window.confirm(
                      `Are you sure? Sales ID: "${orderId}"`,
                    )
                    if (!isConfirmed) {
                      return
                    }

                    const { error } = await sendToFactory(
                      recipeId,
                      orderId,
                      process.env.NODE_ENV === 'development',
                    )

                    if (error) {
                      console.error(error)
                      // eslint-disable-next-line no-alert
                      alert(
                        `There was an issue with sending the order to the factory!`,
                      )
                    } else {
                      // eslint-disable-next-line no-alert
                      alert(
                        `Order with ID of "${orderId}" was sent to the factory!`,
                      )
                      setOrderId('')
                    }
                  }}
                >
                  <Icon name="upload" /> Send to Factory
                </Button>
              </>
            : <>
                {!isOrderDisabled && (
                  <Button
                    classMods={['black', 'largeFont', 'hasIcon']}
                    onClick={onOrder}
                    data-gtm={`order-${orderMethodGTM}-initiate`}
                  >
                    <Icon name={orderButtonIcon} /> {orderButtonLabel}
                  </Button>
                )}
                <CopyUrl />
              </>
            }
          </div>
        </div>
      </div>
      <div className={cn(['finalize-body'])}>
        {children}
        <div className={cn(['finalize-footer'])}>
          <Button
            classMods={['gray', 'hasIcon']}
            onClick={() => {
              onResume()
              if (onAfterResume) {
                onAfterResume()
              }
            }}
            data-gtm="recipe-exit"
          >
            <Icon name="edit" /> {resumeButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Finalize
