import { createAction } from 'redux-actions'

const setRenderCompositeLoading = createAction('SET_RENDER_COMPOSITE_LOADING')
const setRenderCompositePreferredSize = createAction(
  'SET_RENDER_COMPOSITE_PREFERRED_SIZE',
)
const setRenderCompositeActiveView = createAction(
  'SET_RENDER_COMPOSITE_ACTIVE_VIEW',
)

export {
  setRenderCompositeLoading,
  setRenderCompositePreferredSize,
  setRenderCompositeActiveView,
}
