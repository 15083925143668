import fp from 'lodash/fp.js'
import { createSelector } from 'reselect'

import bindNodes from '../bindNodes'

import {
  $$naturalValue,
  LeafNode,
  AbstractNode,
  createDependencyResolver,
  getAllOptions,
} from './commons'

export { AbstractNode }

/**
 * Selection data node type.
 */
export class SelectNode extends LeafNode {
  nodeKind = 'SelectNode'

  static defaults = {
    dependencies: [],
    isPrivate: false,
    defaultValue: $$naturalValue,
    isRequired: true,
    multiple: false,
    isAvailable: true,
    stopOnError: false,
  }

  static specialProps = []

  static resolveValue(state, node) {
    if (node.stopOnError) {
      let value = fp.get(['controlTree', 'values', node.keyPath])(state)
      if (value === undefined) {
        value = node.defaultValue
      }

      return value
    }

    let naturalDefault = []
    const options = getAllOptions(node)
    if (options) {
      const naturalDefaultOption =
        fp.isPlainObject(options) ? Object.values(options)[0] : options[0]
      if (naturalDefaultOption) naturalDefault = naturalDefaultOption.id
    }

    const preferredValues =
      node.ignorePreferred ? undefined : (
        fp.get(['controlTree', 'preferredValues', node.keyPath], state)
      )

    const values = fp.flatten([
      naturalDefault,
      [node.defaultValue],
      fp.pipe(fp.get(['controlTree', 'values', node.keyPath]), (vz) =>
        vz !== undefined ? [vz] : [],
      )(state),
      node.multiple ? [preferredValues] : preferredValues || [],
    ])

    return fp.findLast((value) => this.isValid(value, node))(values) || null
  }

  static resolveObject(value, node) {
    if (value === null) {
      return null
    }

    const options = getAllOptions(node) || []
    if (node.multiple) {
      return fp.filter(({ id }) => fp.includes(id, value))(options)
    }
    return fp.find({ id: value })(options)
  }

  static getNaturalValue(node) {
    if (node.isRequired) {
      const options = getAllOptions(node) || []
      const firstOption = fp.first(fp.map((value) => value.id, options))
      return node.multiple ? [firstOption] : firstOption
    }

    return null
  }

  static isValid(value, node) {
    if (value === undefined) {
      return false
    }

    if (!node.isRequired && value == null) {
      return true
    }

    if (value != null) {
      const options = getAllOptions(node) || []
      const ids = fp.map((value) => value.id, options)
      return node.multiple ?
          fp.isEqual(
            fp.sortBy(fp.identity, fp.intersection(value, ids)),
            fp.sortBy(fp.identity, value),
          )
        : fp.includes(value, ids)
    }

    return false
  }
}

/**
 * Select public constructor.
 */
export function Select(props) {
  return new SelectNode(props)
}

/**
 * Text input data node type.
 */
export class TextNode extends LeafNode {
  nodeKind = 'TextNode'

  static defaults = {
    dependencies: [],
    isPrivate: false,
    defaultValue: '',
    isRequired: true,
    isAvailable: true,
    validate: () => () => true,
    restrict: () => () => true,
    mapObject: () => (v) => v,
  }

  static specialProps = []

  static resolveValue(state, node) {
    let value = fp.get(['controlTree', 'values', node.keyPath], state)
    value = value !== undefined ? value : node.defaultValue
    return value === null ? '' : value
  }

  static resolveObject(value, node) {
    return node.mapObject(value)
  }

  static isValid(value, node) {
    if (value === undefined) {
      return false
    }

    if (!node.isRequired && value === '') {
      return true
    }

    if (node.maxLength && value.length > node.maxLength) {
      return false
    }

    if (node.pattern && value) {
      const isValid = new RegExp(node.pattern).test(value)
      if (!isValid) {
        return false
      }
    }

    return node.restrict(value) && node.validate(value)
  }
}

/**
 * Text public constructor.
 */
export function Text(props) {
  return new TextNode(props)
}

export class FileUploadNode extends LeafNode {
  nodeKind = 'FileUploadNode'

  static defaults = {
    dependencies: [],
    defaultValue: null,
    isRequired: true,
    isAvailable: true,
  }

  static isValid(value, node) {
    if (value === undefined) {
      return false
    }
    return value !== null || !node.isRequired
  }

  static resolveValue(state, node) {
    const value = fp.get(['controlTree', 'values', node.keyPath], state)
    return value !== undefined ? value : node.defaultValue
  }

  static resolveObject(value) {
    return value
  }
}

export function FileUpload(props) {
  return new FileUploadNode(props)
}

/**
 * Variant tree node type.
 */
export class ConditionalBranch extends AbstractNode {
  static defaults = {
    lookup: (d) => d.id,
  }

  bind(meta) {
    const lookup = createSelector(
      fp.map(createDependencyResolver, this.props.dependencies),
      this.props.lookup,
    )

    return fp.pipe(
      fp.keys,
      fp.flatMap((variantKey) =>
        bindNodes(this.props.variants[variantKey], {
          ...meta,
          isAvailable: (state) =>
            (meta.isAvailable || ((state2) => true))(state) &&
            lookup(state) === variantKey,
          dependencies: fp.concat(meta.dependencies, this.props.dependencies),
        }),
      ),
    )(this.props.variants)
  }
}

/**
 * Conditional public constructor.
 */
export function Conditional(props) {
  return new ConditionalBranch(props)
}

export class RepeaterNode extends AbstractNode {
  bind(meta) {
    const { controls } = this.props

    return fp.flatMap((key) =>
      bindNodes(controls[key], {
        dependencies: fp.concat(meta.dependencies, this.props.dependencies),
        path: fp.concat(meta.path, key),
        repeaterPath: meta.path.join('.'),
        repeatedItemPath: key,
      }),
    )(fp.keys(controls))
  }
}

export function Repeater(props) {
  return new RepeaterNode(props)
}
