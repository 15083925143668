const viewAngles = {
  back: {
    label: 'Back',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
  web: {
    label: 'Web',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
  palm: {
    label: 'Palm',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      height: 1024,
    },
  },
}

const defaultViewAngleId = 'web'

export default viewAngles
export { defaultViewAngleId }
