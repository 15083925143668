import Bluebird from 'bluebird'
import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'

import { createCanvas, render } from './canvas'

const generatePreviews =
  (viewNames = []) =>
  (loader, renderComposite, viewAngles, expandedRecipeNested) => {
    const queue = []
    _.forEach(viewNames, (viewName) => {
      const viewAngle = viewAngles[viewName]
      const {
        previewSize: { height },
      } = viewAngle

      const size = {
        height,
        width: Math.round(
          height /
            renderComposite.getAssetAspectRatio(viewName, expandedRecipeNested),
        ),
      }

      const layers = renderComposite.getLayers(
        expandedRecipeNested,
        size,
        viewName,
      )

      queue.push(
        () =>
          new Promise((resolve) => {
            loader.load(layers).then((assets) => {
              const canvas = createCanvas(size).canvas

              render(canvas, assets, size, viewName)

              canvas.toBlob((blob) => {
                resolve([viewName, blob])
              })
            })
          }),
      )
    })

    return new Promise((resolve) => {
      Bluebird.mapSeries(queue, (f) => f()).then((pairs) => {
        resolve(fp.fromPairs(pairs))
      })
    })
  }

export default generatePreviews
