import React from 'react'

import { cn } from '~p/client/components/utils'

import './ViewNavigation.css'

function ViewNavigation(props) {
  return (
    <div
      className={cn(
        ['viewNavigation'].concat(
          props.withHelp ? 'viewNavigationWithHelp' : [],
        ),
      )}
    >
      {props.children}
    </div>
  )
}

export default ViewNavigation
