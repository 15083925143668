import React from 'react'

import TextareaInput from '~mizuno/client/components/molecules/TextareaInput'

export default ({ subline, value, change }) => (
  <TextareaInput
    subline={subline}
    value={value}
    onChange={(ev) => change(ev.target.value)}
  />
)
