import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './ProductTile.css'

function ProductTile(props) {
  const { name, title, imageUrl, classMods, onClick, buttonText } = props
  return (
    <div
      className={cn(['productTile', mods(classMods)])}
      onClick={onClick}
      title={title}
    >
      <div
        className="productTile-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className="productTile-text">
        <span>{name}</span>
      </div>
      <div className="productTile-button">
        <div className="button mod-productTile">
          <span>{buttonText}</span>
        </div>
      </div>
    </div>
  )
}

export default ProductTile
