import React from 'react'

import { cn } from '~p/client/components/utils'

import HorizontalAlign from '../atoms/HorizontalAlign'

import './TypeNavigation.css'

function TypeNavigation(props) {
  const { children } = props
  return (
    <div className={cn(['typeNavigation'])}>
      <HorizontalAlign>{children}</HorizontalAlign>
    </div>
  )
}

export default TypeNavigation
