import React from 'react'

import { cn, mods, states } from '~p/client/components/utils'

import Button from '../atoms/Button'
import Icon from '../atoms/Icon'

import './ProductTitle.css'

function ProductTitle(props) {
  const {
    title,
    name,
    saveButtonLabel,
    orderButtonLabel,
    orderButtonIcon,
    onSave,
    onOrder,
    onPreviewToggle,
    onShare,
    previewState = 'off',
    priceFormatted,
    notes = ['Lead times vary by season. Typical lead time is 4-6 weeks.'],
    isOrderDisabled,
    returnToSiteName,
    returnToSiteLink,
    isEmbed,
  } = props

  const icon = previewState === 'on' ? 'close' : 'zoom'
  const buttonClassMods = previewState === 'on' ? ['hidden'] : []

  const isReturnToSiteVisible = !!(
    returnToSiteName &&
    returnToSiteLink &&
    !isEmbed
  )

  return (
    <div className={cn(['productTitle'])}>
      {isReturnToSiteVisible && (
        <div className="returnToSite">
          <a href={returnToSiteLink}>
            <span className="arrow">❮</span> {returnToSiteName}
          </a>
        </div>
      )}

      <strong className={cn(['productTitle-hero'])}>{title}</strong>
      <span className={cn(['productTitle-productName'])}>{name}</span>
      {priceFormatted && (
        <span className={cn(['productTitle-price'])}>{priceFormatted}</span>
      )}
      <span className={cn(['productTitle-note'])}>
        {notes.map((note) => (
          <div key={note}>{note}</div>
        ))}
      </span>
      <div className={cn(['productTitle-button', mods(buttonClassMods)])}>
        <div className={cn(['productTitle-buttonVariation', mods(['off'])])}>
          <Button classMods={['black']} onClick={onSave} data-gtm="recipe-save">
            {saveButtonLabel}
          </Button>
        </div>
        <div className={cn(['productTitle-buttonVariation', mods(['on'])])}>
          {!isOrderDisabled && (
            <Button
              classMods={['black']}
              onClick={onOrder}
              data-gtm="recipe-order"
            >
              <Icon name={orderButtonIcon} /> {orderButtonLabel}
            </Button>
          )}
          {!window.serverConfig.hideSocial && (
            <Button
              classMods={['black', 'onlyIcon']}
              onClick={onShare}
              data-gtm="mobile-share-toggle"
            >
              <Icon name="share" />
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn(['productTitle-previewToggle', states([previewState])])}
        data-gtm="mobile-fullscreen-toggle"
        onClick={onPreviewToggle}
      >
        <div className={cn(['productTitle-previewToggleInner'])}>
          <Icon name={icon} />
        </div>
      </div>
    </div>
  )
}

export default ProductTitle
