// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SerieRow } from '../typings';

const data = [
  {
    "id": "mizunoPro",
    "name": "Mizuno Pro Baseball",
    "props": {
      "sportId": "baseball",
      "leather": "kip",
      "baseId": "mizunoPro",
      "toneByWeltingSuffix": {
        "split": "mizunoPro-split",
        "roll": "mizunoPro-roll",
        "null": "mizunoPro-split"
      }
    }
  },
  {
    "id": "mizunoProMesh",
    "name": "Mizuno Pro Mesh Back Baseball",
    "props": {
      "sportId": "baseball",
      "leather": "kip",
      "baseId": "mizunoPro",
      "toneByWeltingSuffix": {
        "split": "mizunoPro-split",
        "roll": "mizunoPro-roll"
      }
    }
  },
  {
    "id": "proSelectBaseball",
    "name": "Pro Select Baseball",
    "props": {
      "sportId": "baseball",
      "leather": "steerhide",
      "baseId": "proSelect",
      "toneByWeltingSuffix": {
        "roll": "proSelect-roll",
        "null": "proSelect-roll"
      }
    }
  },
  {
    "id": "proSelectFastpitch",
    "name": "Pro Select Fastpitch",
    "props": {
      "sportId": "softball",
      "leather": "steerhide",
      "baseId": "proSelect",
      "toneByWeltingSuffix": {
        "roll": "proSelect-roll",
        "null": "proSelect-roll"
      }
    }
  },
  {
    "id": "customClassicFastpitch",
    "name": "Custom Classic Fastpitch",
    "props": {
      "sportId": "softball",
      "leather": "throwback",
      "baseId": "customClassic",
      "toneByWeltingSuffix": {
        "roll": "customClassic-roll",
        "null": "customClassic-roll"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SerieRow[]>> = data;

export type Serie = typeof typed[number];

export const SERIE_INDEX_KEY = "id";
export type SerieIndexKey = "id";
export type SerieId = Serie["id"];

let i = 0;
export const SERIE_DICT = {
  "mizunoPro": typed[i++],
  "mizunoProMesh": typed[i++],
  "proSelectBaseball": typed[i++],
  "proSelectFastpitch": typed[i++],
  "customClassicFastpitch": typed[i++]
} as const;

export { typed as SERIES };
