import fp from 'lodash/fp.js'
import { concat } from 'redux-fp'

import renderCompositeUpdater from '~p/client/renderComposite/updater'

import controlTree from './controlTree'
import { defaultViewAngleId } from '../viewAngles'

const initialState = {
  renderComposite: { activeView: defaultViewAngleId },
  closedBlocks: {
    'filters.position': true,
    'filters.size': true,
    ...(window.serverConfig.closedBlocksOverride || {}),
  },
}

const getDefaultState = () => (state) => fp.assign(initialState, state)

export default concat(getDefaultState, controlTree.updater, (action) =>
  fp.update('renderComposite', renderCompositeUpdater(action)),
)
