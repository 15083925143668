import * as _ from '@technically/lodash'
import React from 'react'

import TypeNavigation from '~mizuno/client/components/organisms/TypeNavigation'
import TypeTab from '~mizuno/client/components/atoms/TypeTab'

export default ({ options, value, change }) => (
  <TypeNavigation>
    {_.map(options, (opt) => (
      <TypeTab
        key={opt.id}
        classMods={[]}
        classStates={opt.id === value ? ['active'] : []}
        onClick={() => change(opt.id)}
      >
        {opt.name}
      </TypeTab>
    ))}
  </TypeNavigation>
)
