import React from 'react'

import { maybeSetActive } from '~p/client/components/utils'

import Tile from '../atoms/Tile'
import TileLabel from '../atoms/TileLabel'
import Icon from '../atoms/Icon'

function TextTile(props) {
  const {
    classMods = [],
    classStates = [],
    name = '&nbsp;',
    isActive = false,
  } = props
  return (
    <Tile
      {...props}
      classMods={['text', ...classMods]}
      classStates={maybeSetActive(isActive, classStates)}
    >
      {isActive ?
        <Icon name="select" />
      : null}
      <TileLabel classMods={['center']}>{name}</TileLabel>
    </Tile>
  )
}

export default TextTile
