import { connect } from 'react-redux'
import { setPreviewGenerator } from '~p/client/common/actions'

import RenderComposite from '../components/RenderComposite'
import * as actions from '../actions'

const mapStateToProps = (state, props) => {
  const { isLoading, activeView, preferredSizes } = state.renderComposite
  const { controlTree, renderComposite } = props

  const expandedRecipeNested = controlTree.getExpandedRecipeNested(state)
  const size = preferredSizes[activeView]

  const layers =
    size ?
      renderComposite.getLayers(expandedRecipeNested, size, activeView)
    : null

  return {
    ...props,
    isLoading,
    activeView,
    preferredSizes,
    expandedRecipeNested,
    size,
    layers,
  }
}

const mapDispatchToProps = {
  ...actions,
  setLoading: actions.setRenderCompositeLoading,
  setSize: actions.setRenderCompositePreferredSize,
  setView: actions.setRenderCompositeActiveView,
  setPreviewGenerator,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderComposite)
