import { useSelector } from 'react-redux'

import {
  setContactFormValue,
  orderRecipe,
  inquire,
} from '../../../../platform/client/common/actions'
import { isContactFormValidSelector } from '../../../../platform/client/common/selectors'

import ContentBody from '~mizuno/client/components/atoms/ContentBody'
import ContactForm from '~mizuno/client/components/organisms/ContactForm'
import controlTree from '~c/client/controlTree'
import { useAppDispatch } from '../../../../platform/client/configureStore'

const FormNonNull = (props) => {
  const isValid = useSelector(isContactFormValidSelector)
  const contactForm = useSelector((x) => x.contactForm)
  const dispatch = useAppDispatch()

  return (
    <ContentBody classMods={['block']} classStates="on">
      <ContactForm
        {...contactForm}
        onSubmit={
          props.nonOrder ?
            () => dispatch(inquire())
          : () => dispatch(orderRecipe(controlTree))
        }
        regions={window.serverConfig.regions}
        onNameChange={(value) => dispatch(setContactFormValue('name', value))}
        onPhoneChange={(value) => dispatch(setContactFormValue('phone', value))}
        onEmailChange={(value) => dispatch(setContactFormValue('email', value))}
        onPostalCodeChange={(value) =>
          dispatch(setContactFormValue('postalCode', value))
        }
        onTuringTestChange={(value) =>
          dispatch(setContactFormValue('turingTest', value))
        }
        onRegionChange={(value) =>
          dispatch(setContactFormValue('region', value))
        }
        isValid={isValid}
      />
    </ContentBody>
  )
}

const Form = (props) =>
  !props.nonOrder && window.serverConfig.orderMethod !== 'email' ?
    null
  : <FormNonNull {...props} />

export default Form
