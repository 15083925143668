import React from 'react'

import './ErrorAlert.css'

const ErrorAlert = ({
  isErrorAlertOpen,
  reloadApp,
  resetApp,
  dismissUnhandledErrorAlert,
}) =>
  isErrorAlertOpen && (
    <div id="error-alert">
      <strong>Oops, something went wrong&hellip;</strong>

      <ul>
        <li onClick={() => reloadApp()}>Retry</li>
        <li onClick={() => resetApp()}>Reset</li>
        <li onClick={() => dismissUnhandledErrorAlert()}>Dismiss</li>
      </ul>
    </div>
  )

export default ErrorAlert
