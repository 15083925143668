import { connect } from 'react-redux'

import CopyUrl from '../components/organisms/CopyUrl'
import { copyLinkToClipboard } from '../../../../platform/client/common/actions'

const mapDispatchToProps = {
  copyLinkToClipboard,
}

export default connect(null, mapDispatchToProps)(CopyUrl)
