export default function getRevedImagePath(revManifest, pathParts) {
  const filePath = pathParts.join('/')

  const revision = revManifest[filePath]
  if (revision == null) {
    return undefined
  }

  const [filePathBase, filePathExt] = filePath.split('.')
  return `${filePathBase}.${revision}.${filePathExt}`
}
