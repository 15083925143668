import React from 'react'

import { cn } from '~p/client/components/utils'

import BgContain from '../atoms/BgContain'

function FilePreview({ url }) {
  return (
    <div className={cn(['filePreview'])}>
      <BgContain url={url} />
    </div>
  )
}

export default FilePreview
