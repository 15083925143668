import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './Icon.css'

function Icon({ name, classMods }) {
  return (
    <svg className={cn(['icon', `mod-${name}`, mods(classMods)])}>
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  )
}

export default Icon
