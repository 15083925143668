// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { NavTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "throwingHand",
    "name": "Which is your throwing hand?",
    "propId": "product.throwingHand",
    "id": "product.throwingHand",
    "contentType": "HandDesignSelectControl"
  },
  {
    "parentId": "product",
    "childId": "serie",
    "name": "Series",
    "propId": "filter.serie",
    "id": "product.serie",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "product",
    "childId": "position",
    "name": "Position",
    "propId": "filter.position",
    "id": "product.position",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "product",
    "childId": "size",
    "name": "Size",
    "propId": "filter.size",
    "id": "product.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "product",
    "childId": "pocketDepth",
    "name": "Pocket Depth",
    "propId": "filter.pocketDepth",
    "id": "product.pocketDepth",
    "contentType": "TileSelectControl"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "web",
    "name": "Web",
    "id": "colors.web"
  },
  {
    "parentId": "colors.web",
    "childId": "style",
    "name": "Style",
    "propId": "product.web",
    "id": "colors.web.style",
    "contentType": "WebDesignSelectControl"
  },
  {
    "parentId": "colors.web",
    "childId": "color",
    "name": "Color",
    "propId": "colors.web",
    "id": "colors.web.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "palm",
    "name": "Palm",
    "propId": "colors.palm",
    "id": "colors.palm",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "back",
    "name": "Back",
    "propId": "colors.back",
    "id": "colors.back",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "meshBack",
    "name": "Mesh Back",
    "propId": "colors.meshBack",
    "id": "colors.meshBack",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "pinkyThumbPanels",
    "name": "Pinky/Thumb panels",
    "propId": "colors.pinkyThumbPanels",
    "id": "colors.pinkyThumbPanels",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backPanel1",
    "name": "Back Panel 1",
    "propId": "colors.backPanel1",
    "id": "colors.backPanel1",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backPanel2",
    "name": "Back Panel 2",
    "propId": "colors.backPanel2",
    "id": "colors.backPanel2",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backPanel3",
    "name": "Back Panel 3",
    "propId": "colors.backPanel3",
    "id": "colors.backPanel3",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backPanel4",
    "name": "Back Panel 4",
    "propId": "colors.backPanel4",
    "id": "colors.backPanel4",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backPanel5",
    "name": "Back Panel 5",
    "propId": "colors.backPanel5",
    "id": "colors.backPanel5",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backPanel6",
    "name": "Back Panel 6",
    "propId": "colors.backPanel6",
    "id": "colors.backPanel6",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "backEmboss",
    "name": "Back Emboss",
    "propId": "colors.backEmboss",
    "id": "colors.backEmboss",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "accent",
    "name": "Accent",
    "propId": "colors.accent",
    "id": "colors.accent",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "wrist",
    "name": "Wrist",
    "propId": "colors.wrist",
    "id": "colors.wrist",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "thumbOutside",
    "name": "Thumb Outside",
    "propId": "colors.thumbOutside",
    "id": "colors.thumbOutside",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "thumbLoop",
    "name": "Thumb Loop",
    "propId": "colors.thumbLoop",
    "id": "colors.thumbLoop",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "pinkyOutside",
    "name": "Pinky Outside",
    "propId": "colors.pinkyOutside",
    "id": "colors.pinkyOutside",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "pinkyLoop",
    "name": "Pinky Loop",
    "propId": "colors.pinkyLoop",
    "id": "colors.pinkyLoop",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "welting",
    "name": "Welting",
    "id": "colors.welting"
  },
  {
    "parentId": "colors.welting",
    "childId": "type",
    "name": "Welting Type",
    "propId": "colors.welting.type",
    "id": "colors.welting.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "colors.welting",
    "childId": "color",
    "name": "Welting Color",
    "propId": "colors.welting.color",
    "id": "colors.welting.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "binding",
    "name": "Binding",
    "propId": "colors.binding",
    "id": "colors.binding",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "lace",
    "name": "Lace",
    "id": "colors.lace"
  },
  {
    "parentId": "colors.lace",
    "childId": "color",
    "name": "Lace Color",
    "propId": "colors.lace.color",
    "id": "colors.lace.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors.lace",
    "childId": "length",
    "name": "Lace Length",
    "propId": "colors.lace.length",
    "id": "colors.lace.length",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "stitching-web",
    "name": "Stitching on Web",
    "propId": "colors.stitching.web",
    "id": "colors.stitching-web",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "colors",
    "childId": "stitching-glove",
    "name": "Stitching on Glove",
    "propId": "colors.stitching.glove",
    "id": "colors.stitching-glove",
    "contentType": "ColorSelectControl"
  },
  {
    "childId": "personalize",
    "name": "Personalize",
    "id": "personalize"
  },
  {
    "parentId": "personalize",
    "childId": "patch",
    "name": "Patch Color",
    "propId": "embroidery.patch",
    "id": "personalize.patch",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize",
    "childId": "color",
    "name": "Embroidery Color",
    "propId": "embroidery.color",
    "id": "personalize.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize",
    "childId": "name",
    "name": "Name",
    "id": "personalize.name"
  },
  {
    "parentId": "personalize.name",
    "childId": "text",
    "name": "Text",
    "propId": "embroidery.name.text",
    "id": "personalize.name.text",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "personalize.name",
    "childId": "font",
    "name": "Font",
    "propId": "embroidery.name.font",
    "id": "personalize.name.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "personalize.name",
    "childId": "color",
    "name": "Color",
    "propId": "embroidery.name.color",
    "id": "personalize.name.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize",
    "childId": "number",
    "name": "Number",
    "id": "personalize.number"
  },
  {
    "parentId": "personalize.number",
    "childId": "text",
    "name": "Text",
    "propId": "embroidery.number.text",
    "id": "personalize.number.text",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "personalize.number",
    "childId": "font",
    "name": "Font",
    "propId": "embroidery.number.font",
    "id": "personalize.number.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "personalize.number",
    "childId": "ringColor",
    "name": "Number Ring Color",
    "propId": "embroidery.number.ringColor",
    "id": "personalize.number.ringColor",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize.number",
    "childId": "color",
    "name": "Number Color",
    "propId": "embroidery.number.color",
    "id": "personalize.number.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize",
    "childId": "liner",
    "name": "Liner",
    "id": "personalize.liner"
  },
  {
    "parentId": "personalize.liner",
    "childId": "text",
    "name": "Text",
    "propId": "embroidery.liner.text",
    "id": "personalize.liner.text",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "personalize.liner",
    "childId": "font",
    "name": "Font",
    "propId": "embroidery.liner.font",
    "id": "personalize.liner.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "personalize.liner",
    "childId": "color",
    "name": "Color",
    "propId": "embroidery.liner.color",
    "id": "personalize.liner.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize",
    "childId": "pinky",
    "name": "Pinky",
    "id": "personalize.pinky"
  },
  {
    "parentId": "personalize.pinky",
    "childId": "type",
    "name": "Flag or Ribbon",
    "propId": "embroidery.pinky.type",
    "id": "personalize.pinky.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "personalize.pinky",
    "childId": "flag",
    "name": "Flag",
    "propId": "embroidery.pinky.flag",
    "id": "personalize.pinky.flag",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "personalize.pinky",
    "childId": "ribbon",
    "name": "Ribbon Color",
    "propId": "embroidery.pinky.ribbon",
    "id": "personalize.pinky.ribbon",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "personalize",
    "childId": "logo",
    "name": "Team Logo",
    "id": "personalize.logo"
  },
  {
    "parentId": "personalize.logo",
    "childId": "previewFile",
    "name": "Preview File",
    "propId": "embroidery.logo.previewFile",
    "id": "personalize.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "personalize.logo",
    "childId": "factoryFile",
    "name": "Factory File",
    "propId": "embroidery.logo.factoryFile",
    "id": "personalize.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "personalize",
    "childId": "bag",
    "name": "Glove Bag Personalization",
    "id": "personalize.bag"
  },
  {
    "parentId": "personalize.bag",
    "childId": "text",
    "name": "Text",
    "propId": "embroidery.bag.text",
    "id": "personalize.bag.text",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "personalize.bag",
    "childId": "font",
    "name": "Font",
    "propId": "embroidery.bag.font",
    "id": "personalize.bag.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "personalize.bag",
    "childId": "color",
    "name": "Color",
    "propId": "embroidery.bag.color",
    "id": "personalize.bag.color",
    "contentType": "ColorSelectControl"
  },
  {
    "childId": "options",
    "name": "Options",
    "id": "options"
  },
  {
    "parentId": "options",
    "childId": "fingerPadOrHood",
    "name": "Finger Pad or Hood",
    "id": "options.fingerPadOrHood"
  },
  {
    "parentId": "options.fingerPadOrHood",
    "childId": "type",
    "name": "Type",
    "propId": "options.finger.type",
    "id": "options.fingerPadOrHood.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "options.fingerPadOrHood",
    "childId": "color",
    "name": "Color",
    "propId": "options.finger.color",
    "id": "options.fingerPadOrHood.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "options.fingerPadOrHood",
    "childId": "location",
    "name": "Location",
    "propId": "options.finger.location",
    "id": "options.fingerPadOrHood.location",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "options",
    "childId": "stamp",
    "name": "Stamp",
    "propId": "options.stamp",
    "id": "options.stamp",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "options",
    "childId": "softness",
    "name": "Softness",
    "propId": "options.softness",
    "id": "options.softness",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "options",
    "childId": "palmLinerMaterial",
    "name": "Palm Liner Material",
    "propId": "options.palmLinerMaterial",
    "id": "options.palmLinerMaterial",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "options",
    "childId": "gripThumb",
    "name": "Plus Grip Thumb",
    "propId": "options.gripThumb",
    "id": "options.gripThumb",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "options",
    "childId": "designName",
    "name": "Design Name",
    "propId": "details.recipeName.text",
    "id": "options.designName",
    "contentType": "TextInputControl"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.throwingHand": typed[i++],
  "product.serie": typed[i++],
  "product.position": typed[i++],
  "product.size": typed[i++],
  "product.pocketDepth": typed[i++],
  "colors": typed[i++],
  "colors.web": typed[i++],
  "colors.web.style": typed[i++],
  "colors.web.color": typed[i++],
  "colors.palm": typed[i++],
  "colors.back": typed[i++],
  "colors.meshBack": typed[i++],
  "colors.pinkyThumbPanels": typed[i++],
  "colors.backPanel1": typed[i++],
  "colors.backPanel2": typed[i++],
  "colors.backPanel3": typed[i++],
  "colors.backPanel4": typed[i++],
  "colors.backPanel5": typed[i++],
  "colors.backPanel6": typed[i++],
  "colors.backEmboss": typed[i++],
  "colors.accent": typed[i++],
  "colors.wrist": typed[i++],
  "colors.thumbOutside": typed[i++],
  "colors.thumbLoop": typed[i++],
  "colors.pinkyOutside": typed[i++],
  "colors.pinkyLoop": typed[i++],
  "colors.welting": typed[i++],
  "colors.welting.type": typed[i++],
  "colors.welting.color": typed[i++],
  "colors.binding": typed[i++],
  "colors.lace": typed[i++],
  "colors.lace.color": typed[i++],
  "colors.lace.length": typed[i++],
  "colors.stitching-web": typed[i++],
  "colors.stitching-glove": typed[i++],
  "personalize": typed[i++],
  "personalize.patch": typed[i++],
  "personalize.color": typed[i++],
  "personalize.name": typed[i++],
  "personalize.name.text": typed[i++],
  "personalize.name.font": typed[i++],
  "personalize.name.color": typed[i++],
  "personalize.number": typed[i++],
  "personalize.number.text": typed[i++],
  "personalize.number.font": typed[i++],
  "personalize.number.ringColor": typed[i++],
  "personalize.number.color": typed[i++],
  "personalize.liner": typed[i++],
  "personalize.liner.text": typed[i++],
  "personalize.liner.font": typed[i++],
  "personalize.liner.color": typed[i++],
  "personalize.pinky": typed[i++],
  "personalize.pinky.type": typed[i++],
  "personalize.pinky.flag": typed[i++],
  "personalize.pinky.ribbon": typed[i++],
  "personalize.logo": typed[i++],
  "personalize.logo.previewFile": typed[i++],
  "personalize.logo.factoryFile": typed[i++],
  "personalize.bag": typed[i++],
  "personalize.bag.text": typed[i++],
  "personalize.bag.font": typed[i++],
  "personalize.bag.color": typed[i++],
  "options": typed[i++],
  "options.fingerPadOrHood": typed[i++],
  "options.fingerPadOrHood.type": typed[i++],
  "options.fingerPadOrHood.color": typed[i++],
  "options.fingerPadOrHood.location": typed[i++],
  "options.stamp": typed[i++],
  "options.softness": typed[i++],
  "options.palmLinerMaterial": typed[i++],
  "options.gripThumb": typed[i++],
  "options.designName": typed[i++]
} as const;

export { typed as NAV_TREE };
