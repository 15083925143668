import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import Icon from './Icon'

import './ContentHeadArrow.css'

function ContentHeadArrow(props) {
  const { classMods = ['off'] } = props
  return (
    <div className={cn(['contentHeadArrow', mods(classMods)])}>
      <Icon name="arrowdown" />
    </div>
  )
}

export default ContentHeadArrow
