import React from 'react'
import Dropzone from 'react-dropzone'

import { cn, mods, states } from '~p/client/components/utils'

import Icon from '../atoms/Icon'
import HorizontalAlign from '../atoms/HorizontalAlign'
import Subline from '../atoms/Subline'
import ContentLabel from '../atoms/ContentLabel'
import FilePreview from '../molecules/FilePreview'

import './FileUpload.css'

function renderMessage(message) {
  return (
    <div className={cn(['fileUpload-dropzoneMessage'])}>
      <div
        className={cn(['fileUpload-dropzoneMessage-icon'], mods(['normal']))}
      >
        <Icon name="upload" />
      </div>
      <div className={cn(['fileUpload-dropzoneMessage-text'])}>{message}</div>
    </div>
  )
}

function renderUploadingState() {
  return renderMessage('Uploading...')
}

function renderNormalState() {
  return renderMessage('Drag and Drop Files Here to Upload')
}

export default class FileUpload extends React.Component {
  static defaultProps = {
    value: null,
    isUploading: false,
    error: null,
    isResetAvailable: false,
  }

  constructor(props) {
    super(props)

    this.resetFile = (
      <button
        className={cn(['fileUpload-clear'])}
        onClick={(event) => {
          event.stopPropagation()
          this.props.onClearFile()
        }}
      >
        <Icon name="close" />
      </button>
    )
  }

  onDrop = (files, fileRejections) => {
    if (this.props.isUploading) {
      return
    }

    const [file] = files
    const [fileRejection] = fileRejections

    if (file) this.props.onAcceptFile(file)
    else this.props.onRejectFile(fileRejection.file)
  }

  resetFile

  renderPreview() {
    if (this.props.value && this.props.previewUrl) {
      return (
        <div>
          <FilePreview url={this.props.previewUrl(this.props.value)} />
          {this.props.isResetAvailable ? this.resetFile : ''}
        </div>
      )
    }

    return ''
  }

  renderCommon(dropzone) {
    return (
      <div>
        {dropzone}
        <ContentLabel>
          <strong>{this.props.label}</strong>
        </ContentLabel>
        <HorizontalAlign classMods={['staticRight']}>
          <div className={cn(['fileUpload-silhouetteInput'])}>
            {this.props.value ? this.props.value.filename : ''}
          </div>
          <button className={cn(['fileUpload-silhouetteButton'])}>
            Browse
          </button>
        </HorizontalAlign>
        <Subline>{this.props.subline}</Subline>
      </div>
    )
  }

  renderContent() {
    let dropzone = ''
    if (this.props.previewUrl) {
      let classStates = []
      let dropzoneContent = []

      if (this.props.isUploading) {
        classStates = ['uploading']
        dropzoneContent = renderUploadingState()
      } else if (this.props.value) {
        classStates = ['preview']
      } else {
        dropzoneContent = renderNormalState()
      }
      dropzone = (
        <div className={cn(['fileUpload-dropzone'], states(classStates))}>
          <div
            className={cn(['fileUpload-dropzoneContent'], states(classStates))}
          >
            {this.renderPreview()}
            {dropzoneContent}
          </div>
        </div>
      )
    }

    return this.renderCommon(dropzone)
  }

  render() {
    return (
      <div className={cn(['fileUpload-container'])}>
        <Dropzone
          className={cn(['fileUpload'])}
          activeClassName={cn(states(['active']))}
          rejectClassName={cn(states(['rejected']))}
          multiple={false}
          onDrop={this.onDrop}
          disablePreview
          accept={this.props.accept}
          minSize={this.props.minSize}
          maxSize={this.props.maxSize}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ onClick: (evt) => evt.preventDefault() })}>
              <input {...getInputProps()} />
              {this.renderContent()}
            </div>
          )}
        </Dropzone>
        {this.props.error}
      </div>
    )
  }
}
