import { some } from '@technically/lodash'

import { GLOVES, VENDOR_DICT } from './sheets'

const getTitleBase = (config) => {
  const vendor = VENDOR_DICT[config.hostname]
  const sport = vendor.sportId === 'baseball' ? 'Baseball' : 'Fastpitch'
  return `${config.titleBase} ${sport}`
}

const getIndexTitle = (config) =>
  `${getTitleBase(config)} ${config.titlePostfix}` +
  `${config.hideVia ? '' : ` via ${config.vendorName}`}`

const getIndexDescription = () =>
  'Build your own baseball glove in a photorealistic customizer.'

const getSkuDescription = () =>
  'Build your own baseball glove based on this template.'

const getRecipeDescription = () =>
  'Order this baseball glove or build your own based on it.'

const getSkuValues = (sku) => {
  const upperSku = sku.toUpperCase()
  let result
  some(GLOVES, (glove) => {
    const canonicalSku = glove.name
    if (upperSku === canonicalSku) {
      result = {
        glove,
        canonicalSku,
      }
      return true
    }
    return false
  })
  if (!result) {
    throw new Error(`SKU is not valid: "${sku}"`)
  }

  return result
}

const getSkuTitle = (config, params) => {
  const sku = params[0]
  const { canonicalSku } = getSkuValues(sku)

  return (
    `${canonicalSku} - ${getTitleBase(config)} Glove ` +
    `Template${config.hideVia ? '' : ` via ${config.vendorName}`}`
  )
}

const getRecipeTitle = (config, _recipe, id, hasChanges) =>
  `${id}${hasChanges ? ' with changes' : ''} - ` +
  `${getTitleBase(config)} Glove` +
  `${config.hideVia ? '' : ` via ${config.vendorName}`}`

const getStaticTags = () => `
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  <link rel="manifest" href="/site.webmanifest">
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#001489">
  <meta name="msapplication-TileColor" content="#001489">
  <meta name="theme-color" content="#151923">
`

const garmentSizes = []

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
  garmentSizes,
}
