import { map } from '@technically/lodash'
import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import Icon from '../atoms/Icon'
import Subline from '../atoms/Subline'

import './SelectInput.css'

// TODO: make it work with ungrouped options
function SelectInput(props) {
  const {
    classMods = [],
    optionGroups,
    options,
    name,
    autoComplete,
    value = '',
    subline = '',
    change,
  } = props

  return (
    <div className={cn(['selectInput', mods(classMods)])}>
      <select
        className={cn(['selectInput-input'])}
        onChange={(ev) => {
          change(ev.target.value)
        }}
        name={name}
        autoComplete={autoComplete}
        value={value || ''}
      >
        <option value="" key="">
          &nbsp;
        </option>
        {options &&
          map(options, (option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        {optionGroups &&
          map(optionGroups, (groupOptions, label) => (
            <optgroup key={label} label={label}>
              {map(groupOptions, (option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
      <Icon name="arrowdown" />
      <Subline>{subline}</Subline>
    </div>
  )
}

export default SelectInput
