import fp from 'lodash/fp.js'
import { concat, handleActions, combine } from 'redux-fp'

const isLoading = concat(
  () =>
    (state = true) =>
      state,
  handleActions({
    SET_RENDER_COMPOSITE_LOADING: (action) => () => action.payload,
  }),
)

const preferredSizes = concat(
  () =>
    (state = {}) =>
      state,
  handleActions({
    SET_RENDER_COMPOSITE_PREFERRED_SIZE: ({ payload }) =>
      fp.set(payload.activeView, payload.preferredSize),
  }),
)

const activeView = concat(
  () =>
    (state = null) =>
      state,
  handleActions({
    SET_RENDER_COMPOSITE_ACTIVE_VIEW: (action) => () => action.payload,
  }),
)

export default combine({ isLoading, preferredSizes, activeView })
