import { map, isEmpty } from '@technically/lodash'
import React from 'react'

import getSummary from '~p/client/getSummary'

import FinalizeSection from './organisms/FinalizeSection'
import FinalizeBlock from './organisms/FinalizeBlock'
import FinalizeRow from './atoms/FinalizeRow'

const Summary = ({ nodes }) =>
  map(getSummary(nodes), (level0) => (
    <FinalizeSection key={level0.id} primaryLabel={level0.label}>
      {map(level0.details, (level1) => {
        if (isEmpty(level1.details)) {
          return (
            <FinalizeBlock key={level1.id}>
              <FinalizeRow
                label={level1.label}
                value={level1.value}
                id={level1.id}
              />
            </FinalizeBlock>
          )
        }
        return (
          <FinalizeBlock key={level1.id} primaryLabel={level1.label}>
            {map(level1.details, (level2) => (
              <FinalizeRow
                key={level2.id}
                label={level2.label}
                value={level2.value}
                id={level2.id}
              />
            ))}
          </FinalizeBlock>
        )
      })}
    </FinalizeSection>
  ))

export default Summary
