import React from 'react'

import { maybeSetActive } from '~p/client/components/utils'

import BgSvgCover from './BgSvgCover'
import Tile from '../atoms/Tile'
import BgCover from '../atoms/BgCover'
import TileLabel from '../atoms/TileLabel'
import Icon from '../atoms/Icon'

function ImageTile(props) {
  const {
    classMods = [],
    classStates = [],
    isActive = false,
    name = '&nbsp;',
    url,
    svg,
    svgType,
  } = props

  return (
    <Tile
      {...props}
      classMods={['image', ...classMods]}
      classStates={maybeSetActive(isActive, classStates)}
    >
      {isActive ?
        <Icon name="select" />
      : null}
      {svg ?
        <BgSvgCover svg={svg} svgType={svgType} />
      : <BgCover url={url} />}
      <TileLabel>{name}</TileLabel>
    </Tile>
  )
}

export default ImageTile
