// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorRow } from '../typings';

const data = [
  {
    "id": "teal",
    "name": "Teal",
    "props": {
      "hex": "#008080"
    }
  },
  {
    "id": "royal",
    "name": "Royal",
    "props": {
      "hex": "#1d54ac"
    }
  },
  {
    "id": "forest",
    "name": "Forest",
    "props": {
      "hex": "#245f56"
    }
  },
  {
    "id": "black",
    "name": "Black",
    "props": {
      "hex": "#313133"
    }
  },
  {
    "id": "navy",
    "name": "Navy",
    "props": {
      "hex": "#323e63",
      "alt": {
        "proSelectWeltingBinding": "#001B3A",
        "proSelectWeb": "#001B3A",
        "proSelectBase": "#001B3A"
      }
    }
  },
  {
    "id": "kellyGreen",
    "name": "Kelly Green",
    "props": {
      "hex": "#359967"
    }
  },
  {
    "id": "plumBrown",
    "name": "Plum Brown",
    "props": {
      "hex": "#3c1414"
    }
  },
  {
    "id": "roseBrownCardinal",
    "name": "Rose Brown/Cardinal",
    "props": {
      "hex": "#3c1414"
    }
  },
  {
    "id": "roseBrown",
    "name": "Rose Brown",
    "props": {
      "hex": "#420d09",
      "alt": {
        "mizunoProRollWelting": "#652121",
        "mizunoProStandard": "#652121"
      }
    }
  },
  {
    "id": "blackCherry",
    "name": "Black Cherry",
    "props": {
      "hex": "#421e2e"
    }
  },
  {
    "id": "throwback",
    "name": "Throwback",
    "props": {
      "hex": "#48332a"
    }
  },
  {
    "id": "purple",
    "name": "Purple",
    "props": {
      "hex": "#4a2d5b",
      "alt": {
        "mizunoProRollWelting": "#360ccc",
        "mizunoProSplitWelting": "#360ccc",
        "mizunoProStandard": "#360ccc",
        "mizunoProBinding": "#360ccc"
      }
    }
  },
  {
    "id": "deepRoyal",
    "name": "Deep Royal",
    "props": {
      "hex": "#360ccc",
      "alt": {
        "mizunoProStandard": "#360ccc"
      }
    }
  },
  {
    "id": "corkBrown",
    "name": "Cork Brown",
    "props": {
      "hex": "#5a3021"
    }
  },
  {
    "id": "smokeGray",
    "name": "Smoke Gray",
    "props": {
      "hex": "#5e595d",
      "alt": {
        "mizunoProRollWelting": "#bfbab5",
        "mizunoProLace": "#9c948c",
        "mizunoProStandard": "#9c948c"
      }
    }
  },
  {
    "id": "cardinal",
    "name": "Cardinal",
    "props": {
      "hex": "#5f3236",
      "alt": {
        "mizunoProRollWelting": "#652721 ",
        "mizunoProStandard": "#652721 "
      }
    }
  },
  {
    "id": "brown",
    "name": "Brown",
    "props": {
      "hex": "#61423a"
    }
  },
  {
    "id": "coffee",
    "name": "Coffee",
    "props": {
      "hex": "#664b3e"
    }
  },
  {
    "id": "gray",
    "name": "Gray",
    "props": {
      "hex": "#867678"
    }
  },
  {
    "id": "midBrown",
    "name": "Mid Brown",
    "props": {
      "hex": "#8b4513"
    }
  },
  {
    "id": "lightBlue",
    "name": "Light Blue",
    "props": {
      "hex": "#41B6E6"
    }
  },
  {
    "id": "columbiaBlue",
    "name": "Columbia Blue",
    "props": {
      "hex": "#90c6f1"
    }
  },
  {
    "id": "chestnut",
    "name": "Chestnut",
    "props": {
      "hex": "#954535"
    }
  },
  {
    "id": "red",
    "name": "Red",
    "props": {
      "hex": "#9c1e1d"
    }
  },
  {
    "id": "lime",
    "name": "Lime",
    "props": {
      "hex": "#E3FF00"
    }
  },
  {
    "id": "sOrange",
    "name": "S. Orange",
    "props": {
      "hex": "#871a1a",
      "alt": {
        "mizunoProRollWelting": "#d64d1c",
        "mizunoProSplitWelting": "#e62b00",
        "mizunoProStandard": "#d64d1c"
      }
    }
  },
  {
    "id": "metallicRoseGold",
    "name": "Metallic Rose Gold",
    "props": {
      "hex": "#b7886e"
    }
  },
  {
    "id": "roseGold",
    "name": "Rose Gold",
    "props": {
      "hex": "#eabfb9"
    }
  },
  {
    "id": "silverWhite",
    "name": "Silver/White",
    "props": {
      "hex": "#d7cdbb"
    }
  },
  {
    "id": "silver",
    "name": "Silver",
    "props": {
      "hex": "#C0C0C0"
    }
  },
  {
    "id": "metallicSilver",
    "name": "Metallic Silver",
    "props": {
      "hex": "#c4cace"
    }
  },
  {
    "id": "burntOrange",
    "name": "Burnt Orange",
    "props": {
      "hex": "#cc5500"
    }
  },
  {
    "id": "cork",
    "name": "Cork",
    "props": {
      "hex": "#ce9a25"
    }
  },
  {
    "id": "corkNatural",
    "name": "Cork Natural",
    "props": {
      "hex": "#ce9a26",
      "alt": {
        "mizunoProRollWelting": "#F0AB00",
        "mizunoProLace": "#F0AB00",
        "mizunoProStandard": "#F0AB00",
        "mizunoProMeshBack": "#ff9933"
      }
    }
  },
  {
    "id": "metallicGold",
    "name": "Metallic Gold",
    "props": {
      "hex": "#d4af37",
      "alt": {
        "proSelectWeltingBinding": "#EEE8AA"
      },
      "texture": "/metallicColors/metallicGold.jpg"
    }
  },
  {
    "id": "orange",
    "name": "Orange",
    "props": {
      "hex": "#d64d1c",
      "alt": {
        "mizunoProRollWelting": "#ff5f00 ",
        "mizunoProLace": "#ff5f00 ",
        "mizunoProStandard": "#ff5f00 "
      }
    }
  },
  {
    "id": "cream",
    "name": "Cream",
    "props": {
      "hex": "#d7cdbb",
      "alt": {
        "proSelectWeb": "#ffffe4",
        "proSelectBase": "#ffffe4"
      }
    }
  },
  {
    "id": "vegasGold",
    "name": "Vegas Gold",
    "props": {
      "hex": "#9b8a36"
    }
  },
  {
    "id": "almond",
    "name": "Almond",
    "props": {
      "hex": "#f0e68c"
    }
  },
  {
    "id": "gold",
    "name": "Gold",
    "props": {
      "hex": "#f5d176",
      "alt": {
        "mizunoProMeshBack": "#d4af37"
      }
    }
  },
  {
    "id": "white",
    "name": "White",
    "props": {
      "hex": "#f8f8f8"
    }
  },
  {
    "id": "mizunoProTan",
    "name": "Mizuno Pro Tan",
    "props": {
      "hex": "#faf0be"
    }
  },
  {
    "id": "tan",
    "name": "Tan",
    "props": {
      "hex": "#faf0be",
      "alt": {
        "proSelectWeltingBinding": "#E5CB90",
        "proSelectWeb": "#E5CB90",
        "proSelectBase": "#E5CB90"
      }
    }
  },
  {
    "id": "pink",
    "name": "Pink",
    "props": {
      "hex": "#fdcff1",
      "alt": {
        "mizunoProRollWelting": "#ff9f9f",
        "mizunoProLace": "#ff9f9f"
      }
    }
  },
  {
    "id": "yellow",
    "name": "Yellow",
    "props": {
      "hex": "#fef829",
      "alt": {
        "mizunoProRollWelting": "#fedd00",
        "mizunoProLace": "#fedd00",
        "mizunoProStandard": "#fedd00"
      }
    }
  },
  {
    "id": "athleticGold",
    "name": "Athletic Gold",
    "props": {
      "hex": "#ffda49"
    }
  },
  {
    "id": "naturalGold",
    "name": "Natural Gold",
    "props": {
      "hex": "#ce9a26",
      "alt": {
        "proSelectWeltingBinding": "#ffa500",
        "proSelectWeb": "#ffa500",
        "proSelectBase": "#ffa500"
      }
    }
  },
  {
    "id": "charcoalGray",
    "name": "Charcoal Gray",
    "props": {
      "hex": "#5e595d"
    }
  },
  {
    "id": "maroon",
    "name": "Maroon",
    "props": {
      "hex": "#8C1D40",
      "alt": {
        "proSelectWeltingBinding": "#3c1414",
        "proSelectWeb": "#3c1414",
        "proSelectBase": "#3c1414"
      }
    }
  },
  {
    "id": "metallicRed",
    "name": "Metallic Red",
    "props": {
      "hex": "#ff1919",
      "texture": "/metallicColors/metallicRed.jpg"
    }
  },
  {
    "id": "metallicBlue",
    "name": "Metallic Blue",
    "props": {
      "hex": "#1d44b8",
      "texture": "/metallicColors/metallicBlue.jpg"
    }
  },
  {
    "id": "indianTan",
    "name": "Indian Tan",
    "props": {
      "hex": "#8B4513 "
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorRow[]>> = data;

export type Color = typeof typed[number];

export const COLOR_INDEX_KEY = "id";
export type ColorIndexKey = "id";
export type ColorId = Color["id"];

let i = 0;
export const COLOR_DICT = {
  "teal": typed[i++],
  "royal": typed[i++],
  "forest": typed[i++],
  "black": typed[i++],
  "navy": typed[i++],
  "kellyGreen": typed[i++],
  "plumBrown": typed[i++],
  "roseBrownCardinal": typed[i++],
  "roseBrown": typed[i++],
  "blackCherry": typed[i++],
  "throwback": typed[i++],
  "purple": typed[i++],
  "deepRoyal": typed[i++],
  "corkBrown": typed[i++],
  "smokeGray": typed[i++],
  "cardinal": typed[i++],
  "brown": typed[i++],
  "coffee": typed[i++],
  "gray": typed[i++],
  "midBrown": typed[i++],
  "lightBlue": typed[i++],
  "columbiaBlue": typed[i++],
  "chestnut": typed[i++],
  "red": typed[i++],
  "lime": typed[i++],
  "sOrange": typed[i++],
  "metallicRoseGold": typed[i++],
  "roseGold": typed[i++],
  "silverWhite": typed[i++],
  "silver": typed[i++],
  "metallicSilver": typed[i++],
  "burntOrange": typed[i++],
  "cork": typed[i++],
  "corkNatural": typed[i++],
  "metallicGold": typed[i++],
  "orange": typed[i++],
  "cream": typed[i++],
  "vegasGold": typed[i++],
  "almond": typed[i++],
  "gold": typed[i++],
  "white": typed[i++],
  "mizunoProTan": typed[i++],
  "tan": typed[i++],
  "pink": typed[i++],
  "yellow": typed[i++],
  "athleticGold": typed[i++],
  "naturalGold": typed[i++],
  "charcoalGray": typed[i++],
  "maroon": typed[i++],
  "metallicRed": typed[i++],
  "metallicBlue": typed[i++],
  "indianTan": typed[i++]
} as const;

export { typed as COLORS };
