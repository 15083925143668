import fp from 'lodash/fp.js'
import { createSelector } from 'reselect'

import {
  isSkuSelector,
  layoutModeSelector,
  pathSelector,
  querySelector,
  withChangesSelector,
} from '~p/client/common/selectors'

import controlTree from '~c/client/controlTree'
import { navTreeArray } from '~c/common/sheets'

// By default, `map` doesn't pass `i` to its iteratee
const mapWithIndex = fp.map.convert({ cap: false })

const listRaw = navTreeArray
const listWithId = fp.map((x) => ({
  ...x,
  id: x.parentId ? `${x.parentId}.${x.childId}` : x.childId,
}))(listRaw)

// Why is this tree-like structure being handled as a list everywhere? Because
// lists are much easier to transform/manipulate in an immutable manner.

// As to not pollute with global names
{
  const allIds = fp.map((x) => x.id, listWithId)
  const parentIds = fp.map((x) => x.parentId, listWithId)
  if (!fp.every((x) => x == null || allIds.includes(x), parentIds)) {
    throw new Error('Encountered a non-existent parentId in navTree sheet')
  }

  // Could also check for loops, but the app will hang below anyway, during the
  // normalization :D
}

// As to not pollute with global names
export const listStatic = (() => {
  const normalize = (parentId) =>
    fp.pipe(
      fp.filter((x) =>
        parentId ? x.parentId === parentId : x.parentId == null,
      ),
      fp.reduce((r, x) => [...r, x, ...normalize(x.id)], []),
    )(listWithId)

  const listNormalized = normalize(null)

  // `listNormalized` has the same shape and items as `listWithId`, but is
  // sorted hierarchically, i.e. in the same order as the items would appear in
  // a flattened-out menu.

  const getFullName = (x) => {
    const parentId = x.parentId
    if (parentId == null) {
      return x.name
    }
    const parentLevel = parentId.split('.').length
    if (parentLevel <= 1) {
      return x.name
    }
    const parent = fp.find({ id: parentId }, listNormalized)
    return `${getFullName(parent)} ${x.name}`
  }

  const listWithBasics = mapWithIndex(
    (x, index) => ({
      ...x,
      fullName: getFullName(x),
      index, // Needed in filtered lists
    }),
    listNormalized,
  )

  // Yes, slightly inefficient, but simple
  const getAncestorIndices = (item) => {
    if (item.parentId == null) return []
    const parent = fp.find((x) => x.id === item.parentId, listWithBasics)
    return [...getAncestorIndices(parent), parent.index]
  }

  return fp.map(
    (item) => ({
      ...item,
      ancestorIndices: getAncestorIndices(item),
      childIndices: fp.pipe(
        fp.filter((x) => x.parentId === item.id),
        fp.map((x) => x.index),
      )(listWithBasics),
    }),
    listWithBasics,
  )
})()

export const navListRootIndices = fp.pipe(
  fp.filter((x) => x.parentId == null),
  fp.map((x) => x.index),
)(listStatic)

const getItemNested = (itemIndex) => {
  const item = listStatic[itemIndex]
  const children = fp.map(getItemNested, item.childIndices)
  return {
    item,
    children,
  }
}
export const navListNested = fp.map(getItemNested, navListRootIndices)

// `controlTree.getNodes` is bound and memoized internally. `layoutMode` doesn't
// change often, but it can, so gotta watch it.
export const navListSelector = createSelector(
  controlTree.getNodes,
  layoutModeSelector,
  (nodes, lm) => {
    const addMeta = (r, itemIndex) => {
      const item = listStatic[itemIndex]
      const descendants = fp.reduce(addMeta, [], item.childIndices)
      const node = nodes[item.propId]

      if (node && node.navLabel) {
        item.name = node.navLabel
      }

      // If `propId` is null, then it's available by definition
      const isNodeAvailable = !node || node.isAvailable

      // If `layoutMode` is falsy, then anything goes
      const matchesLayoutMode = !item.layoutMode || item.layoutMode === lm

      // Leaf link, like `summary_purchase`
      const isCosmeticLeaf = !node && !item.childIndices.length

      const hasAvailableChildren = fp.pipe(
        fp.filter((x) => item.childIndices.includes(x.index)),
        fp.some((x) => x.isAvailable),
      )(descendants)

      const isSelectNode = node && node.nodeKind === 'SelectNode'
      const options = node ? node.visibleOptions || node.options : []
      const hasChoice =
        node &&
        options &&
        (options.length > 1 ||
          (options.length === 1 && !node.isRequired) ||
          !!node.isAlwaysVisible)

      // Any node other than `SelectNode` gets a free pass. But if it is a
      // `SelectNode`, then it must have at least two options, otherwise there's
      // no point in showing it.
      const isActionableLeaf = node && (!isSelectNode || hasChoice)

      const isAvailable =
        isNodeAvailable &&
        matchesLayoutMode &&
        (isCosmeticLeaf || hasAvailableChildren || isActionableLeaf)

      // This ordering is in-sync with the normalization step. All optional
      // booleans are included because, well, why not.
      const itemWithMeta = {
        ...item,
        node,
        isCosmeticLeaf,
        hasAvailableChildren,
        isActionableLeaf,
        isAvailable,
        shouldOpenFirstChild:
          (!item.propId && !item.shouldShowChildren) ||
          // If this is a `SelectNode` without a choice, and it has available
          // children, then might as well save the user fp.some time and redirect
          // them to something actionable.
          (hasAvailableChildren && isSelectNode && !hasChoice),
      }
      return [...r, itemWithMeta, ...descendants]
    }

    // Why not just iterate one-by-one? Because this is the most efficient way
    // to calculate availability which is based on descendant availability.
    const listWithMeta = fp.reduce(addMeta, [], navListRootIndices)

    const getNextId = (afterThisIndex) => {
      for (let j = afterThisIndex + 1; j < listWithMeta.length; j += 1) {
        const x = listWithMeta[j]
        if (x.isAvailable && !x.shouldOpenFirstChild) return x.id
      }

      return undefined
    }

    let prevId
    const addLinks = (x) => {
      if (!x.isAvailable) return x
      const nextId = getNextId(x.index)
      if (x.shouldOpenFirstChild) return { ...x, effectiveId: nextId }
      const newX = { ...x, prevId, effectiveId: x.id, nextId }
      prevId = x.id // I know, side effects, but what else? `reduce`?
      return newX
    }

    return fp.map(addLinks, listWithMeta)
  },
)

export const selectableNavListSelector = createSelector(
  navListSelector,
  fp.filter((x) => x.isAvailable && !x.shouldOpenFirstChild),
)

export const firstSelectableNavItemSelector = createSelector(
  selectableNavListSelector,
  fp.first,
)

export const firstWizardNavItemSelector = createSelector(
  selectableNavListSelector,
  fp.find((x) => x.isWizardStep),
)

export const lastWizardNavItemSelector = createSelector(
  selectableNavListSelector,
  fp.findLast((x) => x.isWizardStep),
)

export const firstRegularNavItemSelector = createSelector(
  selectableNavListSelector,
  fp.find((x) => x.node && !x.isWizardStep),
)

// Yes, unavailable and fall-through items are invalid
export const queryNavItemSelector = createSelector(
  selectableNavListSelector,
  querySelector,
  (list, query) => fp.find((x) => x.id === query.menu, list),
)

export const currentNavItemSelector = createSelector(
  queryNavItemSelector,
  pathSelector,
  firstWizardNavItemSelector,
  isSkuSelector,
  withChangesSelector,
  firstRegularNavItemSelector,
  firstSelectableNavItemSelector,
  (
    queryItem,
    path,
    firstWizardItem,
    isSku,
    withChanges,
    firstRegularItem,
    firstSelectableItem,
  ) => {
    if (queryItem) return queryItem
    if (path === '/') return firstWizardItem
    if (isSku || withChanges) return firstRegularItem || firstSelectableItem
    return firstSelectableItem
  },
)

export const prevNavItemSelector = createSelector(
  selectableNavListSelector,
  currentNavItemSelector,
  (items, currentItem) => fp.find({ id: currentItem.prevId }, items),
)

export const isNodeDrawable = (node) => {
  if (!node.isAvailable) {
    return false
  }
  const options = node.visibleOptions || node.options
  if (options && options.length <= 1 && node.isRequired) {
    return false
  }
  return true
}

export const hasDrawableChildren = (item, children, nodes) => {
  if (
    (item.propId && isNodeDrawable(nodes[item.propId])) ||
    item.contentType === 'RosterControl'
  ) {
    return true
  }
  return fp.some(({ item }) => {
    const node = nodes[item.propId]
    return isNodeDrawable(node)
  }, children)
}

export const firstDrawableChild = (children, nodes) =>
  fp.find(({ item }) => {
    const node = nodes[item.propId]
    return isNodeDrawable(node)
  }, children)
