import React from 'react'

import getAsset from '~p/getAsset'

import { cn, mods, states } from '~p/client/components/utils'

import './TypeTab.css'

const TypeTab = (props) => {
  const { classMods, classStates, children, onClick } = props

  let bg
  if (classMods) {
    if (classMods.includes('patterns')) bg = 'camouflage/hunter.png'
    if (classMods.includes('custom')) bg = 'camouflage/spade.png'
    // not an elif by design, as to mimic CSS behavior
  }

  return (
    <button
      className={cn(['typeTab', mods(classMods), states(classStates)])}
      style={{ backgroundImage: bg && `url(${getAsset(bg)})` }}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default TypeTab
