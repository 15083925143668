// This file must remain CommonJS for now, as Jest blows up on dynamic top-level
// ESM imports when transforming via Babel, unfortunately. Not using ~c here
// because some scripts run via tsx, without ever calling Babel, and there's no
// clean way to tell TypeScript to resolve dynamic aliases like that.

const upstream = require(`../customizers/${process.env.CUSTOMIZER}/exports`)

module.exports = {
  DEFAULT_MENU: upstream.DEFAULT_MENU ?? 'empty',
  DEFAULT_MENU_WHEN_SKU: upstream.DEFAULT_MENU_WHEN_SKU ?? null,
  SKU_MATCHES: upstream.SKU_MATCHES ?? [],

  // https://github.com/webpack/webpack/issues/14814
  SKU_OMITS: upstream['SKU_OMITS'.toString()],
  I18N_RESOURCES: upstream['I18N_RESOURCES'.toString()] ?? {},
}
