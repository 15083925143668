import { stringify } from 'qs'

import getRevedImagePath from './data/getRevedImagePath'

const muteAssetWarnings = process.env.MUTE_ASSET_WARNINGS === '1'

const getFilename = (layer) =>
  layer.filename || layer.name + (layer.nameSuffix || '')

const getToneLayerSrc = (layer, getImageSrc) => {
  const shadowSrc = getImageSrc(`${getFilename(layer)}_shadow`)
  const highlightSrc = getImageSrc(`${getFilename(layer)}_highlight`)

  if (!shadowSrc || !highlightSrc) {
    return null
  }
  return {
    shadow: shadowSrc,
    highlight: highlightSrc,
  }
}

const getMeshLayerSrc = (layer, size, Id, viewId) => {
  const recipeData = {
    ...layer.recipeData,
    size,
    meshId: `${Id}/${viewId}/${layer.recipeData.meshId}`,
  }

  const q = stringify(recipeData, {
    allowDots: true,
    encodeValuesOnly: true,
  })

  return `/api/renderMesh3d?${q}`
}

export const getLayerWithSrc =
  (getCustomizerAssetSrc, asset, viewId, size, revManifests) => (layer) => {
    const getImageSrc = (filename) => {
      if (!filename) {
        return null
      }
      const bundleName = layer.bundleName || 'photoshop-layers'

      // Adding / is a convention to make the path absolute meaning it won't prefix assetId and viewId.
      const isAbsolute = filename[0] === '/'
      // Default is .png, but you can explicitly specify custom extension.
      const hasExtension = /\.\w+$/.test(filename)

      const pathParts =
        isAbsolute ?
          [`${filename.slice(1)}${hasExtension ? '' : '.png'}`]
        : [asset.id, viewId, `${filename}${hasExtension ? '' : '.png'}`]

      const manifest = revManifests[bundleName]

      if (!manifest) {
        console.warn('No manifest found for', bundleName)
        return null
      }

      const filePath = getRevedImagePath(manifest, pathParts)

      if (filePath == null) {
        if (!muteAssetWarnings) {
          console.warn(
            'No revision found',
            '\nbundle:',
            bundleName,
            '\nfile:',
            pathParts.join('/'),
          )
        }
        return null
      }

      return getCustomizerAssetSrc(bundleName, filePath, size)
    }

    let src = null

    if (layer.type === 'mesh3d') {
      if (layer.bundleName) {
        // // eslint-disable-next-line no-param-reassign
        // layer.bundleName = '3d-render'
        src = getImageSrc(layer.filename)
      } else {
        src = getMeshLayerSrc(layer, size, asset.id, viewId)
      }
    } else if (layer.blend === 'tone') {
      src = getToneLayerSrc(layer, getImageSrc)
    } else {
      src = getImageSrc(getFilename(layer))
    }

    if (layer.mask && typeof src === 'string') {
      const mask = getImageSrc(layer.mask)
      if (mask) {
        src = {
          content: src,
          mask,
        }
      }
    }

    if (src == null) {
      if (!muteAssetWarnings) {
        console.error('Failed to generate src layer:', asset.id, viewId, layer)
      }
      return null
    }

    return {
      src,
      color: layer.color ? layer.color.props.hex : undefined,
      blend: layer.blend,
      invertMask: layer.invertMask,
      hue: layer.hue,
      brightness: layer.brightness,
    }
  }
