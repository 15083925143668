import React from 'react'

import FoldableBlock from '~mizuno/client/components/organisms/FoldableBlock'
import ErrorBoundary from '~p/client/components/ErrorBoundary'

const ToggleBlock = (props) => {
  const { primaryLabel, secondaryLabel, children, isOpen, toggleBlock } = props

  return (
    <FoldableBlock
      state={isOpen ? 'on' : 'off'}
      primaryLabel={primaryLabel}
      secondaryLabel={secondaryLabel}
      onClick={() => toggleBlock()}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </FoldableBlock>
  )
}

export default ToggleBlock
