import fp from 'lodash/fp.js'
import { useMemo, useState } from 'react'
import { connect } from 'react-redux'

import { debounce } from '@orangelv/utils'

import { orderRecipe, toggleContactForm } from '../common/actions'

const DEBOUNCE_WAIT = 300

const debounceChange = (Component) => (props) => {
  const [pendingValue, setPendingValue] = useState()

  const { change } = props
  const debouncedChange = useMemo(
    () =>
      debounce((x) => {
        change(x)
        setPendingValue(undefined)
      }, DEBOUNCE_WAIT),
    [change, setPendingValue],
  )

  return (
    <Component
      {...props}
      value={pendingValue ?? props.value ?? ''}
      change={(x) => {
        setPendingValue(x)
        debouncedChange(x)
      }}
    />
  )
}

const limitChangeMaxLength = (Component) => (props) => {
  const change = (value) => {
    if (value && props.maxLength && value.length > props.maxLength) {
      return
    }

    props.change(value)
  }

  return <Component {...props} change={change} />
}

const validatePattern = (Component) => (props) => {
  const change = (value) => {
    if (props.pattern && value) {
      const isValid = new RegExp(props.pattern).test(value)
      if (!isValid) {
        return
      }
    }

    props.change(value)
  }

  return <Component {...props} change={change} />
}

const hideWhenSingleOption = (Component) => (props) => {
  const { resolvedNode } = props
  const options = props.options || resolvedNode.options
  const hasOptions = !!options

  const isVisible =
    resolvedNode && hasOptions ?
      !resolvedNode.isRequired || fp.size(options) > 1
    : true

  return isVisible ? <Component {...props} /> : null
}

const noneOption = {
  id: null,
  name: 'None',
  classMods: ['none'],
}

const addNoneToOptions = (Component) => (props) => {
  const { resolvedNode, appendNone, hideNone } = props

  const originalOptions = fp.toArray(props.options || resolvedNode.options)

  let options = originalOptions
  if (!resolvedNode.isRequired && !resolvedNode.multiple && !hideNone) {
    options =
      appendNone ?
        [].concat(originalOptions, [noneOption])
      : [].concat([noneOption], originalOptions)
  }

  return <Component {...props} options={options} />
}

const respectVisibleOptions = (Component) => (props) => {
  const { resolvedNode } = props

  const legalOptions = props.options || resolvedNode.options
  const visibleOptions = resolvedNode.visibleOptions

  const legalOptionIds = fp.map('id', legalOptions)

  const options =
    visibleOptions ?
      fp.map(
        (visibleOpt) => ({
          ...visibleOpt,
          isDisabled: !fp.includes(visibleOpt.id, legalOptionIds),
        }),
        visibleOptions,
      )
    : legalOptions

  return <Component {...props} options={options} />
}

const connectOrders = (controlTree, Component) => {
  const isEmail = window.serverConfig.orderMethod === 'email'
  return connect(
    () => ({
      orderButtonLabel: isEmail ? 'Get Quote' : 'Add to Cart',
      orderButtonIcon: isEmail ? 'email' : 'cart',
      orderMethodGTM: window.serverConfig.orderMethod,
    }),
    (dispatch) => ({
      onOrder: () => {
        if (isEmail) {
          window.scrollTo(0, 0)
          dispatch(toggleContactForm())
        } else {
          dispatch(orderRecipe(controlTree))
        }
      },
    }),
  )(Component)
}

export {
  debounceChange,
  limitChangeMaxLength,
  validatePattern,
  hideWhenSingleOption,
  addNoneToOptions,
  respectVisibleOptions,
  connectOrders,
}
export { default as uploadsEnhancer } from './uploadsEnhancer'
