import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import UrlPattern from 'url-pattern'

class Router {
  routes = []

  constructor(routes) {
    this.routes = fp.map((route) => {
      const [pattern, matcherFn] =
        typeof route === 'string' ? [route, null] : route
      // const [pattern, matcherFn] = fp.concat([], route)
      return {
        pattern: new UrlPattern(pattern),
        matcherFn,
      }
    })(routes)
  }

  /**
   * Given path like /recipe/c0ffee, returns object of matches or null.
   */
  match(path) {
    let matches = null
    _.forEach(this.routes, ({ pattern, matcherFn }) => {
      matches = pattern.match(path)
      return matches === null || !(matcherFn ? matcherFn(matches, path) : true)
    })

    return matches
  }
}

export default Router
