function blendTone(ctx, shadowImageData, highlightImageData) {
  const { width, height } = ctx.canvas

  const destinationImageData = ctx.getImageData(0, 0, width, height)
  const dst = destinationImageData.data
  const len = dst.length

  const shadowPixels = shadowImageData.data
  const highlightPixels = highlightImageData.data

  let alpha
  let r
  let g
  let b

  let shadow
  let highlight
  let shadowByInverseHighlight
  let shadowByHighlightAndInverseAlpha

  for (let px = 0; px < len; px += 4) {
    alpha = dst[px + 3] / 255
    r = dst[px] * alpha
    g = dst[px + 1] * alpha
    b = dst[px + 2] * alpha

    shadow = shadowPixels[px] / 255
    highlight = highlightPixels[px] / 255
    shadowByInverseHighlight = shadow * (1 - highlight)
    shadowByHighlightAndInverseAlpha = shadow * (highlight + 1 - alpha) * 255

    dst[px] = r * shadowByInverseHighlight + shadowByHighlightAndInverseAlpha
    dst[px + 1] =
      g * shadowByInverseHighlight + shadowByHighlightAndInverseAlpha
    dst[px + 2] =
      b * shadowByInverseHighlight + shadowByHighlightAndInverseAlpha
  }

  ctx.putImageData(destinationImageData, 0, 0)
}

export default blendTone
