// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { NumberRingColorRow } from '../typings';

const data = [
  {
    "id": "blackGoldOutline",
    "name": "Black Gold outline",
    "props": {
      "hex": "#313133",
      "hasGoldOutline": true
    }
  },
  {
    "id": "blueGoldOutline",
    "name": "Blue Gold outline",
    "props": {
      "hex": "#323e63",
      "hasGoldOutline": true
    }
  },
  {
    "id": "redGoldOutline",
    "name": "Red Gold outline",
    "props": {
      "hex": "#9c1e1d",
      "hasGoldOutline": true
    }
  },
  {
    "id": "whiteGoldOutline",
    "name": "White Gold outline",
    "props": {
      "hex": "#f8f8f8",
      "hasGoldOutline": true
    }
  },
  {
    "id": "black",
    "name": "Black",
    "props": {
      "hex": "#313133",
      "hasGoldOutline": false
    }
  },
  {
    "id": "blue",
    "name": "Blue",
    "props": {
      "hex": "#323e63",
      "hasGoldOutline": false
    }
  },
  {
    "id": "red",
    "name": "Red",
    "props": {
      "hex": "#9c1e1d",
      "hasGoldOutline": false
    }
  },
  {
    "id": "white",
    "name": "White",
    "props": {
      "hex": "#f8f8f8",
      "hasGoldOutline": false
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NumberRingColorRow[]>> = data;

export type NumberRingColor = typeof typed[number];

export const NUMBER_RING_COLOR_INDEX_KEY = "id";
export type NumberRingColorIndexKey = "id";
export type NumberRingColorId = NumberRingColor["id"];

let i = 0;
export const NUMBER_RING_COLOR_DICT = {
  "blackGoldOutline": typed[i++],
  "blueGoldOutline": typed[i++],
  "redGoldOutline": typed[i++],
  "whiteGoldOutline": typed[i++],
  "black": typed[i++],
  "blue": typed[i++],
  "red": typed[i++],
  "white": typed[i++]
} as const;

export { typed as NUMBER_RING_COLORS };
