import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import ContentHead from '../atoms/ContentHead'
import ContentLabel from '../atoms/ContentLabel'
import ContentBody from '../atoms/ContentBody'

import './FinalizeBlock.css'

function FinalizeBlock(props) {
  const { primaryLabel, children } = props
  return (
    <div className={cn(['block', mods(['finalize'])])}>
      {primaryLabel && (
        <ContentHead classMods={['block', 'finalize']}>
          <ContentLabel classMods={['finalize']}>
            <strong>{primaryLabel}</strong>
          </ContentLabel>
        </ContentHead>
      )}
      <ContentBody classMods={['block', 'finalize']}>
        <table
          className={cn(['finalizeBlock-table'])}
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>{children}</tbody>
        </table>
      </ContentBody>
    </div>
  )
}

export default FinalizeBlock
