import fp from 'lodash/fp.js'
import { connect } from 'react-redux'

import {
  reloadApp,
  resetApp,
  dismissUnhandledErrorAlert,
} from '../common/actions'
import ErrorAlert from '../components/ErrorAlert'

const mapStateToProps = fp.pick('isErrorAlertOpen')

const mapDispatchToProps = {
  reloadApp,
  resetApp,
  dismissUnhandledErrorAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert)
