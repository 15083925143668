import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import React from 'react'

import Loader from '../Loader'
import getPreferredSize from '../getPreferredSize'
import generatePreviews from '../generatePreviews'
import { render } from '../canvas'

class RenderComposite extends React.Component {
  constructor(props) {
    super(props)

    this.loader = new Loader()
  }

  state = {
    rendererParamsByView: {},
    rendererErrorByView: {},
    isRenderedByView: {},
  }

  componentDidMount() {
    this.props.setPreviewGenerator((viewNames) =>
      generatePreviews(viewNames)(
        this.loader,
        this.props.renderComposite,
        this.props.viewAngles,
        this.props.expandedRecipeNested,
      ),
    )

    this.wrapperRef = document.getElementsByClassName('render-composite')[0]

    this.calculateSize()

    window.addEventListener('resize', this.onViewportChange)
    document.addEventListener('visibilitychange', this.onViewportChange)

    this.onProps()
  }

  componentDidUpdate(prevProps) {
    this.onProps(prevProps)

    const shouldResetLoaderCache =
      prevProps.activeView !== this.props.activeView ||
      prevProps.size !== this.props.size
    if (shouldResetLoaderCache) {
      this.loader.reset()
    }
  }

  componentWillUnmount() {
    this.props.setPreviewGenerator(undefined)

    this.loader.reset()

    window.removeEventListener('resize', this.onViewportChange)
    document.removeEventListener('visibilitychange', this.onViewportChange)
  }

  onProps(prevProps) {
    const { isLoading, activeView, size, layers } = this.props

    this.calculateSize()

    if (!size || !layers) {
      return
    }

    const rendererParams = {
      size,
      layers,
    }

    const isChanged =
      !this.state.rendererParamsByView[activeView] ||
      !fp.isEqual(rendererParams, this.state.rendererParamsByView[activeView])
    const isRendered = !!this.state.isRenderedByView[activeView]

    const shouldRender =
      prevProps && prevProps.activeView === activeView ?
        isChanged
      : isChanged || !isRendered

    if (shouldRender) {
      this.setState(
        fp.pipe(
          fp.set(['rendererParamsByView', activeView], rendererParams),
          fp.set(['isRenderedByView', activeView], false),
        ),
      )

      if (!isLoading) {
        this.props.setLoading(true)
      }

      if (this.loadBluebird) {
        this.loadBluebird.cancel()
      }

      this.loadBluebird = this.loader
        .load(layers)
        .then((assets) => {
          setTimeout(() => {
            const canvas = this.canvaRefs[activeView]

            render(canvas, assets, size, activeView)

            this.setState(
              fp.pipe(
                fp.set(['rendererErrorByView', activeView], false),
                fp.set(['isRenderedByView', activeView], true),
              ),
            )
            this.props.setLoading(false)

            if (this.props.onCanvasRendered) {
              this.props.onCanvasRendered(canvas)
            }
          }, 0)
        })
        .catch((err) => {
          console.error(err)

          this.setState(
            fp.pipe(
              fp.set(['rendererErrorByView', activeView], true),
              fp.set(['isRenderedByView', activeView], false),
            ),
          )
          this.props.setLoading(false)
        })
    }
  }

  onViewportChange = () => {
    this.calculateSize()
  }

  getCurrentPreferredSize = () =>
    this.props.preferredSizes[this.props.activeView]

  getContainerSize = () => {
    if (this.wrapperRef == null) {
      return { width: window.innerWidth, height: window.innerHeight }
    }

    return fp.pick(['width', 'height'], this.wrapperRef.getBoundingClientRect())
  }

  calculateSize = () => {
    const { renderComposite, activeView } = this.props

    const containerSize = this.getContainerSize()

    if (!containerSize) {
      return
    }

    const aspectRatio = renderComposite.getAssetAspectRatio(
      activeView,
      this.props.expandedRecipeNested,
    )
    const preferredSize = getPreferredSize(
      renderComposite.heights,
      aspectRatio,
      containerSize,
    )

    const hasSizeChanged = !fp.isEqual(
      preferredSize,
      this.getCurrentPreferredSize(),
    )
    if (hasSizeChanged) {
      this.props.setSize({ activeView, preferredSize })
    }
  }

  loader
  wrapperRef = null
  canvaRefs = {}
  loadBluebird = null

  render() {
    const {
      viewAngles,
      isHidden,
      isLoading,
      activeView,
      preferredSizes,
      LoadingIndicator,
    } = this.props

    const viewAnglesForUI = fp.pickBy({ exposeForUI: true })(viewAngles)

    return (
      <div id="render-composite" className={isHidden ? 'is-hidden' : ''}>
        <div className="canvas-container">
          {_.map(viewAnglesForUI, (__, view) => {
            const size = preferredSizes[view] || {}
            const width = size.width || 0
            const height = size.height || 0
            return (
              <canvas
                key={view}
                ref={(ref) => {
                  this.canvaRefs[view] = ref
                }}
                style={
                  (
                    activeView !== view ||
                    this.state.rendererErrorByView[activeView]
                  ) ?
                    { display: 'none' }
                  : null
                }
                width={width}
                height={height}
              />
            )
          })}
        </div>
        {isLoading ?
          <div className="loader-container">
            <LoadingIndicator />
          </div>
        : null}
        {this.state.rendererErrorByView[activeView] ?
          <div className="loader-container">
            <div className="renderer-error">
              <h1>Can&apos;t render view</h1>
              <p>Please try another configuration or refresh to try again.</p>
            </div>
          </div>
        : null}
      </div>
    )
  }
}

export default RenderComposite
