// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveAssetRow } from '../typings';

const data = [
  {
    "id": "100",
    "props": {
      "sportId": "baseball",
      "shapeId": "fielder",
      "sizeId": "12_00",
      "usageCount": 3,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 2942,
          "height": 4000
        },
        "web": {
          "width": 3691,
          "height": 4000
        },
        "palm": {
          "width": 3811,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": true,
          "numberRing": true,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": true,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "400",
    "props": {
      "sportId": "baseball",
      "shapeId": "fielder",
      "sizeId": "11_50",
      "usageCount": 6,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3653,
          "height": 4616
        },
        "web": {
          "width": 4178,
          "height": 4228
        },
        "palm": {
          "width": 4339,
          "height": 4429
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": true,
          "numberRing": true,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": true,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "600",
    "props": {
      "sportId": "baseball",
      "shapeId": "fielder",
      "sizeId": "11_75",
      "usageCount": 9,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3270,
          "height": 4000
        },
        "web": {
          "width": 3567,
          "height": 4000
        },
        "palm": {
          "width": 4000,
          "height": 3825
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": true,
          "numberRing": true,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": true,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "700",
    "props": {
      "sportId": "baseball",
      "shapeId": "fielder",
      "sizeId": "12_75",
      "usageCount": 3,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3005,
          "height": 4000
        },
        "web": {
          "width": 3722,
          "height": 4000
        },
        "palm": {
          "width": 3868,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": true,
          "numberRing": true,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": true,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "300",
    "props": {
      "sportId": "baseball",
      "shapeId": "firstBase",
      "sizeId": "12_50",
      "usageCount": 2,
      "isReceived": false,
      "imageDimensions": {
        "back": {
          "width": 2550,
          "height": 4000
        },
        "web": {
          "width": 3151,
          "height": 4000
        },
        "palm": {
          "width": 3642,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": false,
          "serie": true,
          "size": true,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "335",
    "props": {
      "sportId": "baseball",
      "shapeId": "catcher",
      "sizeId": "33_50",
      "usageCount": 1,
      "isReceived": false,
      "imageDimensions": {
        "back": {
          "width": 3166,
          "height": 4000
        },
        "web": {
          "width": 3124,
          "height": 4000
        },
        "palm": {
          "width": 3803,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": false,
          "numberRing": false,
          "playerName": false,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": true,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": false,
          "serie": true,
          "size": true,
          "mizunoLogo": true
        }
      },
      "hasEmbroideryOnPalm": true,
      "embossPattern": false,
      "webOnBackView": true,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "335-proSelect",
    "props": {
      "sportId": "baseball",
      "shapeId": "catcher",
      "sizeId": "33_50",
      "usageCount": 1,
      "isReceived": false,
      "imageDimensions": {
        "back": {
          "width": 3166,
          "height": 4000
        },
        "web": {
          "width": 3124,
          "height": 4000
        },
        "palm": {
          "width": 3803,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": false,
          "numberRing": false,
          "playerName": false,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": false,
          "serie": true,
          "size": true,
          "mizunoLogo": true
        }
      },
      "hasEmbroideryOnPalm": true,
      "embossPattern": false,
      "webOnBackView": true,
      "hasPinkyThumbPanels": true
    }
  },
  {
    "id": "1150C",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "11_50",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3170,
          "height": 4000
        },
        "web": {
          "width": 4121,
          "height": 4000
        },
        "palm": {
          "width": 3597,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": true
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1175C",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "11_75",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3076,
          "height": 4000
        },
        "web": {
          "width": 3944,
          "height": 4000
        },
        "palm": {
          "width": 3557,
          "height": 4000
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": true
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1200C",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "12_00",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3106,
          "height": 4000
        },
        "web": {
          "width": 4249,
          "height": 4365
        },
        "palm": {
          "width": 4111,
          "height": 4600
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": true
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1250C",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "12_50",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 3530,
          "height": 4828
        },
        "web": {
          "width": 3969,
          "height": 4000
        },
        "palm": {
          "width": 4244,
          "height": 4731
        }
      },
      "meshes": {
        "web": {
          "liner": true,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": true
        },
        "back": {
          "flag": true,
          "playerName": false,
          "ribbon": true
        },
        "palm": {
          "leather": true,
          "name": true,
          "pocket": true,
          "serie": true,
          "size": true,
          "mizunoLogo": true
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1151",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "11_50",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 1587,
          "height": 2186
        },
        "web": {
          "width": 1841,
          "height": 2048
        },
        "palm": {
          "width": 2098,
          "height": 2407
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1202",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "12_00",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 1578,
          "height": 2190
        },
        "web": {
          "width": 1810,
          "height": 2048
        },
        "palm": {
          "width": 1706,
          "height": 2048
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1203",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "12_00",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 1536,
          "height": 2254
        },
        "web": {
          "width": 1968,
          "height": 2110
        },
        "palm": {
          "width": 1705,
          "height": 2048
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1252",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "12_50",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 1588,
          "height": 2344
        },
        "web": {
          "width": 1902,
          "height": 2187
        },
        "palm": {
          "width": 1759,
          "height": 2115
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1253",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "12_50",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 1703,
          "height": 2308
        },
        "web": {
          "width": 1961,
          "height": 2132
        },
        "palm": {
          "width": 1838,
          "height": 2065
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "1302",
    "props": {
      "sportId": "softball",
      "shapeId": "fielder",
      "sizeId": "13_00",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 1674,
          "height": 2346
        },
        "web": {
          "width": 2003,
          "height": 2161
        },
        "palm": {
          "width": 1883,
          "height": 2099
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": true,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": false,
      "hasPinkyThumbPanels": false
    }
  },
  {
    "id": "CM33",
    "props": {
      "sportId": "softball",
      "shapeId": "catcher",
      "sizeId": "34_50",
      "usageCount": 1,
      "isReceived": true,
      "imageDimensions": {
        "back": {
          "width": 2251,
          "height": 3362
        },
        "web": {
          "width": 2546,
          "height": 3154
        },
        "palm": {
          "width": 3026,
          "height": 3217
        }
      },
      "meshes": {
        "web": {
          "liner": false,
          "number": false,
          "numberRing": false,
          "playerName": false,
          "serieWeb": false
        },
        "back": {
          "flag": false,
          "playerName": true,
          "ribbon": false
        },
        "palm": {
          "leather": false,
          "name": false,
          "pocket": false,
          "serie": false,
          "size": false,
          "mizunoLogo": false
        }
      },
      "hasEmbroideryOnPalm": false,
      "embossPattern": false,
      "webOnBackView": true,
      "hasPinkyThumbPanels": false
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveAssetRow[]>> = data;

export type GloveAsset = typeof typed[number];

export const GLOVE_ASSET_INDEX_KEY = "id";
export type GloveAssetIndexKey = "id";
export type GloveAssetId = GloveAsset["id"];

let i = 0;
export const GLOVE_ASSET_DICT = {
  "100": typed[i++],
  "400": typed[i++],
  "600": typed[i++],
  "700": typed[i++],
  "300": typed[i++],
  "335": typed[i++],
  "335-proSelect": typed[i++],
  "1150C": typed[i++],
  "1175C": typed[i++],
  "1200C": typed[i++],
  "1250C": typed[i++],
  "1151": typed[i++],
  "1202": typed[i++],
  "1203": typed[i++],
  "1252": typed[i++],
  "1253": typed[i++],
  "1302": typed[i++],
  "CM33": typed[i++]
} as const;

export { typed as GLOVE_ASSETS };
