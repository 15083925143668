import axios, { AxiosError, AxiosResponse } from 'axios'

import http from './http'

export async function sendToFactory(
  recipeId: string,
  orderId: string,
  debug = false,
): Promise<
  | { result: AxiosResponse; error?: undefined }
  | { result?: undefined; error: AxiosError }
> {
  try {
    const result = await http.post(`/admin/api/send-to-factory/${recipeId}`, {
      orderId,
      debug,
    })
    return { result }
  } catch (error) {
    if (axios.isAxiosError(error)) return { error }
    throw error
  }
}
