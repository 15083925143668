import fp from 'lodash/fp.js'
import { createSelector } from 'reselect'

export const createDependencyResolver = fp.memoize((dependency) => {
  const sheetPrefix = 'sheet:'
  if (dependency.startsWith(sheetPrefix)) {
    return fp.get(['state', 'sheets', dependency.slice(sheetPrefix.length)])
  }

  const sheetListPrefix = 'sheetList:'
  if (dependency.startsWith(sheetListPrefix)) {
    return fp.get([
      'state',
      'sheetLists',
      dependency.slice(sheetListPrefix.length),
    ])
  }

  return ({ partialNodes, state }) => {
    const repeatedIds = state.controlTree.repeatedNodes[dependency]

    if (repeatedIds === undefined) {
      // The most common type of dependency, not a repeater node or anything.
      return fp.get([dependency, 'object'])(partialNodes)
    }

    // It must be a repeater node. Resolves dependency to an array of repeater subtrees.
    const repeaterSubtrees = fp.map((repeatedId) => {
      const repeaterPath = `${dependency}.${repeatedId}`

      const nodes = fp.pickBy(
        (v, k) => k.startsWith(`${repeaterPath}.`),
        partialNodes,
      )

      return fp.mapKeys(
        (k) => k.replace(`${repeaterPath}.`, ''),
        fp.mapValues((v) => partialNodes[v.keyPath], nodes),
      )
    }, repeatedIds)

    return repeaterSubtrees
  }
})

export const getAllOptions = (n) => {
  if (!n.options && !n.optionGroups) return undefined
  if (n.visibleOptions) return n.visibleOptions
  return n.options ? n.options : fp.flatten(fp.values(n.optionGroups))
}

/**
 * Abstract base node type.
 */
export class AbstractNode {
  constructor(props) {
    if (this.constructor === AbstractNode) {
      throw new TypeError('Cannot instantiate abstract class')
    }

    this.props = fp.assign(this.constructor.defaults, props)
  }
}

export const mapMethods = (mapper) =>
  fp.mapValues((v) => (typeof v === 'function' ? mapper(v) : v))
export const $$naturalValue = Symbol('naturalValue')

/**
 * Supertype of all data nodes.
 */
export class LeafNode extends AbstractNode {
  nodeKind = 'LeafNode'

  constructor(props) {
    super(props)

    if (this.constructor === LeafNode) {
      throw new TypeError('Cannot instantiate abstract class')
    }
  }

  bind(meta) {
    const keyPath = meta.path.join('.')
    const nodeKind = this.nodeKind

    let props = fp.omit(this.constructor.specialProps, this.props)
    const specialProps = fp.pick(this.constructor.specialProps, this.props)
    const dependencySelectors = fp.map(
      createDependencyResolver,
      props.dependencies,
    )
    props = mapMethods((prop) => createSelector(dependencySelectors, prop))(
      props,
    )

    const variantAvailable = meta.isAvailable || ((context) => true)
    const isVariantAvailable = (context) =>
      fp.isFunction(variantAvailable) ?
        variantAvailable(context)
      : variantAvailable

    const selfAvailable = props.isAvailable || ((context) => true)
    const isSelfAvailable = (context) =>
      fp.isFunction(selfAvailable) ? selfAvailable(context) : selfAvailable

    const isAvailable = (context) =>
      isVariantAvailable(context) && isSelfAvailable(context)

    const repeaterPath = meta.repeaterPath || null
    const repeatedItemPath = meta.repeatedItemPath || null

    props.isAvailable = isAvailable
    props.isVariantAvailable = isVariantAvailable
    props.isSelfAvailable = isSelfAvailable
    props.keyPath = keyPath
    props.nodeKind = nodeKind

    const resolve = (state, partialNodes, newPath = null) => {
      const context = { state, partialNodes }

      const variantAvailable2 = isVariantAvailable(context)
      if (!variantAvailable2 || !isSelfAvailable(context)) {
        const { label, changeLabel } = mapMethods((m) => m(context))({
          label: props.label,
          changeLabel: props.changeLabel,
        })

        return {
          ...props,
          isVariantAvailable: variantAvailable2,
          isSelfAvailable: false,
          isAvailable: false,
          value: undefined,
          object: undefined,
          error: false,
          label,
          changeLabel,
        }
      }

      const { value, ...rest } = props

      let resolvedNode = mapMethods((m) => m(context))(rest)
      resolvedNode = { ...resolvedNode, ...specialProps }

      if (newPath) {
        resolvedNode.keyPath = newPath
      }

      if (resolvedNode.defaultValue === $$naturalValue) {
        resolvedNode.defaultValue =
          this.constructor.getNaturalValue(resolvedNode)
      }

      const resolvedValue = this.constructor.resolveValue(state, resolvedNode)
      if (value !== undefined) {
        resolvedNode.value =
          fp.isFunction(value) ? value(context)(resolvedValue) : value
      } else {
        resolvedNode.value = resolvedValue
      }

      resolvedNode.object = this.constructor.resolveObject(
        resolvedNode.value,
        resolvedNode,
      )

      const allOptions = getAllOptions(resolvedNode)

      if (allOptions) {
        const { value } = resolvedNode

        if (resolvedNode.multiple && value && value.length !== 0) {
          const options = value.map((id) => fp.find({ id }, allOptions))
          const optionNames = options.map((option) => option.name)

          if (optionNames.length <= 2) {
            resolvedNode.optionName = optionNames.join(' & ')
          } else {
            resolvedNode.optionName = `${optionNames
              .slice(0, -1)
              .join(', ')} & ${optionNames[optionNames.length - 1]}`
          }
        } else if (value) {
          const option = fp.find({ id: value }, allOptions)
          if (option) {
            resolvedNode.optionName = option.name
          }
        }
      }

      if (
        resolvedNode.autoUnavailable &&
        resolvedNode.isAvailable &&
        nodeKind === 'SelectNode'
      ) {
        resolvedNode.isAvailable = allOptions ? !!allOptions.length : false
      }

      if (resolvedNode.isAvailable) {
        resolvedNode.error = !this.constructor.isValid(
          resolvedNode.value,
          resolvedNode,
        )
      }

      return resolvedNode
    }

    return {
      keyPath,
      nodeKind,
      resolve,
      label: props.label || keyPath,
      graphDependencies: fp.concat(meta.dependencies, props.dependencies),
      repeaterPath,
      repeatedItemPath,
    }
  }
}
