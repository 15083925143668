// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SoftnesseRow } from '../typings';

const data = [
  {
    "id": "soft",
    "name": "Soft"
  },
  {
    "id": "stiff",
    "name": "Stiff"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SoftnesseRow[]>> = data;

export type Softnesse = typeof typed[number];

export const SOFTNESSE_INDEX_KEY = "id";
export type SoftnesseIndexKey = "id";
export type SoftnesseId = Softnesse["id"];

let i = 0;
export const SOFTNESSE_DICT = {
  "soft": typed[i++],
  "stiff": typed[i++]
} as const;

export { typed as SOFTNESSES };
