import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './ProductSet.css'

function ProductSet(props) {
  const { classMods, children } = props
  return <div className={cn(['productSet', mods(classMods)])}>{children}</div>
}

export default ProductSet
