import bluebird from 'bluebird'

bluebird.config({ cancellation: true })

// Handle error from promises. This is bluebird specific.
bluebird.onPossiblyUnhandledRejection((e) => {
  // Manually throw so that it's delegated to `onerror` handler.
  throw e
})

export default bluebird
