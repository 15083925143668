function blendShadow(ctx, shadowImageData) {
  const { width, height } = ctx.canvas

  const destinationImageData = ctx.getImageData(0, 0, width, height)
  const dst = destinationImageData.data
  const len = dst.length

  const shadowPixels = shadowImageData.data

  let r
  let g
  let b

  let shadow
  let shadowMinus128

  for (let px = 0; px < len; px += 4) {
    if (dst[px + 3] === 0) {
      continue
    }

    r = dst[px]
    g = dst[px + 1]
    b = dst[px + 2]

    shadow = shadowPixels[px]
    shadowMinus128 = shadow - 128

    if (shadow >= 128) {
      dst[px] =
        (255 - ((255 - r) * (255 - shadow)) / 127 + (shadowMinus128 + r)) / 2
      dst[px + 1] =
        (255 - ((255 - g) * (255 - shadow)) / 127 + (shadowMinus128 + g)) / 2
      dst[px + 2] =
        (255 - ((255 - b) * (255 - shadow)) / 127 + (shadowMinus128 + b)) / 2
    } else {
      dst[px] = ((shadow / 127) * r + (shadowMinus128 + r)) / 2
      dst[px + 1] = ((shadow / 127) * g + (shadowMinus128 + g)) / 2
      dst[px + 2] = ((shadow / 127) * b + (shadowMinus128 + b)) / 2
    }
  }

  ctx.putImageData(destinationImageData, 0, 0)
}

export default blendShadow
