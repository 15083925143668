import fp from 'lodash/fp.js'
import React from 'react'

import TileSet from '~mizuno/client/components/organisms/TileSet'

const getTileProps = (props) => (opt) => {
  const { resolvedNode, value, change, svgType, getUrl, classMods } = props

  const multiple = resolvedNode && resolvedNode.multiple
  const isRequired = resolvedNode && resolvedNode.isRequired

  const isDisabled = opt.isDisabled
  const isActive = multiple ? fp.includes(opt.id, value) : opt.id === value

  return {
    ...opt,

    key: opt.id || 'none',
    dataLabel: 'None',
    isDisabled,
    isActive,
    onClick: () => {
      if (isDisabled) {
        return
      }

      if (multiple) {
        const newValue = fp.compact(
          isActive ?
            fp.reject((v) => v === opt.id, value)
          : fp.concat(value, opt.id),
        )
        change(newValue)
      } else {
        change(isActive && !isRequired ? null : opt.id)
      }
    },
    svgType,
    svg: svgType ? opt.id : null,
    url: getUrl && getUrl(opt, resolvedNode),
    title: opt.name,
    classMods: fp.flatten([
      classMods || [],
      opt.classMods || [],
      isDisabled ? 'disabled' : [],
    ]),
    subline: resolvedNode && resolvedNode.subline,
  }
}

export default (props) => {
  const { type, tileType, options, resolvedNode, showLabel, subline } = props

  return (
    <TileSet
      tiles={fp.map(getTileProps(props), options)}
      type={tileType ?? type}
      onClick={() => {}}
      primaryLabel={showLabel ? resolvedNode.label : null}
      subline={subline}
    />
  )
}
