import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './ContentHead.css'

function ContentHead(props) {
  const { children, classMods = [], onClick } = props
  return (
    <div className={cn(['contentHead', mods(classMods)])} onClick={onClick}>
      {children}
    </div>
  )
}

export default ContentHead
