import { createAction } from 'redux-actions'

const toggleSection = createAction('TOGGLE_SECTION')
const openSection = createAction('OPEN_SECTION')

const toggleBlock = createAction('TOGGLE_BLOCK')

const setGarmentFilter = createAction('SET_GARMENT_FILTER')

const garmentSelected = createAction('GARMENT_SELECTED')

export {
  toggleSection,
  openSection,
  toggleBlock,
  setGarmentFilter,
  garmentSelected,
}
