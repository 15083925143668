import { compact, flattenDeep, map } from '@technically/lodash'

import { getLayerWithSrc } from '../../../platform/client/renderComposite/getLayerWithSrc'

import heights from './heights'
import { revManifests } from '../assetPipeline/manifest'
import { getLayerInstructions } from './getLayerInstructions'

export const getCustomizerAssetSrc = (bundleName, filePath, size) =>
  '//storage.googleapis.com/olv-customizers-mizuno-gloves-' +
  `${bundleName}/` +
  `x${size.height}/` +
  `${filePath}`

export const getLayers = (expandedRecipeNested, size, viewName) => {
  let rawLayers = []
  try {
    rawLayers = getLayerInstructions(expandedRecipeNested, viewName)
    rawLayers = compact(flattenDeep(rawLayers))
  } catch (error) {
    console.error(error)
  }

  const layersWithSrc = map(
    rawLayers,
    getLayerWithSrc(
      getCustomizerAssetSrc,
      expandedRecipeNested.calc.gloveAsset,
      viewName,
      size,
      revManifests,
    ),
  )

  return compact(layersWithSrc)
}

export const getAssetAspectRatio = (activeView, expandedRecipeNested) => {
  const size =
    expandedRecipeNested.calc.gloveAsset.props.imageDimensions[activeView]
  return size.height / size.width
}

export { heights }
