// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FingerTypeRow } from '../typings';

const data = [
  {
    "id": "pad",
    "name": "Pad"
  },
  {
    "id": "hood",
    "name": "Hood"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FingerTypeRow[]>> = data;

export type FingerType = typeof typed[number];

export const FINGER_TYPE_INDEX_KEY = "id";
export type FingerTypeIndexKey = "id";
export type FingerTypeId = FingerType["id"];

let i = 0;
export const FINGER_TYPE_DICT = {
  "pad": typed[i++],
  "hood": typed[i++]
} as const;

export { typed as FINGER_TYPES };
