// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SizeRow } from '../typings';

const data = [
  {
    "id": "11_25",
    "name": "11.25”"
  },
  {
    "id": "11_50",
    "name": "11.5”"
  },
  {
    "id": "11_75",
    "name": "11.75”"
  },
  {
    "id": "12_00",
    "name": "12”"
  },
  {
    "id": "12_25",
    "name": "12.25”"
  },
  {
    "id": "12_50",
    "name": "12.5”"
  },
  {
    "id": "12_75",
    "name": "12.75”"
  },
  {
    "id": "13_00",
    "name": "13”"
  },
  {
    "id": "33_50",
    "name": "33.5”"
  },
  {
    "id": "34_50",
    "name": "34.5”"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SizeRow[]>> = data;

export type Size = typeof typed[number];

export const SIZE_INDEX_KEY = "id";
export type SizeIndexKey = "id";
export type SizeId = Size["id"];

let i = 0;
export const SIZE_DICT = {
  "11_25": typed[i++],
  "11_50": typed[i++],
  "11_75": typed[i++],
  "12_00": typed[i++],
  "12_25": typed[i++],
  "12_50": typed[i++],
  "12_75": typed[i++],
  "13_00": typed[i++],
  "33_50": typed[i++],
  "34_50": typed[i++]
} as const;

export { typed as SIZES };
