import fp from 'lodash/fp.js'
import {
  Text,
  Select,
  FileUpload,
  createControlTree,
} from '../../../platform/client/control-tree'

import {
  COLORS,
  COLOR_DICT,
  COLOR_SET_DICT,
  CURRENCY_DICT,
  FINGER_LOCATIONS,
  FINGER_TYPES,
  FLAGS,
  FONTS,
  GLOVES,
  GLOVE_ASSET_DICT,
  HANDS,
  LACE_LENGTHS,
  NUMBER_RING_COLORS,
  PALM_LINER_MATERIALS,
  PINKY_TYPES,
  PINKY_TYPE_DICT,
  PINKY_TYPE_SET_DICT,
  POCKET_DEPTHS,
  POCKET_DEPTH_DICT,
  POSITIONS,
  POSITION_DICT,
  PROP_DEF_DICT,
  SERIES,
  SERIE_DICT,
  SIZES,
  SIZE_DICT,
  SOFTNESSES,
  SPORT_DICT,
  STAMPS,
  TOGGLES,
  WEBS,
  WELTING_TYPES,
  WELTING_TYPE_DICT,
  WELTING_TYPE_SET_DICT,
  VENDOR_DICT,
} from '../common/sheets'

const currencyId =
  typeof window !== 'undefined' ? window.serverConfig.currency : 'USD'

const boilerplate = (propDef) => ({
  label: propDef.name,
  subline: propDef.description,
  description: propDef.description,
  defaultValue: propDef.defaultValueId || null,
  isRequired: !propDef.isOptional,
})

const isAvailableBySerie = (propDef) => (serie) =>
  propDef.availability[serie.id] === 'TRUE'

const getAltColors = (colors, key) =>
  fp.map((color) => {
    const alts = color.props.alt
    const altHex = alts && alts[key]
    if (altHex) {
      return { ...color, props: { ...color.props, hex: altHex } }
    }
    return color
  }, colors)

const getSubsetBySerie = (propDef) => (serie) => {
  const availability = propDef.availability[serie.id]

  if (availability === 'FALSE') {
    return null
  }

  switch (propDef.dataType) {
    case 'colorSets': {
      return COLOR_SET_DICT[availability]
    }
    case 'weltingTypeSets': {
      return WELTING_TYPE_SET_DICT[availability]
    }
    case 'pinkyTypeSets': {
      return PINKY_TYPE_SET_DICT[availability]
    }
    default:
      throw Error('Unhandled dataType!')
  }
}

const getOptionsBySerie = (propDef) => (serie) => {
  const availability = propDef.availability[serie.id]

  let dataSheet
  const set = getSubsetBySerie(propDef)(serie)
  switch (propDef.dataType) {
    case 'colorSets': {
      dataSheet = COLOR_DICT
      break
    }
    case 'weltingTypeSets': {
      dataSheet = WELTING_TYPE_DICT
      break
    }
    case 'pinkyTypeSets': {
      dataSheet = PINKY_TYPE_DICT
      break
    }
    default: {
      // noop
    }
  }

  if (availability === 'TRUE') {
    return fp.values(dataSheet)
  }

  if (!set) {
    return []
  }

  let rows = fp.map((id) => dataSheet[id], fp.compact(set.data))

  // Use alt color if available.
  if (propDef.dataType === 'colorSets') {
    rows = getAltColors(rows, availability)
  }

  return rows
}

const getDefaultColorBySerie = (propDef) => (serie) => {
  const colorSet = getSubsetBySerie(propDef)(serie)
  return (colorSet && colorSet.defaultValueId) || propDef.defaultValueId
}

const boilerplateColor = (propDef) => ({
  dependencies: ['calc.serie'],
  autoUnavailable: true,
  label: propDef.name,
  subline: propDef.description,
  description: propDef.description,
  options: COLORS,
  visibleOptions: getOptionsBySerie(propDef),
  defaultValue: getDefaultColorBySerie(propDef),
  isRequired: !propDef.isOptional,
})

const gloveHasSport = (sportId) => (glove) =>
  SERIE_DICT[glove.props.serieId].props.sportId === sportId

const gloveHasSerie = (serieId) => (glove) => glove.props.serieId === serieId

const gloveHasHand = (handId) => (glove) =>
  glove.limitations.throwingHandIds[handId]

const gloveHasPosition = (positionId) => (glove) =>
  glove.limitations.positionIds[positionId]

const gloveHasSize = (sizeId) => (glove) =>
  GLOVE_ASSET_DICT[glove.asset.gloveAssetId].props.sizeId === sizeId

const gloveHasPocketDepth = (pocketDepthId) => (glove) =>
  glove.props.pocket === pocketDepthId

const gloveHasWeb = (webId) => (glove) => glove.limitations.webIds[webId]

const gloveHasAnyPosition = (positions) => (glove) =>
  fp.some((position) => gloveHasPosition(position.id)(glove))(positions)

const gloveHasAnySize = (sizes) => (glove) =>
  fp.some((size) => gloveHasSize(size.id)(glove))(sizes)

const gloveHasAnyPocketDepth = (pocketDepths) => (glove) =>
  fp.some((pocketDepth) => gloveHasPocketDepth(pocketDepth.id)(glove))(
    pocketDepths,
  )

const isSerieAvailable = (gloves) => (serie) =>
  fp.some(gloveHasSerie(serie.id))(gloves)

const isHandAvailable = (gloves) => (hand) =>
  fp.some(gloveHasHand(hand.id))(gloves)

const isPositionAvailable = (gloves) => (position) =>
  fp.some(gloveHasPosition(position.id))(gloves)

const isSizeAvailable = (gloves) => (size) =>
  fp.some(gloveHasSize(size.id))(gloves)

const isPocketDepthAvailable = (gloves) => (pocketDepth) =>
  fp.some(gloveHasPocketDepth(pocketDepth.id))(gloves)

const isWebAvailableForGlove = (glove) => (web) => gloveHasWeb(web.id)(glove)

// TODO consider moving to data
const supportsBackPanels = () => (serie, glove) => glove.id !== 'GMP2-335CC' // MMGQ-66

const getFilterSeriesOptions = (gloves) =>
  fp.filter(isSerieAvailable(gloves), SERIES)

const controls = {
  filtered: {
    gloves: Text({
      isPrivate: true,
      value: () => () => fp.reject((glove) => glove.props.isHidden, GLOVES),
    }),

    glovesBySport: Text({
      isPrivate: true,
      dependencies: ['filtered.gloves', 'calc.sport'],
      value: (gloves, sport) => () =>
        fp.filter(gloveHasSport(sport.id), gloves),
    }),

    glovesBySerie: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesBySport', 'filter.serie'],
      value: (gloves, serie) => () =>
        serie ?
          fp.filter((glove) => glove.props.serieId === serie.id, gloves)
        : gloves,
    }),

    glovesByHand: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesBySerie', 'product.throwingHand'],
      value: (gloves, hand) => () =>
        fp.filter(
          (glove) => glove.limitations.throwingHandIds[hand.id],
          gloves,
        ),
    }),

    glovesByPosition: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesByHand', 'filter.position'],
      value: (gloves, positions) => () => {
        if (positions == null || positions.length === 0) {
          return gloves
        }
        return fp.filter(gloveHasAnyPosition(positions), gloves)
      },
    }),

    glovesBySize: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesByPosition', 'filter.size'],
      value: (gloves, sizes) => () => {
        if (sizes == null || sizes.length === 0) {
          return gloves
        }
        return fp.filter(gloveHasAnySize(sizes), gloves)
      },
    }),

    glovesByPocketDepth: Text({
      isPrivate: true,
      dependencies: ['filtered.glovesBySize', 'filter.pocketDepth'],
      value: (gloves, pocketDepths) => () => {
        if (pocketDepths == null || pocketDepths.length === 0) {
          return gloves
        }
        return fp.filter(gloveHasAnyPocketDepth(pocketDepths), gloves)
      },
    }),
  },

  env: {
    currency: Text({
      isPrivate: true,
      value: () => () => CURRENCY_DICT[currencyId] || CURRENCY_DICT.USD,
    }),

    vendor: Text({
      isPrivate: true,
      value: () => () => VENDOR_DICT[window.serverConfig.hostname],
    }),

    sportId: Text({
      isPrivate: true,
      dependencies: ['env.vendor'],
      value: (vendor) => () => vendor.sportId,
    }),
  },

  calc: {
    sport: Text({
      ...boilerplate(PROP_DEF_DICT.calc_sport),
      dependencies: ['env.sportId'],
      isPrivate: true,
      value: (sportId) => () => SPORT_DICT[sportId],
    }),

    serie: Text({
      ...boilerplate(PROP_DEF_DICT.calc_serie),
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () => SERIE_DICT[glove.props.serieId],
    }),

    price: Text({
      isPrivate: true,
      dependencies: ['env.currency', 'product.glove'],
      value: (currency, glove) => () => glove.props.price[currency.id],
    }),

    priceFormatted: Text({
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () =>
        `USD: ${glove.props.price.USD} | CAD: ${glove.props.price.CAD}`,
    }),

    priceWithCurrency: Text({
      isPrivate: true,
      dependencies: ['calc.price', 'env.currency'],
      isAvailable: (price) => !!price,
      value: (price, currency) => () =>
        `${currency.props.prefix} ${price.toFixed(2)}`,
    }),

    positions: Text({
      ...boilerplate(PROP_DEF_DICT.calc_positions),
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () =>
        fp.compact(
          fp.map(
            ([positionId, isAvailable]) =>
              isAvailable ? POSITION_DICT[positionId] : undefined,
            fp.toPairs(glove.limitations.positionIds),
          ),
        ),
    }),

    size: Text({
      ...boilerplate(PROP_DEF_DICT.calc_size),
      isPrivate: true,
      dependencies: ['calc.gloveAsset'],
      value: (gloveAsset) => () => SIZE_DICT[gloveAsset.props.sizeId],
    }),

    pocketDepth: Text({
      ...boilerplate(PROP_DEF_DICT.calc_pocketDepth),
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () =>
        glove.props.pocket && POCKET_DEPTH_DICT[glove.props.pocket],
    }),

    sku: Text({
      ...boilerplate(PROP_DEF_DICT.calc_size),
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () => glove.name,
    }),

    gloveAsset: Text({
      isPrivate: true,
      dependencies: ['product.glove'],
      value: (glove) => () => GLOVE_ASSET_DICT[glove.asset.gloveAssetId],
    }),
  },

  product: {
    glove: Select({
      ...boilerplate(PROP_DEF_DICT.product_glove),
      dependencies: ['filtered.glovesByPocketDepth'],
      autoUnavailable: true,
      visibleOptions: (gloves) => gloves,
      options: GLOVES,
    }),

    throwingHand: Select({
      ...boilerplate(PROP_DEF_DICT.product_throwingHand),
      dependencies: ['filtered.glovesBySerie'],
      autoUnavailable: true,
      visibleOptions: (gloves) => fp.filter(isHandAvailable(gloves), HANDS),
      options: HANDS,
    }),

    web: Select({
      ...boilerplate(PROP_DEF_DICT.product_web),
      dependencies: ['product.glove'],
      defaultValue: (glove) => glove.defaults.webId,
      autoUnavailable: true,
      visibleOptions: (glove) => fp.filter(isWebAvailableForGlove(glove), WEBS),
      options: WEBS,
    }),
  },

  filter: {
    serie: Select({
      ...boilerplate(PROP_DEF_DICT.filter_serie),
      dependencies: ['filtered.glovesBySport'],
      autoUnavailable: true,
      visibleOptions: getFilterSeriesOptions,
      options: SERIES,
      isAvailable: (gloves) => getFilterSeriesOptions(gloves).length > 1,
    }),

    position: Select({
      ...boilerplate(PROP_DEF_DICT.filter_position),
      multiple: true,
      dependencies: ['filtered.glovesByHand'],
      autoUnavailable: true,
      visibleOptions: (gloves) =>
        fp.filter(isPositionAvailable(gloves), POSITIONS),
      options: POSITIONS,
    }),

    size: Select({
      ...boilerplate(PROP_DEF_DICT.filter_size),
      multiple: true,
      dependencies: ['filtered.glovesByPosition'],
      autoUnavailable: true,
      visibleOptions: (gloves) => fp.filter(isSizeAvailable(gloves), SIZES),
      options: SIZES,
    }),

    pocketDepth: Select({
      ...boilerplate(PROP_DEF_DICT.filter_pocketDepth),
      multiple: true,
      dependencies: ['filtered.glovesBySize'],
      autoUnavailable: true,
      visibleOptions: (gloves) =>
        fp.filter(isPocketDepthAvailable(gloves), POCKET_DEPTHS),
      options: POCKET_DEPTHS,
    }),
  },

  colors: {
    web: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_web),
    }),

    palm: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_palm),
    }),

    back: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_back),
      dependencies: ['calc.serie', 'product.glove'],
      visibleOptions: (serie, glove) => {
        if (!supportsBackPanels()(serie, glove)) {
          return getOptionsBySerie(PROP_DEF_DICT.colors_backPanel1)(serie)
        }
        return getOptionsBySerie(PROP_DEF_DICT.colors_back)(serie)
      },
      defaultValue: (serie, glove) => {
        if (!supportsBackPanels()(serie, glove)) {
          return getDefaultColorBySerie(PROP_DEF_DICT.colors_backPanel1)(serie)
        }
        return getDefaultColorBySerie(PROP_DEF_DICT.colors_back)(serie)
      },
    }),

    meshBack: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_meshBack),
    }),

    backPanel1: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_backPanel1),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: supportsBackPanels(),
    }),

    backPanel2: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_backPanel2),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: supportsBackPanels(),
    }),

    backPanel3: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_backPanel3),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: supportsBackPanels(),
    }),

    backPanel4: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_backPanel4),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: supportsBackPanels(),
    }),

    backPanel5: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_backPanel5),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: supportsBackPanels(),
    }),

    backPanel6: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_backPanel6),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: supportsBackPanels(),
    }),

    pinkyThumbPanels: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_pinkyThumbPanels),
      dependencies: ['calc.serie', 'calc.gloveAsset'],
      isAvailable: (_, gloveAsset) => gloveAsset.props.hasPinkyThumbPanels,
    }),

    backEmboss: Select({
      ...boilerplate(PROP_DEF_DICT.colors_backEmboss),
      dependencies: ['calc.serie', 'calc.gloveAsset'],
      isAvailable: (serie, gloveAsset) => {
        const { shapeId } = gloveAsset.props
        return (
          isAvailableBySerie(PROP_DEF_DICT.colors_backEmboss)(serie) &&
          shapeId !== 'catcher' &&
          shapeId !== 'firstBase'
        )
      },
      autoUnavailable: true,
      options: fp.filter(({ limitations }) => limitations.backEmboss, STAMPS),
    }),
    accent: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_accent),
    }),

    wrist: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_wrist),
    }),

    thumbOutside: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_thumbOutside),
      dependencies: ['calc.serie', 'product.glove'],
      isAvailable: (_, glove) => !glove.limitations.positionIds.catcher,
    }),

    thumbLoop: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_thumbLoop),
    }),

    pinkyOutside: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_pinkyOutside),
    }),

    pinkyLoop: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_pinkyLoop),
    }),

    binding: Select({
      ...boilerplateColor(PROP_DEF_DICT.colors_binding),
    }),

    welting: {
      type: Select({
        ...boilerplate(PROP_DEF_DICT.colors_welting_type),
        dependencies: ['calc.serie', 'product.glove'],
        autoUnavailable: true,
        isAvailable: (_, glove) =>
          !glove.limitations.positionIds.firstBase &&
          !glove.limitations.positionIds.catcher,
        visibleOptions: getOptionsBySerie(PROP_DEF_DICT.colors_welting_type),
        options: WELTING_TYPES,
      }),

      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.colors_welting_color),
        dependencies: ['calc.serie', 'colors.welting.type'],
        isAvailable: (_, weltingType) => !!weltingType,
        visibleOptions: (serie, weltingType) => {
          const colorSetId = weltingType.props.colorSetIds[serie.id]
          if (!colorSetId) {
            return []
          }
          const colorSet = COLOR_SET_DICT[colorSetId]
          return getAltColors(
            fp.map((id) => COLOR_DICT[id], fp.compact(colorSet.data)),
            colorSetId,
          )
        },
        defaultValue: (serie, weltingType) => {
          const colorSetId = weltingType.props.colorSetIds[serie.id]
          if (!colorSetId) {
            return PROP_DEF_DICT.colors_welting_color.defaultValueId
          }
          const colorSet = COLOR_SET_DICT[colorSetId]
          return (
            (colorSet && colorSet.defaultValueId) ||
            PROP_DEF_DICT.colors_welting_color.defaultValueId
          )
        },
      }),
    },

    lace: {
      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.colors_lace_color),
      }),

      length: Select({
        ...boilerplate(PROP_DEF_DICT.colors_lace_length),
        dependencies: ['calc.serie'],
        autoUnavailable: true,
        options: LACE_LENGTHS,
        isAvailable: isAvailableBySerie(PROP_DEF_DICT.colors_lace_length),
      }),
    },

    stitching: {
      web: Select({
        ...boilerplateColor(PROP_DEF_DICT.colors_stitching_web),
      }),

      glove: Select({
        ...boilerplateColor(PROP_DEF_DICT.colors_stitching_glove),
      }),
    },
  },

  embroidery: {
    patch: Select({
      ...boilerplateColor(PROP_DEF_DICT.embroidery_patch),
    }),

    color: Select({
      ...boilerplateColor(PROP_DEF_DICT.embroidery_color),
    }),

    name: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.embroidery_name_text),
        dependencies: ['calc.serie'],
        isAvailable: isAvailableBySerie(PROP_DEF_DICT.embroidery_name_text),
        maxLength: 15,
        pattern: /^[-a-zA-Z0-9'.# ]+$/,
      }),

      font: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_name_font),
        dependencies: ['embroidery.name.text'],
        isAvailable: (text) => !!text,
        autoUnavailable: true,
        options: FONTS,
      }),

      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.embroidery_name_color),
        dependencies: ['calc.serie', 'embroidery.name.text'],
        isAvailable: (_, text) => !!text,
      }),
    },

    number: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.embroidery_number_text),
        dependencies: ['calc.serie', 'calc.gloveAsset'],
        isAvailable: (serie, gloveAsset) => {
          const { shapeId } = gloveAsset.props
          return (
            isAvailableBySerie(PROP_DEF_DICT.embroidery_number_text)(serie) &&
            shapeId !== 'catcher' &&
            shapeId !== 'firstBase'
          )
        },
        maxLength: 2,
        pattern: /^[0-9]+$/,
        inputType: 'tel',
      }),

      font: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_number_font),
        dependencies: ['embroidery.number.text'],
        isAvailable: (text) => !!text,
        autoUnavailable: true,
        options: FONTS,
      }),

      ringColor: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_number_ringColor),
        dependencies: ['embroidery.number.text'],
        isAvailable: (text) => !!text,
        autoUnavailable: true,
        options: NUMBER_RING_COLORS,
      }),

      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.embroidery_number_color),
        dependencies: ['calc.serie', 'embroidery.number.text'],
        isAvailable: (_, text) => !!text,
      }),
    },

    liner: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.embroidery_liner_text),
        dependencies: ['calc.serie'],
        isAvailable: isAvailableBySerie(PROP_DEF_DICT.embroidery_liner_text),
        maxLength: 15,
        pattern: /^[a-zA-Z0-9 ]+$/,
      }),

      font: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_liner_font),
        dependencies: ['embroidery.liner.text'],
        isAvailable: (text) => !!text,
        autoUnavailable: true,
        options: FONTS,
      }),

      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.embroidery_liner_color),
        dependencies: ['calc.serie', 'embroidery.liner.text'],
        isAvailable: (_, text) => !!text,
      }),
    },

    pinky: {
      type: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_pinky_type),
        dependencies: ['calc.serie'],
        autoUnavailable: true,
        visibleOptions: getOptionsBySerie(PROP_DEF_DICT.embroidery_pinky_type),
        options: PINKY_TYPES,
        isRequired: (serie) => serie.props.baseId === 'proSelect',
      }),

      flag: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_pinky_flag),
        dependencies: ['embroidery.pinky.type', 'calc.serie'],
        isAvailable: (pinkyType) => !!(pinkyType && pinkyType.id === 'flag'),
        autoUnavailable: true,
        options: FLAGS,
        isRequired: (_, serie) => serie.props.baseId !== 'proSelect',
      }),

      ribbon: Select({
        ...boilerplateColor(PROP_DEF_DICT.embroidery_pinky_ribbon),
        dependencies: ['calc.serie', 'embroidery.pinky.type'],
        isAvailable: (_, pinkyType) =>
          !!(pinkyType && pinkyType.id === 'ribbon'),
      }),
    },

    logo: {
      previewFile: FileUpload({
        ...boilerplate(PROP_DEF_DICT.embroidery_logo_previewFile),
        dependencies: ['calc.serie', 'embroidery.number.text'],
        isAvailable: (serie, text) =>
          isAvailableBySerie(PROP_DEF_DICT.embroidery_logo_previewFile)(
            serie,
          ) && !text,
      }),
      factoryFile: FileUpload({
        ...boilerplate(PROP_DEF_DICT.embroidery_logo_factoryFile),
        dependencies: ['embroidery.logo.previewFile'],
        isAvailable: (previewFile) => previewFile && previewFile.id,
      }),
    },

    bag: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.embroidery_bag_text),
        dependencies: ['calc.serie'],
        isAvailable: isAvailableBySerie(PROP_DEF_DICT.embroidery_bag_text),
        maxLength: 15,
        pattern: /^[a-zA-Z0-9 #]+$/,
      }),

      font: Select({
        ...boilerplate(PROP_DEF_DICT.embroidery_bag_font),
        dependencies: ['embroidery.bag.text'],
        isAvailable: (text) => !!text,
        autoUnavailable: true,
        options: FONTS,
      }),

      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.embroidery_bag_color),
        dependencies: ['calc.serie', 'embroidery.bag.text'],
        isAvailable: (_, text) => !!text,
      }),
    },
  },

  options: {
    stamp: Select({
      ...boilerplate(PROP_DEF_DICT.options_stamp),
      dependencies: ['calc.serie'],
      isAvailable: isAvailableBySerie(PROP_DEF_DICT.options_stamp),
      autoUnavailable: true,
      options: STAMPS,
    }),

    softness: Select({
      ...boilerplate(PROP_DEF_DICT.options_softness),
      dependencies: ['calc.serie'],
      isAvailable: isAvailableBySerie(PROP_DEF_DICT.options_softness),
      autoUnavailable: true,
      options: SOFTNESSES,
    }),

    palmLinerMaterial: Select({
      ...boilerplate(PROP_DEF_DICT.options_palmLinerMaterial),
      dependencies: ['calc.serie'],
      isAvailable: isAvailableBySerie(PROP_DEF_DICT.options_palmLinerMaterial),
      autoUnavailable: true,
      options: PALM_LINER_MATERIALS,
    }),

    gripThumb: Select({
      ...boilerplate(PROP_DEF_DICT.options_gripThumb),
      dependencies: ['calc.serie'],
      isAvailable: isAvailableBySerie(PROP_DEF_DICT.options_gripThumb),
      autoUnavailable: true,
      options: TOGGLES,
    }),

    finger: {
      type: Select({
        ...boilerplate(PROP_DEF_DICT.options_finger_type),
        dependencies: ['calc.serie', 'calc.gloveAsset'],
        isAvailable: (serie, gloveAsset) => {
          const { shapeId } = gloveAsset.props
          return (
            isAvailableBySerie(PROP_DEF_DICT.options_finger_type)(serie) &&
            shapeId !== 'catcher' &&
            shapeId !== 'firstBase'
          )
        },
        autoUnavailable: true,
        options: FINGER_TYPES,
      }),

      color: Select({
        ...boilerplateColor(PROP_DEF_DICT.options_finger_color),
        dependencies: ['calc.serie', 'options.finger.type'],
        isAvailable: (_, fingerType) => !!fingerType,
      }),

      location: Select({
        ...boilerplate(PROP_DEF_DICT.options_finger_location),
        dependencies: ['options.finger.type'],
        isAvailable: (fingerType) => !!fingerType,
        autoUnavailable: true,
        options: FINGER_LOCATIONS,
      }),
    },
  },

  details: {
    recipeName: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.details_recipeName_text),
        dependencies: ['calc.serie'],
        isAvailable: isAvailableBySerie(PROP_DEF_DICT.details_recipeName_text),
        maxLength: 30,
      }),
    },
  },
}

const controlTree = createControlTree(controls)

export default controlTree
