import React from 'react'

import { cn, mods, states } from '~p/client/components/utils'

import './Tile.css'

function Tile(props) {
  const {
    children,
    onClick,
    classMods = [],
    classStates,
    props: colorProps,
    dataLabel,
    title,
  } = props
  return (
    <div
      onClick={onClick}
      className={cn(['tile', mods(classMods), states(classStates)])}
      style={{
        backgroundColor:
          colorProps && colorProps.hex ? colorProps.hex : undefined,
      }}
      data-label={dataLabel}
      title={title}
    >
      <div className={cn(['tile-inner'])}>{children}</div>
    </div>
  )
}

export default Tile
