import React from 'react'

import './Overlay.css'

function Overlay(props) {
  return (
    <div className="overlay">
      <div className="overlay-inner">{props.children}</div>
    </div>
  )
}

export default Overlay
