import * as _ from '@technically/lodash'
import React from 'react'

const TIPPING_ASPECT_RATIO = 0.75

export default class PreviewImages extends React.Component {
  state = {
    scale: 1,
    ratio: 1,
  }

  componentDidMount() {
    this.updateDimensions()
    this.updateDimensionsDeferred()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate() {
    this.updateDimensions()
    this.updateDimensionsDeferred()
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
    window.removeEventListener('resize', this.updateDimensions)
  }

  // for CSS animations and such
  timeout = null
  updateDimensionsDeferred = () => {
    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(this.updateDimensions, 300)
  }

  updateDimensions = () => {
    if (!this.outer || !this.inner) return
    const nextState = {
      scale: Math.min(
        this.outer.clientWidth / this.inner.clientWidth,
        this.outer.clientHeight / this.inner.clientHeight,
      ),
      ratio: this.outer.clientWidth / this.outer.clientHeight,
    }
    if (
      nextState.scale === this.state.scale &&
      nextState.ratio === this.state.ratio
    )
      return
    this.setState(nextState)
  }

  render() {
    const previews = _.toPairs(this.props.previewUrls)
    if (_.isEmpty(previews)) {
      return <div />
    }
    const previewOne = previews[0]
    const previewsRest = _.slice(previews, 1)

    const isOneLargeMode =
      (!this.props.mode && this.state.ratio > TIPPING_ASPECT_RATIO) ||
      this.props.mode === 'oneLarge'
    const isVerticalMode = this.props.mode === 'vertical' || !isOneLargeMode

    let images
    if (isOneLargeMode) {
      images = [
        <img
          className="preview-image-large"
          key="a"
          src={previewOne[1]}
          onLoad={this.updateDimensions}
        />,
        <div className="preview-images-small" key="b">
          {_.map(previewsRest, ([angle, url]) => (
            <img
              className="preview-image-small"
              key={angle}
              src={url}
              onLoad={this.updateDimensions}
            />
          ))}
        </div>,
      ]
    } else if (isVerticalMode) {
      images = _.map(previews, ([angle, url]) => (
        <img
          className="preview-image-vertical"
          key={angle}
          src={url}
          onLoad={this.updateDimensions}
        />
      ))
    }

    return (
      <div
        className="preview-images"
        ref={(x) => {
          this.outer = x
        }}
      >
        <div
          className="preview-images-inner"
          ref={(x) => {
            this.inner = x
          }}
          style={{ transform: `scale(${this.state.scale})` }}
        >
          {images}
        </div>
      </div>
    )
  }
}
