import Bluebird from 'bluebird'
import * as _ from '@technically/lodash'

import http from '../../client/http'

class Loader {
  images = {}
  imageData = {}

  _loadImage = (src) =>
    new Promise((resolve, reject) => {
      const cachedImage = this.images[src]

      if (cachedImage) {
        resolve(cachedImage)
        return
      }

      http.get(src, { responseType: 'blob' }).then((response) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(response.data)

        fileReader.addEventListener('loadend', () => {
          const image = new Image()

          image.setAttribute('src', fileReader.result)

          image.addEventListener('load', () => {
            this.images[src] = image

            resolve(image)
          })

          image.addEventListener('error', reject)
        })

        fileReader.addEventListener('error', reject)
      })
    })

  load = (layers) =>
    Bluebird.map(layers, (layer) => {
      if (typeof layer.src === 'string') {
        return this._loadImage(layer.src).then((image) => ({
          layer,
          image,
        }))
      }
      // Promise.map doesn't work with objects. Be careful when refactoring this!
      return Bluebird.props(_.mapValues(layer.src, this._loadImage)).then(
        (images) => ({
          layer,
          image: images,
        }),
      )
    })

  reset() {
    this.images = {}
  }
}

export default Loader
