import * as _ from '@technically/lodash'
import React from 'react'

import ErrorAlert from '~p/client/containers/ErrorAlert'
import ErrorBoundary from '~p/client/components/ErrorBoundary'
import { cn, mods } from '~p/client/components/utils'

import Overlay from '~mizuno/client/components/atoms/Overlay'
import LoadingIndicator from '~mizuno/client/components/atoms/LoadingIndicator'

const Layout = (props) => {
  const {
    children,
    isProductScreen,
    isFinalizeScreen,
    isAppLoading,
    isPreviewMinimized,
  } = props

  return (
    <div>
      <ErrorBoundary>
        <ErrorAlert />
      </ErrorBoundary>

      <div
        className={cn(
          _.compact([
            'layout',
            window.serverConfig.fixedWidth ? 'mod-fixedWidth' : null,
            isProductScreen ? 'is-productSelect' : null,
            isFinalizeScreen ? 'is-finalize' : null,
            isAppLoading ? 'is-overlay' : null,
            !isPreviewMinimized ? 'is-preview-on' : null,
          ]),
        )}
      >
        <ErrorBoundary>
          <Overlay>
            {isAppLoading ?
              <LoadingIndicator />
            : null}
          </Overlay>
        </ErrorBoundary>

        <ErrorBoundary>
          <div
            className={cn([
              'layout-body',
              window.serverConfig.headerUrl ? mods(['with-header']) : null,
            ])}
          >
            {children}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default Layout
