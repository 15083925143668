import * as _ from '@technically/lodash'

import { garmentSizes } from '~c/common/meta'

const getRosterEntries = (nodes) => {
  const rosterIds = _.uniq(
    Object.keys(nodes)
      .filter((x) => x.startsWith('details.roster.'))
      .map((x) => x.split('.')[2]),
  )

  return _.map(rosterIds, (rosterId) => ({
    id: rosterId,
    ...garmentSizes.reduce(
      (r, { path }) => ({
        ...r,
        [path]: nodes[`details.roster.${rosterId}.${path}`].value,
      }),
      {},
    ),
    quantity: nodes[`details.roster.${rosterId}.quantity`].value,
    number: nodes[`details.roster.${rosterId}.number`].value,
    name: nodes[`details.roster.${rosterId}.name`].value,
  }))
}

export const getRosterDetailsValue = (nodes) => {
  const rosterEntries = getRosterEntries(nodes)
  const entries = _.map(rosterEntries, (row) => [
    ...garmentSizes.reduce(
      (r, garment) =>
        row[garment.path] ? [...r, garment.sheet[row[garment.path]].name] : r,
      [],
    ),
    row.quantity,
    row.number || '-',
    row.name || '-',
  ])
  entries.unshift([
    ...garmentSizes.reduce(
      (r, garment) =>
        rosterEntries[0][garment.path] ? [...r, garment.title] : r,
      [],
    ),
    'Quantity',
    'Number',
    'Name',
  ])
  return entries
}

export const getSizeSummaryValue = (nodes) => {
  const rows = [['Size', 'Quantity']]
  const rosterEntries = getRosterEntries(nodes)

  const amountOfSizes = garmentSizes.reduce(
    (r, x) => (rosterEntries[0][x.path] ? r + 1 : r),
    0,
  )

  for (const garment of garmentSizes) {
    // Safe to assume that if the size is missing for one of the garments then
    // it means it wasn't enabled, otherwise it wouldn't be valid.
    if (!rosterEntries[0][garment.path]) continue

    let garmentRows = Object.entries(
      rosterEntries.reduce((r, entry) => {
        const sizeName = garment.sheet[entry[garment.path]].name
        return { ...r, [sizeName]: (r[sizeName] ?? 0) + +entry.quantity }
      }, {}),
    )

    if (garment.sheetArray) {
      garmentRows = _.sortBy(garmentRows, ([sizeId]) =>
        _.findIndex(garment.sheetArray, { id: sizeId }),
      )
    }

    rows.push(
      ...garmentRows.map(([x, ...y]) => [
        amountOfSizes > 1 ? `${garment.title} ${x}` : x,
        ...y,
      ]),
    )
  }

  rows.push([
    amountOfSizes > 1 ? 'Total sets' : 'Total',
    rosterEntries.reduce((r, x) => r + +x.quantity, 0),
  ])

  return rows
}
