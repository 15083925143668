import React from 'react'

import { cn } from '~p/client/components/utils'

import './BgCover.css'

const BgCover = ({ url, solid }) => (
  <div
    style={{
      backgroundImage: url ? `url(${url})` : null,
      backgroundColor: solid,
    }}
    className={cn(['bgCover'])}
  />
)

export default BgCover
