import './promise'
import '~c/client/index'
import './configureSentry'

import { recipeIdSelector } from './common/selectors'
// @ts-expect-error because ~c
import store from '~c/client/store'
import { sendToFactory } from './factory'
import http from './http'

function getRecipeId() {
  const recipeId = recipeIdSelector(store.getState())
  if (recipeId) return recipeId
  console.error('No recipeId (save and run again)')
}

window['sendToFactory'] = async ({
  orderId,
  debug = true,
}: {
  orderId?: string | number
  debug?: boolean
} = {}) => {
  const recipeId = getRecipeId()
  if (recipeId === undefined) return

  const { result, error } = await sendToFactory(
    recipeId,
    orderId !== undefined ? orderId.toString() : 'TEST_ORDER_ID',
    debug,
  )

  if (error) console.error(error)
  else console.log(result)
}

window['debugBakeryOrder'] = async () => {
  const recipeId = getRecipeId()
  if (recipeId === undefined) return
  console.log((await http.get(`/api/debug-bakery-order/${recipeId}`)).data)
}
