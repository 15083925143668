import React from 'react'

import { cn } from '~p/client/components/utils'

import Button from '../atoms/Button'
import Icon from '../atoms/Icon'

export default class CopyUrl extends React.Component {
  state = { copied: false }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  onClick = () => {
    this.props.copyLinkToClipboard()
    this.setState({ copied: true }, this.resetState)
  }

  resetState = () => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({ copied: false })
      this.timeout = null
    }, 2000)
  }

  timeout = null

  render() {
    if (this.props.justIcon) {
      return (
        <div
          className={cn([this.state.copied ? 'copyUrl-copied' : ''])}
          data-gtm="url-copy"
          onClick={this.onClick}
        >
          <Icon name="copyPadded" />
        </div>
      )
    }

    return (
      <Button
        classMods={['black', 'largeFont', 'hasIcon']}
        onClick={this.onClick}
        data-gtm="url-copy"
      >
        <Icon name="copy" /> {this.state.copied ? 'Link Copied' : 'Copy link'}
      </Button>
    )
  }
}
