import { bind, each, isString } from '@technically/lodash'
import fp from 'lodash/fp.js'

const mapValuesWithKey = fp.mapValues.convert({ cap: false })

const mapWithKey = fp.map.convert({ cap: false })

const transformWithKey = fp.transform.convert({ cap: false })

/**
 * Creates a new object that's difference between a and b objects.
 */
const diffObject = (a, b) => {
  const reducer = (changes, key) =>
    key in a && fp.isEqual(a[key], b[key]) ?
      changes
    : fp.set([key], b[key])(changes)
  return fp.reduce(reducer, {}, fp.keys(b))
}

const tryAppendFormInput = (form, value, name) => {
  if (value == null) {
    return
  }
  const valueString = isString(value) ? value : JSON.stringify(value, null, 2)

  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', name)
  input.setAttribute('value', valueString)
  form.appendChild(input)
}

const createForm = (action, method, data) => {
  const form = document.createElement('form')
  document.body.appendChild(form)
  form.target = '_parent'
  form.action = action
  form.method = method

  each(data, bind(tryAppendFormInput, null, form))

  return form
}

export {
  mapValuesWithKey,
  mapWithKey,
  transformWithKey,
  diffObject,
  createForm,
}
