import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import Subline from '../atoms/Subline'

import './TextareaInput.css'

function TextareaInput(props) {
  const { subline = '', value = '', classMods = [], onChange } = props
  return (
    <div className={cn(['textareaInput', mods(classMods)])}>
      <textarea
        className={cn(['textareaInput-input'])}
        type="text"
        value={value}
        onChange={onChange}
      />
      <Subline>{subline}</Subline>
    </div>
  )
}

export default TextareaInput
