import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

import { createCanvas, render } from '~p/client/renderComposite/canvas'
import Loader from '~p/client/renderComposite/Loader'

import * as renderComposite from '../renderComposite'
import controlTree from './controlTree'
import { SERIE_DICT, GLOVES, WEBS } from '../common/sheets'

// USAGE
// 1. Open the customizer
// 2. In console run `iconGenerators.generateGloves()` or `iconGenerators.generateWebs()`
// 3. Wait until a zip file is downloaded
// 4. Use the new files

const SPORT_ID = 'baseball'
// const SPORT_ID = 'softball'
const VIEW = 'web'
const HEIGHT = 512

const shiftCanvas = (canvas, opts) => {
  const dx = opts.dx || 0
  const dy = opts.dy || 0

  const w = canvas.width
  const h = canvas.height

  const ctx = canvas.getContext('2d')

  const imageData = ctx.getImageData(0, 0, w, h)
  ctx.clearRect(0, 0, w, h)
  ctx.putImageData(imageData, dx, dy)
}

// const openBlobsAsImages = blobs => {
//   _.forEach(blobs, ({ blob, id }) => {
//     const image = new Image()
//     image.src = URL.createObjectURL(blob)

//     const w = window.open('')
//     w.addEventListener('load', () => {
//       w.document.write(image.outerHTML)
//       w.document.title = id
//     })
//   })
// }

const saveBlobsAsZip = async (blobs) => {
  const zip = new JSZip()
  const folder = zip.folder('icons')

  _.forEach(blobs, ({ blob, id }) => {
    folder.file(`${id}.png`, blob)
  })

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'icons.zip')
}

const generateGloves = async () => {
  const gloves = fp.filter(
    (glove) =>
      SERIE_DICT[glove.props.serieId].props.sportId === SPORT_ID &&
      !glove.props.isHidden,
    GLOVES,
  )

  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  const loader = new Loader()

  const queue = []

  _.forEach(gloves, (glove) => {
    const expandedRecipeNested = controlTree.getExpandedRecipeNested(
      fp.set(['controlTree', 'values', 'product.glove'], glove.id)(state),
    )

    const size = {
      height: HEIGHT,
      width: Math.round(
        HEIGHT /
          renderComposite.getAssetAspectRatio(VIEW, expandedRecipeNested),
      ),
    }

    const layers = renderComposite.getLayers(expandedRecipeNested, size, VIEW)

    queue.push(
      new Promise(async (resolve) => {
        const canvas = createCanvas(size).canvas

        const assets = await loader.load(layers)

        render(canvas, assets, size, VIEW)

        canvas.toBlob((blob) => {
          resolve({ blob, id: glove.id })
        })
      }),
    )
  })

  const blobs = await Promise.all(queue)

  saveBlobsAsZip(blobs)
}

const generateWebs = async () => {
  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  const loader = new Loader()

  const queue = []

  _.forEach(WEBS, (web) => {
    const glove = fp.find(
      (glove) =>
        SERIE_DICT[glove.props.serieId].props.sportId === SPORT_ID &&
        !glove.props.isHidden &&
        glove.limitations.webIds[web.id],
      GLOVES,
    )

    if (!glove) {
      console.warn('No glove for web', web.id)
      return
    }

    const expandedRecipeNested = controlTree.getExpandedRecipeNested(
      fp.pipe(
        fp.set(['controlTree', 'values', 'product.glove'], glove.id),
        fp.set(['controlTree', 'values', 'product.web'], web.id),
      )(state),
    )

    const size = {
      height: HEIGHT,
      width: Math.round(
        HEIGHT /
          renderComposite.getAssetAspectRatio(VIEW, expandedRecipeNested),
      ),
    }

    const isWebSrc = (src) => _.includes(src, '/web-')
    const isWebLayer = (layer) =>
      typeof layer.src === 'string' ?
        isWebSrc(layer.src)
      : _.every(layer.src, isWebSrc)

    const layers = _.filter(
      renderComposite.getLayers(expandedRecipeNested, size, VIEW),
      isWebLayer,
    )

    queue.push(
      new Promise(async (resolve) => {
        const canvas = createCanvas(size).canvas

        const assets = await loader.load(layers)

        render(canvas, assets, size, VIEW)

        const defaultOffset = {
          dx: -60,
          dy: 60,
        }
        const offsets =
          SPORT_ID === 'baseball' ?
            {
              static: { dx: 0 },
            }
          : {
              CM: { dx: -40, dy: 120 },
              D1: { dx: -120 },
              D8T: { dx: -120 },
              D9: { dx: -120 },
              D10: { dx: -120 },
              IC: { dx: -120 },
              PY: { dx: -120 },
              SH2: { dx: -120 },
              TA4: { dx: -120 },
              TAS2: { dx: -120 },
              TR: { dx: -120 },
            }

        const { dx, dy } = { ...defaultOffset, ...offsets[web.id] }

        // Center web.
        shiftCanvas(canvas, { dx, dy })

        canvas.toBlob((blob) => {
          resolve({ blob, id: web.id })
        })
      }),
    )
  })

  const blobs = await Promise.all(queue)

  // openBlobsAsImages(blobs)
  saveBlobsAsZip(blobs)
}

export { generateGloves, generateWebs }
