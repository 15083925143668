// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { WebRow } from '../typings';

const data = [
  {
    "id": "static",
    "name": "Static",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "CM",
    "name": "CM",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "CR2",
    "name": "Cross 2",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "CR8",
    "name": "Cross 8",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "CR9",
    "name": "Cross 9",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "CRSH1",
    "name": "Cross Shock 1",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "CRSH2",
    "name": "Cross Shock 2",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "D1",
    "name": "Deep 1",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "D10",
    "name": "Deep 10",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "D2",
    "name": "Deep 2",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "D3",
    "name": "Deep 3",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "D3C",
    "name": "Deep 3C",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "D3I",
    "name": "Deep 3-I",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "D3S",
    "name": "Deep 3S",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "D8T",
    "name": "Deep 8T",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "D9",
    "name": "Deep 9",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "DH",
    "name": "Dual H",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "H",
    "name": "H-shape",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "IC",
    "name": "Ichiro",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "PY",
    "name": "Pyramid",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "SH1",
    "name": "Shock 1",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "SH2",
    "name": "Shock 2",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "SH21",
    "name": "Shock 21",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "SH24",
    "name": "Shock 24",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "SH4",
    "name": "Shock 4",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "SH8",
    "name": "Shock 8",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "SHY2",
    "name": "Shock Y2",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "T",
    "name": "T-shape",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "TA",
    "name": "Tartan",
    "asset": {
      "hasDetails": true,
      "hasLogo": true
    }
  },
  {
    "id": "TA0",
    "name": "Tartan 0",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "TA26",
    "name": "Tartan 26",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "TA4",
    "name": "Tartan 4",
    "asset": {
      "hasDetails": true,
      "hasLogo": true
    }
  },
  {
    "id": "TAD",
    "name": "Tartan D",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "TAMTF",
    "name": "Tartan MTF",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "TAS",
    "name": "Tartan Shock",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "TAS2",
    "name": "Tartan Shock 2",
    "asset": {
      "hasDetails": false,
      "hasLogo": true
    }
  },
  {
    "id": "TAST",
    "name": "Tartan ST",
    "asset": {
      "hasDetails": false,
      "hasLogo": false
    }
  },
  {
    "id": "TR",
    "name": "Trident",
    "asset": {
      "hasDetails": true,
      "hasLogo": true
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<WebRow[]>> = data;

export type Web = typeof typed[number];

export const WEB_INDEX_KEY = "id";
export type WebIndexKey = "id";
export type WebId = Web["id"];

let i = 0;
export const WEB_DICT = {
  "static": typed[i++],
  "CM": typed[i++],
  "CR2": typed[i++],
  "CR8": typed[i++],
  "CR9": typed[i++],
  "CRSH1": typed[i++],
  "CRSH2": typed[i++],
  "D1": typed[i++],
  "D10": typed[i++],
  "D2": typed[i++],
  "D3": typed[i++],
  "D3C": typed[i++],
  "D3I": typed[i++],
  "D3S": typed[i++],
  "D8T": typed[i++],
  "D9": typed[i++],
  "DH": typed[i++],
  "H": typed[i++],
  "IC": typed[i++],
  "PY": typed[i++],
  "SH1": typed[i++],
  "SH2": typed[i++],
  "SH21": typed[i++],
  "SH24": typed[i++],
  "SH4": typed[i++],
  "SH8": typed[i++],
  "SHY2": typed[i++],
  "T": typed[i++],
  "TA": typed[i++],
  "TA0": typed[i++],
  "TA26": typed[i++],
  "TA4": typed[i++],
  "TAD": typed[i++],
  "TAMTF": typed[i++],
  "TAS": typed[i++],
  "TAS2": typed[i++],
  "TAST": typed[i++],
  "TR": typed[i++]
} as const;

export { typed as WEBS };
