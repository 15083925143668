import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './Subline.css'

function Subline(props) {
  const { classMods = [], children } = props
  return <div className={cn(['subline', mods(classMods)])}>{children}</div>
}

export default Subline
