import React from 'react'

import FoldableBlock from '~mizuno/client/components/organisms/FoldableBlock'
import { toggleSubmenu } from '../../../../platform/client/common/actions'
import { useAppDispatch } from '../../../../platform/client/configureStore'
import controlTree from '~c/client/controlTree'

const ControlMenu = (props) => {
  const {
    path,
    link,
    component,
    primaryLabel,
    secondaryLabel,
    resolvedNode,
    isOpen,
  } = props
  const { label, optionName, value, multiple } = resolvedNode || {}

  const dispatch = useAppDispatch()

  let valueLabel = secondaryLabel
  if (valueLabel === undefined) {
    if (value && value.filename) {
      valueLabel = value.filename
    } else if (optionName) {
      valueLabel = optionName
    } else if (value && !multiple) {
      valueLabel = value
    } else {
      valueLabel = 'None'
    }
  }

  if (!path && !resolvedNode) {
    valueLabel = null
  }

  return (
    <FoldableBlock
      state={isOpen ? 'on' : 'off'}
      primaryLabel={primaryLabel || `${label}:`}
      secondaryLabel={valueLabel}
      onClick={() => dispatch(toggleSubmenu(controlTree, path || link))}
    >
      {component}
    </FoldableBlock>
  )
}

export default ControlMenu
