import React from 'react'
import { Provider } from 'react-redux'

export default function Application({ children, store }) {
  return (
    <Provider store={store}>
      <div>{children}</div>
    </Provider>
  )
}
