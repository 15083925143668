// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorSetRow } from '../typings';

const data = [
  {
    "id": "legacyBinding",
    "name": "Legacy - Binding",
    "defaultValueId": "cream",
    "data": [
      "black",
      "cork",
      "coffee",
      "cream",
      "white",
      "throwback"
    ]
  },
  {
    "id": "legacyStandard",
    "name": "Legacy - Standard",
    "defaultValueId": "cream",
    "data": [
      "black",
      "cork",
      "coffee",
      "cream",
      "throwback"
    ]
  },
  {
    "id": "legacyLace",
    "name": "Legacy - Lace",
    "defaultValueId": "silverWhite",
    "data": [
      "black",
      "forest",
      "royal",
      "navy",
      "corkNatural",
      "corkBrown",
      "coffee",
      "red",
      "orange",
      "cardinal",
      "purple",
      "white",
      "throwback",
      "silverWhite"
    ]
  },
  {
    "id": "legacyExtended",
    "name": "Legacy - Extended",
    "defaultValueId": "cream",
    "data": [
      "black",
      "forest",
      "royal",
      "navy",
      "cork",
      "coffee",
      "red",
      "cream",
      "orange",
      "cardinal",
      "purple",
      "throwback"
    ]
  },
  {
    "id": "legacyPersonalize",
    "name": "Legacy - Personalize",
    "defaultValueId": "cream",
    "data": [
      "black",
      "forest",
      "royal",
      "navy",
      "coffee",
      "red",
      "cream",
      "orange",
      "cardinal",
      "purple",
      "white",
      "gold",
      "silver",
      "pink",
      "yellow",
      "athleticGold"
    ]
  },
  {
    "id": "mizunoProStandard",
    "name": "Mizuno Pro - Standard",
    "defaultValueId": "white",
    "data": [
      "silver",
      "black",
      "forest",
      "royal",
      "navy",
      "corkNatural",
      "sOrange",
      "orange",
      "red",
      "roseBrown",
      "plumBrown",
      "chestnut",
      "deepRoyal",
      "coffee",
      "cardinal",
      "smokeGray",
      "mizunoProTan",
      "burntOrange",
      "kellyGreen",
      "lightBlue",
      "white",
      "yellow"
    ]
  },
  {
    "id": "mizunoProEmbroidery",
    "name": "Mizuno Pro - Embroidery",
    "defaultValueId": "white",
    "data": [
      "silver",
      "black",
      "forest",
      "royal",
      "navy",
      "lime",
      "corkNatural",
      "sOrange",
      "orange",
      "red",
      "roseBrown",
      "plumBrown",
      "chestnut",
      "purple",
      "coffee",
      "cardinal",
      "smokeGray",
      "tan",
      "burntOrange",
      "blackCherry",
      "kellyGreen",
      "columbiaBlue",
      "white"
    ]
  },
  {
    "id": "mizunoProExtended",
    "name": "Mizuno Pro - Extended",
    "defaultValueId": "white",
    "data": [
      "silver",
      "black",
      "forest",
      "royal",
      "navy",
      "lime",
      "corkNatural",
      "sOrange",
      "orange",
      "red",
      "roseBrown",
      "plumBrown",
      "chestnut",
      "purple",
      "coffee",
      "cardinal",
      "smokeGray",
      "tan",
      "burntOrange",
      "blackCherry",
      "kellyGreen",
      "columbiaBlue",
      "pink",
      "white"
    ]
  },
  {
    "id": "mizunoProRollWelting",
    "name": "Mizuno Pro - Roll Welting",
    "defaultValueId": "white",
    "data": [
      "silver",
      "black",
      "forest",
      "royal",
      "navy",
      "corkNatural",
      "sOrange",
      "orange",
      "red",
      "roseBrown",
      "plumBrown",
      "chestnut",
      "purple",
      "coffee",
      "cardinal",
      "smokeGray",
      "mizunoProTan",
      "burntOrange",
      "kellyGreen",
      "lightBlue",
      "metallicGold",
      "metallicSilver",
      "pink",
      "white",
      "yellow"
    ]
  },
  {
    "id": "mizunoProSplitWelting",
    "name": "Mizuno Pro - Split Welting",
    "defaultValueId": "white",
    "data": [
      "silver",
      "black",
      "orange",
      "brown",
      "corkNatural",
      "white",
      "sOrange"
    ]
  },
  {
    "id": "mizunoProBinding",
    "name": "Mizuno Pro - Binging",
    "defaultValueId": "white",
    "data": [
      "silver",
      "black",
      "forest",
      "royal",
      "navy",
      "lime",
      "corkNatural",
      "sOrange",
      "orange",
      "red",
      "roseBrown",
      "plumBrown",
      "chestnut",
      "purple",
      "coffee",
      "cardinal",
      "smokeGray",
      "tan",
      "burntOrange",
      "blackCherry",
      "kellyGreen",
      "columbiaBlue",
      "metallicGold",
      "metallicSilver",
      "pink",
      "white"
    ]
  },
  {
    "id": "mizunoProLace",
    "name": "Mizuno Pro - Lace",
    "defaultValueId": "silverWhite",
    "data": [
      "silverWhite",
      "black",
      "forest",
      "royal",
      "navy",
      "corkNatural",
      "orange",
      "red",
      "roseBrownCardinal",
      "chestnut",
      "purple",
      "coffee",
      "smokeGray",
      "burntOrange",
      "pink",
      "yellow"
    ]
  },
  {
    "id": "mizunoProMeshBack",
    "name": "Mizuno Pro - Mesh Back",
    "defaultValueId": "white",
    "data": [
      "white",
      "black",
      "orange",
      "royal",
      "navy",
      "gold",
      "corkNatural",
      "red"
    ]
  },
  {
    "id": "proSelectBase",
    "name": "Pro Select - Base",
    "defaultValueId": "tan",
    "data": [
      "midBrown",
      "black",
      "tan",
      "red",
      "navy",
      "royal",
      "white",
      "orange",
      "maroon",
      "forest",
      "naturalGold",
      "charcoalGray"
    ]
  },
  {
    "id": "proSelectWeb",
    "name": "Pro Select - Web",
    "defaultValueId": "tan",
    "data": [
      "midBrown",
      "black",
      "royal",
      "navy",
      "orange",
      "red",
      "tan",
      "forest",
      "white",
      "maroon",
      "naturalGold",
      "charcoalGray"
    ]
  },
  {
    "id": "proSelectWeltingBinding",
    "name": "Pro Select - Welting and Binding",
    "defaultValueId": "tan",
    "data": [
      "midBrown",
      "black",
      "royal",
      "navy",
      "orange",
      "red",
      "tan",
      "forest",
      "metallicSilver",
      "metallicGold",
      "white",
      "metallicRed",
      "metallicBlue"
    ]
  },
  {
    "id": "proSelectLace",
    "name": "Pro Select - Lace",
    "defaultValueId": "corkNatural",
    "data": [
      "black",
      "royal",
      "navy",
      "corkNatural",
      "orange",
      "red",
      "forest",
      "white",
      "indianTan"
    ]
  },
  {
    "id": "proSelectEmbroidery",
    "name": "Pro Select - Embroidery",
    "defaultValueId": "cream",
    "data": [
      "black",
      "royal",
      "navy",
      "orange",
      "red",
      "cork",
      "lime",
      "forest",
      "pink",
      "purple",
      "columbiaBlue",
      "charcoalGray",
      "cream",
      "white",
      "roseGold"
    ]
  },
  {
    "id": "proSelectPatch",
    "name": "Pro Select - Patch",
    "defaultValueId": "almond",
    "data": [
      "almond",
      "white",
      "red",
      "navy",
      "royal"
    ]
  },
  {
    "id": "proSelectStitching",
    "name": "Pro Select - Stitching",
    "defaultValueId": "cream",
    "data": [
      "midBrown",
      "black",
      "cream",
      "red",
      "navy",
      "royal",
      "white",
      "naturalGold",
      "charcoalGray"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorSetRow[]>> = data;

export type ColorSet = typeof typed[number];

export const COLOR_SET_INDEX_KEY = "id";
export type ColorSetIndexKey = "id";
export type ColorSetId = ColorSet["id"];

let i = 0;
export const COLOR_SET_DICT = {
  "legacyBinding": typed[i++],
  "legacyStandard": typed[i++],
  "legacyLace": typed[i++],
  "legacyExtended": typed[i++],
  "legacyPersonalize": typed[i++],
  "mizunoProStandard": typed[i++],
  "mizunoProEmbroidery": typed[i++],
  "mizunoProExtended": typed[i++],
  "mizunoProRollWelting": typed[i++],
  "mizunoProSplitWelting": typed[i++],
  "mizunoProBinding": typed[i++],
  "mizunoProLace": typed[i++],
  "mizunoProMeshBack": typed[i++],
  "proSelectBase": typed[i++],
  "proSelectWeb": typed[i++],
  "proSelectWeltingBinding": typed[i++],
  "proSelectLace": typed[i++],
  "proSelectEmbroidery": typed[i++],
  "proSelectPatch": typed[i++],
  "proSelectStitching": typed[i++]
} as const;

export { typed as COLOR_SETS };
