import React from 'react'

import Icon from '../atoms/Icon'

import './BgSvgCover.css'

function BgSvgCover(props) {
  const { svg, svgType } = props
  const svgName =
    svgType ?
      `${svgType.toLowerCase()}-${svg.toLowerCase()}`
    : `${svg.toLowerCase()}`

  return (
    <div className="bgSvgCover">
      <Icon name={svgName} />
    </div>
  )
}

export default BgSvgCover
