import React from 'react'

import TextInput from '~mizuno/client/components/molecules/TextInput'

export default class extends React.Component {
  componentDidMount() {
    this.maybeFocus()
  }

  maybeFocus() {
    if (this.props.isOpen) {
      this.ref.focus()
    }
  }

  ref = null

  render() {
    const { resolvedNode, change } = this.props

    const placeholder =
      this.props.placeholder ?? (resolvedNode ? resolvedNode.defaultValue : '')

    return (
      <TextInput
        inputRef={(ref) => {
          this.ref = ref
        }}
        {...this.props}
        onChange={change}
        onFocus={this.onFocus}
        placeholder={placeholder}
      />
    )
  }
}
