// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CurrencyRow } from '../typings';

const data = [
  {
    "id": "USD",
    "name": "USD",
    "props": {
      "prefix": "$"
    }
  },
  {
    "id": "EUR",
    "name": "EUR",
    "props": {
      "prefix": "€"
    }
  },
  {
    "id": "CAD",
    "name": "CAD",
    "props": {
      "prefix": "$"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CurrencyRow[]>> = data;

export type Currency = typeof typed[number];

export const CURRENCY_INDEX_KEY = "id";
export type CurrencyIndexKey = "id";
export type CurrencyId = Currency["id"];

let i = 0;
export const CURRENCY_DICT = {
  "USD": typed[i++],
  "EUR": typed[i++],
  "CAD": typed[i++]
} as const;

export { typed as CURRENCIES };
