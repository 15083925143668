import fp from 'lodash/fp.js'
import * as _ from '@technically/lodash'

import React from 'react'

import { cn, mods, states } from '~p/client/components/utils'

import HorizontalAlign from '../atoms/HorizontalAlign'
import ColorTile from '../molecules/ColorTile'
import PanelTile from '../molecules/PanelTile'
import PatternTile from '../molecules/PatternTile'
import ImageTile from '../molecules/ImageTile'
import TextTile from '../molecules/TextTile'
import Subline from '../atoms/Subline'

import './TileSet.css'

const types = {
  text: 'TEXT',
  color: 'COLOR',
  panel: 'PANEL',
  pattern: 'PATTERN',
  image: 'IMAGE',
}

const getTileComponent = (type) => {
  const components = {
    TEXT: TextTile,
    COLOR: ColorTile,
    PANEL: PanelTile,
    IMAGE: ImageTile,
    PATTERN: PatternTile,
  }

  return components[type]
}

const getChunkSize = (type) => {
  const sizes = {
    TEXT: Infinity,
    COLOR: 6,
    PANEL: 6,
    IMAGE: Infinity,
    PATTERN: 3,
  }

  return sizes[type]
}

const ghostElementOf = (elementType) => (__, key) =>
  React.createElement(
    elementType,
    { key, onClick: fp.noop, classMods: ['ghost'] },
    '&nbsp;',
  )

const renderEmptyTiles = (type, count) => {
  const diff = getChunkSize(type) - count
  if (diff !== Infinity && diff > 0) {
    return _.range(diff).map(ghostElementOf(getTileComponent(type)))
  }

  return []
}

const setAvailability = (it) =>
  fp.set('isAvailable', fp.isEqual(undefined, fp.get('value', it)), it)

// Currying doesn't work in development mode because createElement is
// monkey-patched by react-hot-loader, hence its arity changes. That's actually
// fixable, by using _.curry(func, forcedArity), but, for some reason, forcing
// arity didn't help either. Oh well.
const renderTile = (x) => (y) => React.createElement(x, y, null)

const renderRow = (type) => (tilesChunk, rowKey) => {
  const tiles = tilesChunk
    .map(setAvailability)
    .map(renderTile(getTileComponent(type)))
    .concat(renderEmptyTiles(type, tilesChunk.length))

  const rowClassMods = getChunkSize(type) === Infinity ? ['wrap'] : []
  return (
    <HorizontalAlign
      key={rowKey}
      classMods={[type.toLowerCase(), ...rowClassMods]}
    >
      {tiles}
    </HorizontalAlign>
  )
}

const renderTiles = (type, tiles) =>
  fp.chunk(getChunkSize(type), tiles).map(renderRow(type))

const renderPrimaryLabel = (primaryLabel) =>
  primaryLabel ?
    <strong className={cn(['tileSet-primaryLabel'])}>{primaryLabel}</strong>
  : null

function TileSet(props) {
  const {
    type,
    onClick,
    classMods = [],
    classStates = [],
    tiles,
    primaryLabel = '',
    subline,
  } = props
  return (
    <div
      className={cn(['tileSet', mods(classMods), states(classStates)])}
      onClick={onClick}
    >
      {renderPrimaryLabel(primaryLabel)}
      {renderTiles(type, tiles)}
      <Subline>{subline}</Subline>
    </div>
  )
}

export default TileSet
export { types }
