import fp from 'lodash/fp.js'
import { handleActions, concat } from 'redux-fp'

const initialState = {
  parentHref: null,
  isPreviewMinimized: true,
  isPreviewIntroduced: false,
  openSections: {},
  vendorShoppingCart: {},
  contactForm: {
    name: '',
    phone: '',
    email: '',
    postalCode: '',
    region: '',
    turingTest: '',
  },
}

const getDefaultState = () => (state) => fp.assign(initialState, state)

const vendorUpdater = handleActions({
  CONFIGURE_VENDOR_SHOPPING_CART: ({ payload }) =>
    fp.set('vendorShoppingCart', payload),
})

const historyUpdater = handleActions({
  NAVIGATED: ({ payload: { loc, matches } }) =>
    fp.set('history', { location: loc, matches }),
  PARENT_NAVIGATED: ({ payload }) => fp.set('parentHref', payload),
})

const uiUpdater = handleActions({
  SET_DEBUG_VIEWER_TAB_ID: ({ payload }) => fp.set('debugViewerTabId', payload),

  SET_LAYOUT_MODE: ({ payload }) => fp.set('layoutMode', payload),

  OPEN_UNHANDLED_ERROR_ALERT: () => fp.set('isErrorAlertOpen', true),
  DISMISS_UNHANDLED_ERROR_ALERT: () => fp.set('isErrorAlertOpen', false),

  TOGGLE_PREVIEW_MINIMIZATION: () => fp.update('isPreviewMinimized', (x) => !x),
  SET_PREVIEW_MINIMIZATION: ({ payload }) =>
    fp.set('isPreviewMinimized', !!payload),
  INTRODUCE_PREVIEW: () => fp.set('isPreviewIntroduced', true),

  TOGGLE_SECTION: ({ payload: sectionId }) =>
    fp.pipe(
      fp.update(['openSections', sectionId], (isOpen) => !isOpen),
      fp.update('openSections', fp.pickBy(fp.identity)),
    ),
  OPEN_SECTION: ({ payload: sectionId }) =>
    fp.update(['openSections', sectionId], true),

  TOGGLE_BLOCK: ({ payload: blockId }) =>
    fp.update(['closedBlocks', blockId], (isClosed) => !isClosed),

  SET_SHARE_VISIBLE: ({ payload }) =>
    fp.pipe(
      fp.set('isShareVisible', payload),
      fp.set('isContactFormVisible', false),
    ),

  TOGGLE_SHARE: () =>
    fp.pipe(
      fp.update('isShareVisible', (isVisible) => !isVisible),
      fp.set('isContactFormVisible', false),
    ),
})

const recipeUpdater = handleActions({
  LOAD_RECIPE: () => fp.set('isRecipeLoading', true),
  LOAD_RECIPE_COMPLETED: () => fp.set('isRecipeLoading', false),

  SAVE_RECIPE: () => fp.set('isRecipeSaving', true),
  SAVE_RECIPE_COMPLETED: () => fp.set('isRecipeSaving', false),

  ORDER_RECIPE: () => fp.set('isOrderingRecipe', true),
  ORDER_RECIPE_COMPLETED: () => fp.set('isOrderingRecipe', false),

  GENERATE_PREVIEWS: () => fp.set('isPreviewsGenerating', true),
  GENERATE_PREVIEWS_COMPLETED: () => fp.set('isPreviewsGenerating', false),

  UPLOAD_PREVIEWS: () => fp.set('isPreviewsUploading', true),
  UPLOAD_PREVIEWS_COMPLETED: () => fp.set('isPreviewsUploading', false),

  SET_PREVIEW_URLS: ({ payload }) => fp.set('previewUrls', payload),
})

const changesUpdater = handleActions({
  SET_ORIGIN_VALUES: ({ payload }) => fp.set('originValues', payload),
})

const contactFormUpdater = handleActions({
  TOGGLE_CONTACT_FORM: () =>
    fp.pipe(
      fp.update('isContactFormVisible', (isVisible) => !isVisible),
      fp.set('isShareVisible', false),
      fp.unset('contactForm'),
    ),

  SET_CONTACT_FORM_VALUE: ({ payload: { key, value } }) =>
    fp.set(['contactForm', key], value),
})

const previewUpdater = handleActions({
  SET_PREVIEW_GENERATOR: ({ payload: { previewGenerator } }) =>
    fp.set(['previewGenerator'], previewGenerator),
})

export default concat(
  getDefaultState,
  vendorUpdater,
  historyUpdater,
  uiUpdater,
  recipeUpdater,
  changesUpdater,
  contactFormUpdater,
  previewUpdater,
)
