import React from 'react'

import { cn } from '~p/client/components/utils'

import './BgContain.css'

const BgContain = ({ url, solid }) => (
  <div
    style={{ backgroundImage: `url(${url})`, backgroundColor: solid }}
    className={cn(['bgContain'])}
  />
)

export default BgContain
