import * as _ from '@technically/lodash'

import blendTone from '~p/client/blendTone'
import blendShadow from '~p/client/blendShadow'
import blendDarken from '~p/client/blendDarken'

let renderParams = {}
let toneCache = {}

const tmpCtx = document.createElement('canvas').getContext('2d')

const createCanvas = (size) => {
  const canvas = document.createElement('canvas')
  canvas.width = size.width
  canvas.height = size.height

  return canvas.getContext('2d')
}

const applyImage = (image) => {
  tmpCtx.clearRect(0, 0, image.width, image.height)
  tmpCtx.drawImage(image, 0, 0)

  return tmpCtx.canvas
}

const applyHue = (image, hue) => {
  tmpCtx.save()

  tmpCtx.clearRect(0, 0, image.width, image.height)
  tmpCtx.filter = `hue-rotate(${hue}deg)`
  tmpCtx.drawImage(image, 0, 0)

  tmpCtx.restore()
  return tmpCtx.canvas
}

const applyBrightness = (image, brightness) => {
  tmpCtx.save()

  tmpCtx.clearRect(0, 0, image.width, image.height)
  tmpCtx.filter = `brightness(${brightness})`
  tmpCtx.drawImage(image, 0, 0)

  tmpCtx.restore()
  return tmpCtx.canvas
}

const applyColor = (image, color) => {
  tmpCtx.save()

  tmpCtx.fillStyle = color
  tmpCtx.fillRect(0, 0, image.width, image.height)
  tmpCtx.globalCompositeOperation = 'destination-in'
  tmpCtx.drawImage(image, 0, 0)

  tmpCtx.restore()
  return tmpCtx.canvas
}

const applyMask = (mask, invertMask) => {
  tmpCtx.save()

  tmpCtx.globalCompositeOperation = `destination-${invertMask ? 'out' : 'in'}`
  tmpCtx.drawImage(mask, 0, 0)

  tmpCtx.restore()
  return tmpCtx.canvas
}

const getImageDataFromImage = (image) => {
  const { width, height } = image

  tmpCtx.clearRect(0, 0, width, height)
  tmpCtx.drawImage(image, 0, 0)

  return tmpCtx.getImageData(0, 0, width, height)
}

const getMaybeImages = (image) => {
  if (typeof image.highlight !== 'undefined') {
    return {
      toneImages: image,
    }
  }
  if (typeof image.mask !== 'undefined') {
    return {
      maskImages: image,
    }
  }

  return {
    image,
  }
}

const render = (canvas, assets, size, viewName) => {
  if (!canvas) {
    // RenderComposite component with canvas was unmounted.
    return
  }

  const params = { size, viewName }

  if (!_.isEqual(params, renderParams)) {
    toneCache = {}
  }

  renderParams = params

  const ctx = canvas.getContext('2d')
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  tmpCtx.canvas.width = canvas.width
  tmpCtx.canvas.height = canvas.height

  _.forEach(assets, (asset) => {
    const { layer } = asset
    if (layer.src == null) {
      return
    }

    const { image, toneImages, maskImages } = getMaybeImages(asset.image)
    if (image) {
      if (layer.blend === 'shadow' || layer.blend === 'darken') {
        let shadowImageData

        const layerKey = JSON.stringify(layer)

        const cachedTone = toneCache[layerKey]
        if (cachedTone) {
          shadowImageData = cachedTone.shadowImageData
        } else {
          shadowImageData = getImageDataFromImage(image)

          toneCache[layerKey] = { shadowImageData }
        }
        if (layer.blend === 'shadow') {
          blendShadow(ctx, shadowImageData)
        }
        if (layer.blend === 'darken') {
          blendDarken(ctx, shadowImageData)
        }
      } else if (layer.hue) {
        const hueCanvas = applyHue(image, layer.hue)
        ctx.drawImage(hueCanvas, 0, 0)
      } else if (layer.brightness) {
        const brightnessCanvas = applyBrightness(image, layer.brightness)
        ctx.drawImage(brightnessCanvas, 0, 0)
      } else if (layer.color) {
        const colorCanvas = applyColor(image, layer.color)
        ctx.drawImage(colorCanvas, 0, 0)
      } else {
        ctx.drawImage(image, 0, 0)
      }
    }

    if (toneImages) {
      if (layer.blend !== 'tone') {
        // unsupported
      }

      let shadowImageData
      let highlightImageData

      const layerKey = JSON.stringify(layer)

      const cachedTone = toneCache[layerKey]
      if (cachedTone) {
        shadowImageData = cachedTone.shadowImageData
        highlightImageData = cachedTone.highlightImageData
      } else {
        shadowImageData = getImageDataFromImage(toneImages.shadow)
        highlightImageData = getImageDataFromImage(toneImages.highlight)

        toneCache[layerKey] = { shadowImageData, highlightImageData }
      }

      blendTone(ctx, shadowImageData, highlightImageData)
    }

    if (maskImages) {
      if (!layer.mask) {
        // not supported
      }

      if (layer.color) {
        applyColor(maskImages.content, layer.color)
      } else {
        applyImage(maskImages.content)
      }
      const maskCanvas = applyMask(maskImages.mask, layer.invertMask)

      ctx.drawImage(maskCanvas, 0, 0)
    }
  })
}

export { createCanvas, render }
