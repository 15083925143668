import React from 'react'

import { cn } from '~p/client/components/utils'

import ContentHead from '../atoms/ContentHead'
import ContentHeadArrow from '../atoms/ContentHeadArrow'
import ContentLabel from '../atoms/ContentLabel'
import ContentBody from '../atoms/ContentBody'
import HorizontalAlign from '../atoms/HorizontalAlign'
import BlockChanges, { hasChanges } from '../molecules/BlockChanges'

function FoldableBlock(props) {
  const {
    state,
    onClick,
    primaryLabel,
    secondaryLabel = '',
    children,
    changes = [],
  } = props
  let bodyClassStates = [state]
  if (hasChanges(changes)) {
    bodyClassStates = bodyClassStates.concat(['changed'])
  }
  return (
    <div className={cn(['block'])}>
      <ContentHead onClick={onClick} classMods={['block']}>
        <HorizontalAlign classMods={['staticRight']}>
          <ContentLabel>
            <strong>{primaryLabel}</strong>
            <span>{secondaryLabel}</span>
          </ContentLabel>
          <ContentHeadArrow classMods={[state]} />
        </HorizontalAlign>
      </ContentHead>
      <ContentBody classStates={bodyClassStates} classMods={['block']}>
        {children}
        <BlockChanges changes={changes} />
      </ContentBody>
    </div>
  )
}

export default FoldableBlock
