import { connect } from 'react-redux'

import { getShareUrl } from '~p/client/share'

import SocialIcons from '../components/organisms/SocialIcons'

const mapStateToProps = (state, props) => ({
  ...props,
  shareToEmailUrl: getShareUrl('email', state),
  shareToFacebookUrl: getShareUrl('facebook', state),
  shareToTwitterUrl: getShareUrl('twitter', state),
  shareToPinterestUrl: getShareUrl('pinterest', state),
})

export default connect(mapStateToProps)(SocialIcons)
