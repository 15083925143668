
import meshDual from './mesh-dual.json'
import meshStamp from './mesh-stamp.json'
import photoshopLayers from './photoshop-layers.json'

export const revManifests = {
  'mesh-dual': meshDual,
  'mesh-stamp': meshStamp,
  'photoshop-layers': photoshopLayers,
}
