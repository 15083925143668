import React from 'react'

import { cn, mods, states } from '~p/client/components/utils'

import './ContentBody.css'

function ContentBody(props) {
  const { children, classMods = [], classStates = [] } = props
  return (
    <div className={cn(['contentBody', mods(classMods), states(classStates)])}>
      <div className={cn(['contentBody-inner'])}>{children}</div>
    </div>
  )
}

export default ContentBody
