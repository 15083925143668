import { connect } from 'react-redux'

import ToggleBlock from '../components/ToggleBlock'
import { toggleBlock } from '../actions'

const mapStateToProps = (state, props) => ({
  ...props,
  isOpen: !state.closedBlocks[props.blockId],
})

const mapDispatchToProps = (dispatch, { blockId }) => ({
  toggleBlock: () => dispatch(toggleBlock(blockId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ToggleBlock)
