import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import Icon from '../atoms/Icon'
import CopyUrl from '../../containers/CopyUrl'

import './SocialIcons.css'

function SocialIcons(props) {
  const {
    classMods = [],
    showCopy = false,
    shareToEmailUrl,
    shareToTwitterUrl,
    shareToFacebookUrl,
    shareToPinterestUrl,
    downloadUrl,
  } = props

  return (
    <div className={cn(['socialIcons', mods(classMods)])}>
      {showCopy && <CopyUrl justIcon />}
      <div data-gtm="print" onClick={() => window.print()}>
        <Icon name="print" />
      </div>
      <a
        data-gtm="email"
        href={shareToEmailUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="emailPadded" />
      </a>
      <a
        data-gtm="facebook"
        href={shareToFacebookUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="facebook" />
      </a>
      <a
        data-gtm="twitter"
        href={shareToTwitterUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="twitter" />
      </a>
      <a
        data-gtm="pinterest"
        href={shareToPinterestUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="pinterest" />
      </a>
      <a
        data-gtm="image-download"
        href={downloadUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="download" />
      </a>
    </div>
  )
}

export default SocialIcons
