import React from 'react'

import { cn, mods } from '~p/client/components/utils'
import ErrorBoundary from '~p/client/components/ErrorBoundary'

import ContentHead from '~mizuno/client/components/atoms/ContentHead'
import ContentLabel from '~mizuno/client/components/atoms/ContentLabel'
import ContentBody from '~mizuno/client/components/atoms/ContentBody'
import HorizontalAlign from '~mizuno/client/components/atoms/HorizontalAlign'
import ContentHeadArrow from '~mizuno/client/components/atoms/ContentHeadArrow'

const ToggleSection = ({
  primaryLabel,
  children,
  toggleSection,
  isSectionOpen,
}) => (
  <ErrorBoundary>
    <div className={cn(['section', mods([isSectionOpen ? 'on' : 'off'])])}>
      <ContentHead
        classMods={['section', 'pointer']}
        onClick={() => toggleSection()}
      >
        <HorizontalAlign classMods={['staticRight']}>
          <ContentLabel classMods={['section']}>
            <span>{primaryLabel}</span>
          </ContentLabel>
          <ContentHeadArrow classMods={[isSectionOpen ? 'on' : 'off']} />
        </HorizontalAlign>
      </ContentHead>
      <ContentBody classMods={['section']}>
        {isSectionOpen ? children() : null}
      </ContentBody>
    </div>
  </ErrorBoundary>
)

export default ToggleSection
