// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FlagRow } from '../typings';

const data = [
  {
    "id": "US",
    "name": "US"
  },
  {
    "id": "MX",
    "name": "Mexico"
  },
  {
    "id": "AU",
    "name": "Australia"
  },
  {
    "id": "CA",
    "name": "Canada"
  },
  {
    "id": "CO",
    "name": "Colombia"
  },
  {
    "id": "CU",
    "name": "Cuba"
  },
  {
    "id": "DO",
    "name": "Dominican Republic"
  },
  {
    "id": "IE",
    "name": "Ireland"
  },
  {
    "id": "IL",
    "name": "Israel"
  },
  {
    "id": "IT",
    "name": "Italy"
  },
  {
    "id": "NL",
    "name": "Netherlands"
  },
  {
    "id": "PA",
    "name": "Panama"
  },
  {
    "id": "PR",
    "name": "Puerto Rico"
  },
  {
    "id": "VE",
    "name": "Venezuela"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FlagRow[]>> = data;

export type Flag = typeof typed[number];

export const FLAG_INDEX_KEY = "id";
export type FlagIndexKey = "id";
export type FlagId = Flag["id"];

let i = 0;
export const FLAG_DICT = {
  "US": typed[i++],
  "MX": typed[i++],
  "AU": typed[i++],
  "CA": typed[i++],
  "CO": typed[i++],
  "CU": typed[i++],
  "DO": typed[i++],
  "IE": typed[i++],
  "IL": typed[i++],
  "IT": typed[i++],
  "NL": typed[i++],
  "PA": typed[i++],
  "PR": typed[i++],
  "VE": typed[i++]
} as const;

export { typed as FLAGS };
