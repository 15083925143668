// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PalmLinerMaterialRow } from '../typings';

const data = [
  {
    "id": "shell",
    "name": "Shell"
  },
  {
    "id": "deerskin",
    "name": "Deerskin"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PalmLinerMaterialRow[]>> = data;

export type PalmLinerMaterial = typeof typed[number];

export const PALM_LINER_MATERIAL_INDEX_KEY = "id";
export type PalmLinerMaterialIndexKey = "id";
export type PalmLinerMaterialId = PalmLinerMaterial["id"];

let i = 0;
export const PALM_LINER_MATERIAL_DICT = {
  "shell": typed[i++],
  "deerskin": typed[i++]
} as const;

export { typed as PALM_LINER_MATERIALS };
