import React from 'react'

import { cn } from '~p/client/components/utils'

import TextInput from '../molecules/TextInput'
import SelectInput from '../molecules/SelectInput'
import Button from '../atoms/Button'
import { emailRegex } from '~p/client/common/selectors'

import './ContactForm.css'

function ContactForm(props) {
  const {
    email,
    isValid,
    name,
    onEmailChange,
    onNameChange,
    onPhoneChange,
    onPostalCodeChange,
    onRegionChange,
    onSubmit,
    onTuringTestChange,
    phone,
    postalCode,
    region,
    regions,
    turingTest,
  } = props

  return (
    <div className={cn(['contactForm'])}>
      <TextInput
        name="name"
        autoComplete="name"
        autoFocus
        subline="Name (optional)"
        value={name}
        onChange={onNameChange}
      />
      <TextInput
        name="phone"
        autoComplete="tel"
        subline="Phone (optional)"
        value={phone}
        onChange={onPhoneChange}
      />
      <TextInput
        name="email"
        autoComplete="email"
        type="email"
        subline="Email"
        value={email}
        onChange={onEmailChange}
        pattern={emailRegex}
      />
      {regions && (
        <SelectInput
          name="state"
          autoComplete="address-level1"
          subline="Region"
          options={regions}
          value={region}
          classMods={['fullWidth']}
          change={onRegionChange}
        />
      )}
      <TextInput
        name="postal"
        autoComplete="postal-code"
        subline="Postal code (optional)"
        value={postalCode}
        onChange={onPostalCodeChange}
      />
      {/* Bot honeypot field */}
      <TextInput
        name="turingTest"
        subline="Turing Test"
        value={turingTest}
        onChange={onTuringTestChange}
        ariaLabel="If you are human do not fill out this field."
        turingTest
      />
      <div className="contactForm-submit">
        <Button
          onClick={isValid ? onSubmit : () => {}}
          classMods={isValid ? [] : ['disabled']}
          data-gtm="order-email-submit"
        >
          Contact Me
        </Button>
      </div>
    </div>
  )
}

export default ContactForm
