function getDualLayers(baseJob) {
  const { meshId, textureIdBase, color, bundleName, filename, contain } =
    baseJob
  return [
    {
      type: 'mesh3d',
      blend: undefined,
      color,
      recipeData: {
        textureId: `${textureIdBase}-fill.png`,
        meshId,
        backgroundColor: undefined,
        contain,
      },
      bundleName,
      filename: `${filename || textureIdBase}-fill`,
    },
    {
      type: 'mesh3d',
      blend: 'shadow',
      recipeData: {
        textureId: `${textureIdBase}-shadow.png`,
        meshId,
        backgroundColor: '#808080',
        contain,
      },
      bundleName,
      filename: `${filename || textureIdBase}-shadow`,
    },
  ]
}

function getFillLayer(baseJob) {
  const {
    meshId,
    textureId,
    color,
    bundleName,
    filename,
    mask,
    invertMask,
    contain,
  } = baseJob
  return {
    type: 'mesh3d',
    blend: undefined,
    color,
    recipeData: {
      textureId,
      meshId,
      backgroundColor: undefined,
      contain,
    },
    bundleName,
    filename: filename || textureId,
    mask,
    invertMask,
  }
}

function getTextLayer(baseJob) {
  const {
    meshId,
    textData,
    color,
    bundleName,
    filename,
    mask,
    blend,
    backgroundColor,
    contain,
  } = baseJob
  const textureIdBase = [meshId, textData.font, textData.text].join('_')
  return {
    type: 'mesh3d',
    blend: blend || undefined,
    color,
    recipeData: {
      textData,
      meshId,
      backgroundColor: backgroundColor || undefined,
      contain,
    },
    bundleName,
    filename: filename || textureIdBase,
    mask,
  }
}

export { getDualLayers, getFillLayer, getTextLayer }
