import React from 'react'

import { maybeSetActive } from '~p/client/components/utils'

import Tile from '../atoms/Tile'
import Icon from '../atoms/Icon'
import BgCover from '../atoms/BgCover'

function PanelTile(props) {
  const { classMods = [], classStates = [], isActive = false, url } = props
  return (
    <Tile
      {...props}
      classMods={['panel', ...classMods]}
      classStates={maybeSetActive(isActive, classStates)}
    >
      {isActive ?
        <Icon name="select" />
      : null}
      <BgCover url={url} />
    </Tile>
  )
}

export default PanelTile
