import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import './HorizontalAlign.css'

function HorizontalAlign(props) {
  const { children, classMods = [] } = props
  return (
    <div className={cn(['horizontalAlign', mods(classMods)])}>{children}</div>
  )
}

export default HorizontalAlign
