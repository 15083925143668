import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import ContentHead from '../atoms/ContentHead'
import ContentLabel from '../atoms/ContentLabel'
import ContentBody from '../atoms/ContentBody'
import HorizontalAlign from '../atoms/HorizontalAlign'

function FinalizeSection(props) {
  const { primaryLabel, children } = props
  return (
    <div className={cn(['section', mods(['finalize'])])}>
      <ContentHead classMods={['section']}>
        <HorizontalAlign classMods={['staticRight']}>
          <ContentLabel classMods={['section']}>
            <strong>{primaryLabel}</strong>
          </ContentLabel>
        </HorizontalAlign>
      </ContentHead>
      <ContentBody classMods={['section']}>{children}</ContentBody>
    </div>
  )
}

export default FinalizeSection
