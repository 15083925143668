import { connect } from 'react-redux'

import { setRenderCompositeActiveView } from '~p/client/renderComposite/actions'

import Renderer from '../components/Renderer'

const mapStateToProps = (state, props) => ({
  activeView: state.renderComposite.activeView,
  ...props,
})

const mapDispatchToProps = {
  setView: setRenderCompositeActiveView,
}

export default connect(mapStateToProps, mapDispatchToProps)(Renderer)
