import { map } from '@technically/lodash'
import fp from 'lodash/fp.js'
import React from 'react'
import { withProps } from 'recompose'
import { connect } from 'react-redux'

import * as controlLib from '~p/client/control-tree'
import { openMenu } from '~p/client/common/actions'
import { createMenuSelector } from '~p/client/common/selectors'
import {
  navListNested,
  hasDrawableChildren,
  firstDrawableChild,
} from '~p/client/navigation'

import NavigationTab from '~mizuno/client/components/atoms/NavigationTab'
import Navigation from '~mizuno/client/components/organisms/Navigation'

import ToggleBlock from '../../../mizuno-uniforms/client/containers/ToggleBlock'

import controlTree from '../controlTree'

import * as productControls from '../containers/controls'

import _ControlMenu from '../../../mizuno-uniforms/client/containers/ControlMenu'

const withCT = withProps({ controlTree })

const {
  ColorSelectControl,
  TileSelectControl,
  WebDesignSelectControl,
  FontSelectControl,
  TextInputControl,
  ImageUploadControl,
  FileUploadControl,
  HandDesignSelectControl,
} = fp.mapValues(withCT, productControls)
const ControlMenu = withCT(_ControlMenu)

const Fragment = withCT(controlLib.Fragment)

const menuSelector = createMenuSelector(controlTree)

const Nav = connect(
  (state) => ({
    menu: menuSelector(state),
  }),
  {
    openMenu,
  },
)(({ menu, openMenu: openMenuConnected }) => (
  <div>
    <Navigation>
      {map(navListNested, (level1) => (
        <NavigationTab
          key={level1.item.id}
          classStates={menu === level1.item.id ? ['active'] : []}
          onClick={() => openMenuConnected(controlTree, level1.item.id)}
          data-gtm={`tab-${level1.item.id}`}
        >
          {level1.item.name}
        </NavigationTab>
      ))}
    </Navigation>
  </div>
))

const getItemComponent = (item, nodes, opts = {}) => {
  const { showLabel, hideNone } = opts

  const sportId = nodes['env.sportId'].value

  const props = {
    key: item.id,
    path: item.propId,
    showLabel,
    hideNone,
    sportId,
  }

  const { contentType } = item

  if (contentType === 'TileSelectControl') {
    return <TileSelectControl {...props} />
  }

  if (contentType === 'ColorSelectControl') {
    return <ColorSelectControl {...props} />
  }

  if (contentType === 'WebDesignSelectControl') {
    return <WebDesignSelectControl {...props} />
  }

  if (contentType === 'FontSelectControl') {
    return <FontSelectControl {...props} />
  }

  if (contentType === 'TextInputControl') {
    if (!item.propId) {
      throw new Error('propId missing')
    }
    return (
      <TextInputControl
        {...props}
        subline={nodes[item.propId].subline}
        classMods={['gap']}
      />
    )
  }

  if (contentType === 'ImageUploadControl') {
    return (
      <ImageUploadControl
        {...props}
        label={null}
        subline="PNG, JPEG or GIF or SVG file for immediate visualization"
        classMods={['gap']}
      />
    )
  }

  if (contentType === 'FileUploadControl') {
    return (
      <FileUploadControl
        {...props}
        label={null}
        subline="PSD, AI or TIFF file for factory"
        classMods={['gap']}
      />
    )
  }

  if (contentType === 'HandDesignSelectControl') {
    return (
      <HandDesignSelectControl {...props} key={item.id} path={item.propId} />
    )
  }

  throw Error(`contentType missing or not handled for ${item.id}`)
}

const Sidebar = (props) => (
  <div id="sidebar">
    <Nav />

    {map(navListNested, ({ item: item1, children: children1 }) => (
      <Fragment forRoute={item1.id} key={item1.id}>
        {() => (
          <div>
            {map(children1, ({ item: item2, children: children2 }) => {
              if (!hasDrawableChildren(item2, children2, props.nodes)) {
                return null
              }

              if (item1.id === 'product') {
                return (
                  <ToggleBlock
                    key={item2.id}
                    blockId={item2.id}
                    primaryLabel={item2.name}
                  >
                    {getItemComponent(item2, props.nodes, {
                      hideNone: true,
                    })}
                  </ToggleBlock>
                )
              }

              return (
                <ControlMenu
                  key={item2.id}
                  path={
                    children2.length ?
                      firstDrawableChild(children2, props.nodes).item.propId
                    : item2.propId
                  }
                  link={fp.replace(new RegExp(`^${item1.id}\\.`), '', item2.id)}
                  primaryLabel={item2.name}
                >
                  {!children2.length ?
                    getItemComponent(item2, props.nodes)
                  : map(children2, ({ item: item3 }) =>
                      getItemComponent(item3, props.nodes, {
                        showLabel: true,
                      }),
                    )
                  }
                </ControlMenu>
              )
            })}
          </div>
        )}
      </Fragment>
    ))}
  </div>
)

export default Sidebar
