import fp from 'lodash/fp.js'
import wildcard from 'wildcard'
import { connect } from 'react-redux'
import { lifecycle } from 'recompose'

import { submenuSelector } from '~p/client/common/selectors'

import { toggleSection, openSection } from '../actions' // used also in gloves that do not expose these actions

import ToggleSection from '../components/ToggleSection'

const hasOpenSubmenuInside = (childrenPath, openSubmenu) =>
  openSubmenu &&
  fp.some((path) => wildcard(path, openSubmenu))(fp.concat([], childrenPath))

const mapStateToProps = (state, props) => {
  const { sectionId, childrenPath } = props
  const openSections = state.openSections

  const submenu = submenuSelector(state)
  const isSectionOpen = openSections[sectionId]

  const shouldOpen =
    !isSectionOpen &&
    childrenPath &&
    hasOpenSubmenuInside(childrenPath, submenu)

  return {
    ...props,
    isSectionOpen,
    shouldOpen,
  }
}

const mapDispatchToProps = (dispatch, { sectionId }) => ({
  toggleSection: () => dispatch(toggleSection(sectionId)),
  openSection: () => dispatch(openSection(sectionId)),
})

function componentDidMount() {
  if (this.props.shouldOpen) this.props.openSection()
}

export default fp.compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({ componentDidMount }),
)(ToggleSection)
