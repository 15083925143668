import React from 'react'

import { maybeSetActive, maybeSetDisabled } from '~p/client/components/utils'

import Tile from '../atoms/Tile'
import Icon from '../atoms/Icon'

function ColorTile(props) {
  const {
    classMods = [],
    classStates = [],
    isActive = false,
    isDisabled = false,
  } = props
  return (
    <Tile
      {...props}
      classMods={['color', ...classMods]}
      classStates={maybeSetActive(
        isActive,
        maybeSetDisabled(isDisabled, classStates),
      )}
    >
      {isActive ?
        <Icon name="select" />
      : null}
      &nbsp;
    </Tile>
  )
}

export default ColorTile
