import fp from 'lodash/fp.js'

// Converts a list of class names to a single string
// cn :: [a] -> String
export const cn = fp.flowRight([fp.join(' '), fp.flatten])

// Prepends every modifier in alist with a prefix
// mods :: [a] -> [String]
export const mods = fp.map(fp.add('mod-'))

// Prepends every state in a list with a prefix
// mods :: [a] -> [String]
export const states = fp.map(fp.add('is-'))

// Set active is given true
// maybeSetActive :: boolean -> [a] -> [a]
export const maybeSetActive = (predicate, classStates) =>
  (predicate ? ['active'] : []).concat(classStates)

// Set disabled is given true
// maybeSetDisabled :: boolean -> [a] -> [a]
export const maybeSetDisabled = (predicate, classStates) =>
  (predicate ? ['disabled'] : []).concat(classStates)
