import fp from 'lodash/fp.js'
import { createAction } from 'redux-actions'
import clipboard from 'clipboard-js'

import viewAngles from '../../viewAngles'

import { sendCopyToParentWindow } from '../sendCopyToParentWindow'

import http from '../http'
import {
  setValues,
  setChanges,
  resetValues,
  commitChanges,
} from '../control-tree'
import { push, replace } from '../history'
import {
  changesSelector,
  recipeIdSelector,
  isSkuSelector,
  createMenuSelector,
  submenuSelector,
  previewUrlsWithOriginSelector,
  legacyPreviewUrlsWithOriginSelector,
  createLegacyRecipeDataFlatSelector,
  createLegacyRecipeDataNestedSelector,
  pageTitleSelector,
} from './selectors'
import { mapWithKey, diffObject, createForm } from '../utils'
import customizer from '../../customizer.cjs'

// For ESM/CJS portability
const { SKU_OMITS, SKU_MATCHES, DEFAULT_MENU, DEFAULT_MENU_WHEN_SKU } =
  customizer

const { vendor, orderMethod, addToCartUrl, orderDataFormat } =
  window.serverConfig ?? {}

const openUnhandledErrorAlert = createAction('OPEN_UNHANDLED_ERROR_ALERT')
const dismissUnhandledErrorAlert = createAction('DISMISS_UNHANDLED_ERROR_ALERT')

const reloadApp = () => (dispatch) => {
  dispatch(createAction('RELOAD_APP')())
  dispatch(createAction('DISMISS_UNHANDLED_ERROR_ALERT')())

  window.location.reload()
}

const resetApp = () => (dispatch) => {
  dispatch(createAction('RESET_APP')())
  dispatch(createAction('DISMISS_UNHANDLED_ERROR_ALERT')())

  window.location.href = '/'
}

const setLayoutMode = createAction('SET_LAYOUT_MODE')

const waitForPreviewGenerator = (getState) =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      const state = getState()
      if (state.previewGenerator) {
        clearInterval(interval)
        resolve(undefined)
      }
    }, 100)
  })

const uploadPreviews = async (recipeId, previews) => {
  if (fp.isEmpty(previews)) {
    return Promise.resolve([])
  }

  const requests = mapWithKey((blob, previewName) => {
    const formData = new FormData()
    formData.append('file', blob)
    const url = `/api/recipes/${recipeId}/${previewName}?modelVersion=${process.env.MODEL_VERSION}`
    return http.put(url, formData).then(() => ({ [previewName]: url }))
  }, previews)

  return Promise.all(requests)
}

const navigated =
  (controlTree, loc, matches, action) => (dispatch, getState) => {
    const prevMenu = createMenuSelector(controlTree)(getState())
    dispatch({
      type: 'NAVIGATED',
      payload: { loc, matches, action },
      meta: { [sendCopyToParentWindow]: true },
    })
    const nextMenu = createMenuSelector(controlTree)(getState())
    if (prevMenu !== nextMenu) {
      dispatch({
        type: 'MENU_CHANGED',
        payload: { prevMenu, nextMenu },
      })
    }
  }

const updateTitle =
  (controlTree, getRecipeTitle, getSkuTitle, getIndexTitle) =>
  (dispatch, getState) => {
    document.title = pageTitleSelector(
      controlTree,
      getRecipeTitle,
      getSkuTitle,
      getIndexTitle,
      getState(),
    )
  }

const setOriginValues = (controlTree) => (dispatch, getState) => {
  dispatch({
    type: 'SET_ORIGIN_VALUES',
    payload: controlTree.getPublicRecipe(getState()),
  })
}

const updatePath = (controlTree) => (dispatch, getState) => {
  const state = getState()

  const recipeId = recipeIdSelector(state)

  let path
  if (recipeId) {
    path = `/design/${recipeId}/with-changes`
  } else {
    const currentValues = controlTree.getRecipe(state)

    const sku = fp.map((k) => currentValues[k], SKU_MATCHES).join('-')

    path = sku ? `/sku/${sku}` : '/'
  }

  replace({ path })
}

const updateChanges = (controlTree) => (dispatch, getState) => {
  const state = getState()

  const recipeId = recipeIdSelector(state)
  const currentValues = controlTree.getPublicRecipe(state)
  const diffValues = diffObject(state.originValues, currentValues)

  // Omit SKU from changes when recipe ID is not present.
  let changes =
    recipeId ? diffValues : fp.omit(SKU_OMITS || SKU_MATCHES)(diffValues)

  if (fp.isEqual({}, changes)) {
    changes = undefined
  }

  push({ query: { changes } })
}

// TODO: Mizuno specific, move out of platform.
const openMenu = (controlTree, nextMenu) => (dispatch, getState) => {
  dispatch(createAction('OPEN_MENU')(nextMenu))

  dispatch(updatePath(controlTree))

  const state = getState()
  const isSku = isSkuSelector(state)

  const menu =
    (
      (!isSku && DEFAULT_MENU === nextMenu) ||
      (isSku && DEFAULT_MENU_WHEN_SKU === nextMenu)
    ) ?
      undefined
    : nextMenu

  push({ query: { menu } })

  dispatch(commitChanges())
}

const loadChanges =
  (controlTree, getRecipeTitle, getSkuTitle, getIndexTitle) =>
  (dispatch, getState) => {
    const state = getState()
    const changedValues = changesSelector(state)
    dispatch(setChanges(changedValues))
    dispatch(
      updateTitle(controlTree, getRecipeTitle, getSkuTitle, getIndexTitle),
    )
  }

const setPreviewUrls = createAction('SET_PREVIEW_URLS')

const toggleContactForm = createAction('TOGGLE_CONTACT_FORM')

const processPreviews =
  (getState, recipeId, currentUrls) => async (dispatch) => {
    const missingViews = fp.keys(
      fp.omit(
        fp.keys(currentUrls),
        fp.pickBy('exposeForRecipePreviewImages', viewAngles),
      ),
    )

    if (!missingViews.length) {
      return currentUrls
    }

    await waitForPreviewGenerator(getState)

    const state = getState()

    const { previewGenerator } = state

    dispatch({ type: 'GENERATE_PREVIEWS' })
    const generatedPreviews = await previewGenerator(missingViews).catch(
      (error) => {
        console.error('error in processPreviews generatedPreviews', error)
        throw error
      },
    )
    dispatch({ type: 'GENERATE_PREVIEWS_COMPLETED' })

    dispatch({ type: 'UPLOAD_PREVIEWS' })
    const uploadedUrls = await uploadPreviews(
      recipeId,
      generatedPreviews,
    ).catch((error) => {
      console.error('error in processPreviews uploadedUrls')
      throw error
    })
    dispatch({ type: 'UPLOAD_PREVIEWS_COMPLETED' })

    return fp.reduce((a, x) => fp.assign(a, x), currentUrls, uploadedUrls)
  }

const loadRecipe = (controlTree, recipeId) => async (dispatch, getState) => {
  dispatch({ type: 'LOAD_RECIPE', payload: recipeId })

  const { data } = await http.get(
    `/api/recipes/${recipeId}?modelVersion=${process.env.MODEL_VERSION}`,
  )

  dispatch(setValues(data.data))
  dispatch(setOriginValues(controlTree))

  const previewUrls = await processPreviews(
    getState,
    recipeId,
    data.previews,
  )(dispatch)
  dispatch(setPreviewUrls(previewUrls))

  dispatch({ type: 'LOAD_RECIPE_COMPLETED', payload: data })
}

const _saveRecipe = (recipe) => async (dispatch, getState) => {
  const { data } = await http.post(`/api/recipes`, {
    data: recipe,
    modelVersion: process.env.MODEL_VERSION,
  })

  const previewUrls = await processPreviews(
    getState,
    data.id,
    data.previews,
  )(dispatch)

  return { recipe: data, previewUrls }
}

const saveRecipe = (controlTree) => async (dispatch, getState) => {
  dispatch({ type: 'SAVE_RECIPE' })

  const state = getState()
  const newRecipe = controlTree.getPublicRecipe(state)

  const { recipe, previewUrls } = await _saveRecipe(newRecipe)(
    dispatch,
    getState,
  )
  dispatch({ type: 'SAVE_RECIPE_COMPLETED' })
  dispatch(setPreviewUrls(previewUrls))
  push({
    path: `/design/${recipe.id}`,
    query: { changes: null, menu: null },
  })
  return recipe.id
}

const resetRecipe = (controlTree) => (dispatch) => {
  push({ path: '/', query: { menu: DEFAULT_MENU, changes: null } })

  dispatch(resetValues())
  dispatch(setOriginValues(controlTree))
}

const modifyRecipe = () => (dispatch, getState) => {
  const state = getState()
  const recipeId = recipeIdSelector(state)
  const path = `/design/${recipeId}/with-changes`

  push({ path })
}

const getEmbedParentBaseUrl = (href) => {
  if (!href) {
    return undefined
  }
  const result = /^(.+?)(#!?)/.exec(href)
  if (!result) {
    return undefined
  }
  const [, base, hashPrefix] = result
  return base + hashPrefix
}

const orderRecipe =
  (controlTree, id = null) =>
  (dispatch, getState) => {
    dispatch({ type: 'ORDER_RECIPE' })

    const state = getState()

    const recipeId = id || recipeIdSelector(state)

    const data = {
      vendor,
      recipeId,
      modelVersion: process.env.MODEL_VERSION,
      method: orderMethod,
    }

    if (orderMethod === 'email') {
      http
        .post(`/api/orders`, {
          ...data,
          contactForm: state.contactForm,
          embedParentBaseUrl: getEmbedParentBaseUrl(state.parentHref),
        })
        .then(() => {
          dispatch({ type: 'ORDER_RECIPE_COMPLETED' })
          dispatch(toggleContactForm())
        })
    } else if (orderMethod === 'shopatron-cart') {
      http.post(`/api/orders`, data).then(({ data: { redirectTo } }) => {
        window.parent.location = redirectTo
      })
    } else if (orderMethod === 'custom') {
      http
        .post(`/api/orders`, {
          ...data,
          embedParentBaseUrl: getEmbedParentBaseUrl(state.parentHref),
        })
        .then(({ data: { redirectTo } }) => {
          window.parent.location = redirectTo
        })
    } else if (orderMethod === 'client-cart') {
      http.post(`/api/orders`, data).then(({ data: { id: orderId } }) => {
        const cartUrl = state.vendorShoppingCart.url || addToCartUrl
        const fullRecipe = controlTree.getRecipe(state)
        const priceWithCurrency = fullRecipe['calc.priceWithCurrency']
        const price = fullRecipe['calc.price']
        if (orderDataFormat === 'legacyFlat') {
          createForm(cartUrl, 'POST', {
            ...state.vendorShoppingCart.extraParams,
            ...createLegacyRecipeDataFlatSelector(controlTree)(state),
            ...legacyPreviewUrlsWithOriginSelector(state),
            ConfigurationID: recipeId,
            OrderID: orderId,
            PriceWithCurrency: priceWithCurrency,
            price,
          }).submit()
        } else if (orderDataFormat === 'legacyNested') {
          createForm(cartUrl, 'POST', {
            json: JSON.stringify({
              ...state.vendorShoppingCart.extraParams,
              ...createLegacyRecipeDataNestedSelector(controlTree)(state),
              ...legacyPreviewUrlsWithOriginSelector(state),
              ConfigurationID: recipeId,
              OrderID: orderId,
              PriceWithCurrency: priceWithCurrency,
              price,
            }),
          }).submit()
        } else {
          createForm(cartUrl, 'POST', {
            extraParams: state.vendorShoppingCart.extraParams,
            recipeId,
            orderId,
            previewUrls: previewUrlsWithOriginSelector(state),
            recipeData: controlTree.getPublicRecipe(state),
            price,
            priceWithCurrency,
          }).submit()
        }
      })
    } else {
      throw new Error(`Unhandled order method: ${orderMethod}`)
    }
  }

const saveAndOrderRecipe = (controlTree) => async (dispatch, getState) => {
  const state = getState()

  const newRecipe = controlTree.getPublicRecipe(state)

  dispatch({ type: 'SAVE_RECIPE' })
  const { recipe, previewUrls } = await _saveRecipe(newRecipe)(
    dispatch,
    getState,
  )
  dispatch({ type: 'SAVE_RECIPE_COMPLETED' })
  dispatch(setPreviewUrls(previewUrls))
  dispatch(orderRecipe(controlTree, recipe.id))
}

const setDebugViewerTabId = createAction('SET_DEBUG_VIEWER_TAB_ID')

const setPreviewMinimization = createAction('SET_PREVIEW_MINIMIZATION')
const togglePreviewMinimization = createAction('TOGGLE_PREVIEW_MINIMIZATION')

const introducePreview = () => (dispatch) => {
  dispatch({ type: 'INTRODUCE_PREVIEW' })

  dispatch(setPreviewMinimization(false))
  setTimeout(() => {
    dispatch(setPreviewMinimization(true))
  }, 2000)
}

const ensurePreviewIntroduced = () => (dispatch, getState) => {
  const state = getState()
  if (state.isPreviewIntroduced || !state.isPreviewMinimized) {
    // no point in the intro, if it's already open for whatever reason
    return
  }

  dispatch(introducePreview())
}

const toggleSubmenu = (controlTree, controlId) => (dispatch, getState) => {
  dispatch(createAction('OPEN_SUBMENU')())

  const state = getState()

  const menu = createMenuSelector(controlTree)(state)
  const submenu = submenuSelector(state)

  const shouldOpen = submenu !== controlId

  const query = {
    menu: shouldOpen ? `${menu}/${controlId}` : menu,
  }

  push({ query })

  dispatch(commitChanges())
}

const copyLinkToClipboard = () => (dispatch, getState) => {
  const state = getState()

  const url = state.parentHref || window.location.href

  dispatch({
    type: 'COPY_LINK_TO_CLIPBOARD',
    payload: url,
  })

  clipboard.copy(url)
}

const saveAndCopyLinkToClipboard = (controlTree) => async (dispatch) => {
  await dispatch(saveRecipe(controlTree))
  await dispatch(copyLinkToClipboard())
}

const setShareVisible = createAction('SET_SHARE_VISIBLE')

const toggleShare = createAction('TOGGLE_SHARE')

const setContactFormValue = (key, value) => (dispatch) => {
  dispatch({
    type: 'SET_CONTACT_FORM_VALUE',
    payload: { key, value },
  })
}

const configureVendorShoppingCart = createAction(
  'CONFIGURE_VENDOR_SHOPPING_CART',
)

const setPreviewGenerator = (previewGenerator) => (dispatch) => {
  dispatch({
    type: 'SET_PREVIEW_GENERATOR',
    payload: { previewGenerator },
  })
}

const inquire = () => async (dispatch, getState) => {
  dispatch({ type: 'INQUIRE_ABOUT_RECIPE' })

  const state = getState()
  const recipeId = recipeIdSelector(state)

  await http.post(`/api/inquiries`, {
    recipeId,
    modelVersion: process.env.MODEL_VERSION,
    contactForm: state.contactForm,
    embedParentBaseUrl: getEmbedParentBaseUrl(state.parentHref),
  })

  dispatch({ type: 'INQUIRE_ABOUT_RECIPE_COMPLETED' })
  dispatch(toggleContactForm())
}

export {
  reloadApp,
  resetApp,
  setLayoutMode,
  openUnhandledErrorAlert,
  dismissUnhandledErrorAlert,
  setDebugViewerTabId,
  navigated,
  updateTitle,
  setOriginValues,
  updatePath,
  updateChanges,
  loadChanges,
  setPreviewUrls,
  loadRecipe,
  saveRecipe,
  resetRecipe,
  modifyRecipe,
  orderRecipe,
  saveAndOrderRecipe,
  openMenu,
  setPreviewMinimization,
  togglePreviewMinimization,
  ensurePreviewIntroduced,
  toggleSubmenu,
  saveAndCopyLinkToClipboard,
  copyLinkToClipboard,
  setContactFormValue,
  toggleContactForm,
  setShareVisible,
  toggleShare,
  configureVendorShoppingCart,
  setPreviewGenerator,
  inquire,
}
