import * as _ from '@technically/lodash'

import RenderComposite from '~p/client/renderComposite/containers/RenderComposite'

import ViewNavigation from '~mizuno/client/components/atoms/ViewNavigation'
import Button from '~mizuno/client/components/atoms/Button'

import './Renderer.css'

const Renderer = (props) => (
  <div>
    <RenderComposite {...props} />

    {!props.isHidden && (
      <ViewNavigation>
        {_.map(
          _.pickBy(props.viewAngles, { exposeForUI: true }),
          ({ label }, view) => (
            <Button
              key={view}
              classMods={['view']}
              classStates={props.activeView === view ? ['active'] : []}
              onClick={() => props.setView(view)}
            >
              {label}
            </Button>
          ),
        )}
      </ViewNavigation>
    )}
  </div>
)

export default Renderer
