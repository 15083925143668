import { useState } from 'react'

import http from '../http'

const errorMessage = 'Something went wrong!'

const uploadFile = async (file, targetUrl) => {
  const formData = new FormData()
  formData.append('file', file)

  return http
    .post(targetUrl, formData)
    .then((response) => ({
      status: 'uploaded',
      errorMessage: undefined,
      data: response.data,
    }))
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return { status: 'error', errorMessage: error.response.data.message }
      }
      return { status: 'error', errorMessage }
    })
}

const uploadsEnhancer = (config) => (Component) => (props) => {
  const [state, setState] = useState({
    isUploading: false,
    errorMessage: undefined,
  })

  const onAcceptFile = async (file) => {
    setState({ isUploading: true })

    const { status, errorMessage, data } = await uploadFile(
      file,
      config.targetUrl,
    )

    setState({ isUploading: false, errorMessage })

    if (status === 'uploaded') {
      props.change({ id: data.id, filename: file.name })
    }
  }

  const onRejectFile = () => {
    setState({ errorMessage })
    props.change(null)
  }

  const onClearFile = () => {
    props.change(null)
  }

  return (
    <Component
      accept={config.accept}
      previewUrl={config.previewUrl}
      onAcceptFile={onAcceptFile}
      onRejectFile={onRejectFile}
      onClearFile={onClearFile}
      isUploading={state.isUploading}
      error={state.errorMessage}
      {...props}
    />
  )
}

export default uploadsEnhancer
