import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import React, { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import { connect } from 'react-redux'
import WebFont from 'webfontloader'

import getAsset from '~p/getAsset'

import handleErrors from '../../../platform/client/handleErrors'
import { cn, states } from '~p/client/components/utils'
import Application from '~p/client/containers/Application'
import configureRouter from '~p/client/configureRouter'
import {
  openMenu,
  togglePreviewMinimization,
  ensurePreviewIntroduced,
  setOriginValues,
  loadRecipe,
  saveRecipe,
  modifyRecipe,
  toggleShare,
  setPreviewMinimization,
  copyLinkToClipboard,
} from '~p/client/common/actions'
import {
  isRecipeFinalizedSelector,
  isSkuSelector,
  createMenuSelector,
  getPreviewUrlsSelector,
  isAppLoadingSelector,
} from '~p/client/common/selectors'
import SocialIcons from '~mizuno/client/containers/SocialIcons'
import { connectOrders } from '~p/client/component-enhancers'
import PreviewImages from '~p/client/components/PreviewImages'
import * as controlTreeDebugUtils from '~p/client/control-tree/debugUtils'

import Layout from '~mizuno/client/components/Layout'
import LoadingIndicator from '~mizuno/client/components/atoms/LoadingIndicator'
import ProductSet from '~mizuno/client/components/molecules/ProductSet'
import ProductTile from '~mizuno/client/components/molecules/ProductTile'
import _ProductTitle from '~mizuno/client/components/organisms/ProductTitle'
import Summary from '~mizuno/client/components/Summary'
import '~mizuno/client/components/Root.css'

import ContactForm from '../../mizuno-uniforms/client/containers/ContactForm'
import _Finalize from '../../mizuno-uniforms/client/containers/Finalize'

import store from './store'
import Renderer from './containers/Renderer'
import * as renderComposite from '../renderComposite'
import viewAngles, { defaultViewAngleId } from '../viewAngles'
import controlTree from './controlTree'
import Sidebar from './components/Sidebar'
import * as iconGenerators from './iconGenerators'
import { GLOVE_ASSET_DICT, GLOVE_DICT } from '../common/sheets'

handleErrors(store)

// TODO: Remove.
window.iconGenerators = iconGenerators

// For debugging.
window.store = store

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
_.forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

const ProductTitle = connectOrders(controlTree, _ProductTitle)
const Finalize = connectOrders(controlTree, _Finalize)

const routes = [
  [
    '/design/:recipeId(/:status)',
    ({ recipeId }) => /[a-z0-9]+/.test(recipeId) && recipeId.length >= 3,
  ],
  '/sku/:glove',
]

const { initialMatches } = configureRouter(controlTree, store, routes)

const initialState = store.getState()

if (initialMatches && initialMatches.recipeId) {
  store.dispatch(loadRecipe(controlTree, initialMatches.recipeId))
} else if (initialMatches && isSkuSelector(initialState)) {
  const { glove } = initialMatches
  store.dispatch(controlTree.setValues({ 'product.glove': glove }))
  store.dispatch(setOriginValues(controlTree))
} else {
  store.dispatch(setOriginValues(controlTree))
}

window.addEventListener('message', (ev) => {
  if (fp.has('type', ev.data)) {
    store.dispatch(ev.data)
  }
})

{
  const mql = window.matchMedia('(min-width: 1000px)')
  if (mql.matches) store.dispatch(setPreviewMinimization(false))
  mql.addListener((x) => store.dispatch(setPreviewMinimization(!x.matches)))
}

window._ = _
window.fp = fp
window.controlTree = controlTree
window.store = store

// Don't want to show softball in baseball builder and vice versa.
// This was once checked on API, but not anymore because SPORT_ID is various.
// https://github.com/OrangeLV/Customizer/commit/4deba0
const isIncorrectSportUsed = (props) => {
  const sportId = props.nodes['calc.sport'].value.id

  const gloveId = props.controlTreeValues['product.glove']
  const gloveAsset =
    gloveId ?
      GLOVE_ASSET_DICT[GLOVE_DICT[gloveId].asset.gloveAssetId]
    : undefined

  return gloveAsset && gloveAsset.props.sportId !== sportId
}

const menuSelector = createMenuSelector(controlTree)

const Root = connect(
  (state) => {
    const nodes = controlTree.getNodes(state)
    const productNode = nodes['product.glove']

    const productName = productNode.optionName
    const products = productNode.visibleOptions || productNode.options

    const controlTreeValues = state.controlTree.values

    return {
      nodes,
      isAppLoading: isAppLoadingSelector(state),
      isRecipeFinalized: isRecipeFinalizedSelector(state),
      isPreviewMinimized: state.isPreviewMinimized,
      previewUrls: getPreviewUrlsSelector(
        viewAngles,
        defaultViewAngleId,
      )(state),
      menu: menuSelector(state),
      products,
      expandedRecipe: controlTree.getExpandedRecipe(state),
      productName,
      controlTreeValues,
    }
  },
  (dispatch) => ({
    openMenu: (menu) => dispatch(openMenu(controlTree, menu)),
    saveRecipe: () => dispatch(saveRecipe(controlTree)),
    modifyRecipe: () => dispatch(modifyRecipe()),
    copyLinkToClipboard: () => dispatch(copyLinkToClipboard()),
    togglePreviewMinimization: () => dispatch(togglePreviewMinimization()),
    toggleShare: () => dispatch(toggleShare()),
    changeProduct: (id) => {
      dispatch(ensurePreviewIntroduced())
      dispatch(controlTree.change('product.glove', id))
      dispatch(openMenu(controlTree, 'colors'))
    },
  }),
)((props) => {
  const isProductScreen = props.menu === 'product' && !props.isRecipeFinalized
  const isFinalizeScreen = props.isRecipeFinalized

  const sportId = props.nodes['calc.sport'].value.id

  if (isIncorrectSportUsed(props)) {
    return (
      <>
        The recipe ID matches a recipe in another sport. Please make sure you
        are using the correct builder.
      </>
    )
  }

  return (
    <Layout
      {...props}
      isProductScreen={isProductScreen}
      isFinalizeScreen={isFinalizeScreen}
    >
      <ProductTitle
        previewState={props.isPreviewMinimized ? 'off' : 'on'}
        title={
          props.expandedRecipe['details.recipeName.text'] ||
          `${
            sportId === 'baseball' ? 'Men’s Baseball' : 'Women’s Fastpitch'
          } Custom Glove`
        }
        name={props.productName}
        saveButtonLabel="Save my glove"
        onSave={() => props.saveRecipe()}
        onShare={() => {
          props.copyLinkToClipboard()
        }}
        onPreviewToggle={() => props.togglePreviewMinimization()}
        priceFormatted={props.nodes['calc.priceFormatted'].value}
      />

      <div className="viewer">
        <div className="preview">
          <div
            className={cn([
              'renderer',
              states([props.isPreviewMinimized ? 'off' : 'on']),
            ])}
          >
            <Renderer
              controlTree={controlTree}
              renderComposite={renderComposite}
              viewAngles={viewAngles}
              isHidden={isProductScreen || isFinalizeScreen}
              LoadingIndicator={LoadingIndicator}
            />

            {isFinalizeScreen && props.isPreviewMinimized && (
              <img
                className="preview-image-corner"
                src={_.values(props.previewUrls)[0]}
              />
            )}

            {isFinalizeScreen && !props.isPreviewMinimized && (
              <div className="preview-images-outer">
                {!window.serverConfig.hideSocial && (
                  <SocialIcons
                    classMods={['largeScreen']}
                    downloadUrl={props.previewUrls[defaultViewAngleId]}
                  />
                )}

                <PreviewImages previewUrls={props.previewUrls} />
              </div>
            )}
          </div>
        </div>

        <div className="productSelect">
          <ProductSet>
            {_.map(props.products, (glove) => (
              <ProductTile
                key={glove.id}
                name={glove.name}
                onClick={() => props.changeProduct(glove.id)}
                imageUrl={getAsset(`icons/gloves/${glove.id}.png`)}
                buttonText="Build this glove"
              />
            ))}
          </ProductSet>
        </div>
      </div>

      <div className="sidebar">
        <div className="sidebar-body">
          <Sidebar nodes={props.nodes} />
        </div>
      </div>

      <Finalize
        controlTree={controlTree}
        onSave={props.saveRecipe}
        resetMenu="options"
        saveButtonLabel="Save my glove"
        resumeButtonLabel="Edit glove"
      >
        {isFinalizeScreen && !props.isAppLoading && (
          <Fragment>
            <ContactForm />
            <Summary nodes={props.nodes} />
          </Fragment>
        )}
      </Finalize>
    </Layout>
  )
})

function onReady() {
  const root = document.getElementById('root')
  if (root === null) {
    throw new Error('root element not found')
  }
  createRoot(root).render(
    <Application store={store}>
      <Root />
    </Application>,
  )
}

document.addEventListener('DOMContentLoaded', onReady)
