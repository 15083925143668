import * as _ from '@technically/lodash'
import fp from 'lodash/fp.js'
import wildcard from 'wildcard'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { push } from '../history'
import { createMenuSelector } from '../common/selectors'

function controlize(Component) {
  const mapStateToProps = () =>
    createSelector(
      [(s, p) => p.controlTree.getNodes(s), (s, p) => p.path],
      (nodes, path) => {
        const resolvedNode = nodes[path]

        return { resolvedNode }
      },
    )

  const mapDispatchToProps = (dispatch, props) => ({
    change: (value) => dispatch(props.controlTree.change(props.path, value)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const { resolvedNode, key, path } = props

    if (resolvedNode === undefined) {
      console.error('Invalid path:', props.path)
      return (
        <div className="contentLabel" key={key}>
          Invalid path {path}
        </div>
      )
    }

    if (resolvedNode.isPrivate || resolvedNode.value === undefined) {
      return null
    }

    const childProps = fp.assign(resolvedNode, props)

    return React.createElement(Component, childProps)
  })
}

const isAnyNodeAvailable = (controlPaths, resolvedNodes) => {
  const nodes = fp.filter((node) =>
    fp.some((controlPath) => wildcard(controlPath, node.keyPath))(
      fp.concat([], controlPaths),
    ),
  )(resolvedNodes)

  return fp.some((x) => x.value !== undefined, nodes)
}

const ControlGroup = connect((state, props) => ({
  resolvedNodes: props.controlTree.getNodes(state),
}))((props) => {
  const { controlPaths, resolvedNodes } = props

  if (
    controlPaths !== undefined &&
    !isAnyNodeAvailable(controlPaths, resolvedNodes)
  ) {
    return null
  }

  return <span>{props.children}</span>
})

const Fragment = connect((state, props) => ({
  menu: createMenuSelector(props.controlTree)(state),
  ...props,
}))((props) => {
  const { menu, forRoute, withConditions } = props

  const hasMatch =
    (forRoute && _.startsWith(menu, forRoute)) ||
    (withConditions && withConditions(menu))
  if (!hasMatch) {
    return null
  }

  return <ControlGroup {...props}>{props.children()}</ControlGroup>
})

const onLinkClick = (ev, href) => {
  ev.preventDefault()
  push({ query: { menu: href } })
}

const Link = connect((state, props) => ({
  ...props,
}))((props) => (
  <ControlGroup {...props}>
    <a href="#" onClick={(ev) => onLinkClick(ev, props.href)}>
      {props.children}
    </a>
  </ControlGroup>
))

function repeatedControl(repeaterPath, Component) {
  const mapStateToProps = (state, { controlTree }) => ({
    controlIds: controlTree.getRepeatedNodes(state, repeaterPath),
    nodes: controlTree.getNodes(state),
    history: state.history,
  })

  const mapDispatchToProps = (dispatch, { controlTree }) => ({
    add: () => dispatch(controlTree.addNode(repeaterPath)),
    remove: (id) => dispatch(controlTree.removeNode(repeaterPath, id)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(Component)
}

export { controlize, ControlGroup, Fragment, Link, repeatedControl }
