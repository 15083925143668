import React from 'react'
import { connect } from 'react-redux'

import { createMenuSelector, submenuSelector } from '~p/client/common/selectors'
import { controlize } from '~p/client/control-tree'
import { hideWhenSingleOption } from '~p/client/component-enhancers'

import { TextInputControl, TileSelectControl } from './controls'
import ControlMenu from '../components/ControlMenu'
import controlTree from '~c/client/controlTree'

const nodeKindToComponent = {
  SelectNode: TileSelectControl,
  TextNode: TextInputControl,
}

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = (state, props) => {
  const { path, link, resolvedNode, children } = props
  let component = children

  if (link && !children) {
    console.error(
      'Cannot use `link` property without `children` for node:',
      path,
    )
    return undefined
  }

  const menu = menuSelector(state)
  const submenu = submenuSelector(state)

  const isOpen = !!(submenu && (submenu === path || submenu === link))

  if (!component) {
    const Component = nodeKindToComponent[resolvedNode.nodeKind]

    if (!Component) {
      console.error('No component for node:', path)
      return undefined
    }

    component = React.createElement(Component, {
      ...props,
      controlTree,
      path,
      isOpen,
    })
  }

  return {
    component,
    menu,
    submenu,
    isOpen,
  }
}

const Menu = connect(mapStateToProps)(ControlMenu)
const ControlizedMenu = controlize(hideWhenSingleOption(Menu))

export default (props) => {
  if (props.path) {
    return <ControlizedMenu {...props} />
  }

  return <Menu {...props} />
}
