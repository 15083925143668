/**
 * Creates a debounced function that delays invoking func until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked.
 *
 * @param targetFunction - The function to debounce.
 * @param wait - The number of milliseconds to delay.
 *
 * @returns The new debounced function.
 */
export function debounce<T extends unknown[]>(
  targetFunction: (...targetArguments: T) => unknown,
  wait: number,
): (...targetArguments: T) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  return (...targetArguments) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      targetFunction.apply(targetFunction, targetArguments);
    }, wait);
  };
}
