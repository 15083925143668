import fp from 'lodash/fp.js'
import { AbstractNode } from './nodes'

/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 * Bind all child nodes to control tree.
 */
function bindSubtree(subtree, meta) {
  return fp.pipe(
    fp.keys,
    fp.flatMap((key) =>
      bindNodes(subtree[key], {
        ...meta,
        path: fp.concat(meta.path, key),
      }),
    ),
  )(subtree)
}

/**
 * Delegete to binding function by type.
 *
 * Runtime node creation entry point.
 */
export default function bindNodes(node, meta) {
  let result

  if (node instanceof AbstractNode) {
    result = node.bind(meta)
  } else {
    result = bindSubtree(node, meta)
  }

  return fp.concat([], result)
}

/* eslint-enable @typescript-eslint/no-use-before-define */
