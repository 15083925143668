import React from 'react'

import { maybeSetActive } from '~p/client/components/utils'

import Tile from '../atoms/Tile'
import BgCover from '../atoms/BgCover'
import Icon from '../atoms/Icon'

function PatternTile(props) {
  const { classMods = [], classStates = [], isActive = false, url } = props
  return (
    <Tile
      {...props}
      classMods={['pattern', ...classMods]}
      classStates={maybeSetActive(isActive, classStates)}
    >
      {isActive ?
        <Icon name="select" />
      : null}
      <BgCover {...props} url={url} />
    </Tile>
  )
}

export default PatternTile
