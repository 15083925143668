import React from 'react'

import './ResetRecipe.css'

const ResetRecipe = ({ resetRecipe }) => (
  <a className="reset-recipe" data-gtm="recipe-reset" onClick={resetRecipe}>
    Clear all options and start over?
  </a>
)

export default ResetRecipe
